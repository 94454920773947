import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { PhoneInputSection } from 'components/containers/PhoneInputSection';
import { Text1 } from 'components/typography/Texts';
import ObjectError from 'components/ui-elements/ObjectError';
import QRCodeComponent from 'components/ui-elements/QRCodeComponent';
import Spinner from 'components/ui-elements/Spinner';
import { usePublicUserData } from 'services/user/usePublicUserData';
import { extractUserName } from 'utils/extractUserName';

import MessageCloud from './components/MessageCloud';

const Container = styled.div`
  margin: 0 auto;
  max-width: 1200px;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 80%;
`;

const DescriptionSection = styled.div`
  margin: 30px auto;
  width: 70%;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 30px;
  align-items: center;
  border: solid 1px ${theme.color.borderGray};
  border-radius: 5px;
`;

const MessageCloudWrapper = styled.div`
  margin: 40px auto 0 auto;

  ${theme.mq.tablet} {
    margin: 20px 10px;
  }
`;

export const MessagePreview = () => {
  const { t } = useTranslation('message');
  const { senderId } = useParams();
  const { content: user, error, loading } = usePublicUserData(Number(senderId));

  return (
    <Container>
      {loading && <Spinner displayLabel={true} />}
      {!!error && <ObjectError error={error} />}
      {!!user && (
        <>
          <MessageCloudWrapper>
            <MessageCloud authorName={extractUserName(user)} authorPhoto={user?.imageUrl} authorIsVip={user.vip} />
          </MessageCloudWrapper>
          <DescriptionSection>
            <DescriptionWrapper>
              <Text1>{t('description')}</Text1>
            </DescriptionWrapper>
            <QRCodeComponent hasAnnotatedDescription={true} />
            <PhoneInputSection context={'MESSAGE'} />
          </DescriptionSection>
        </>
      )}
    </Container>
  );
};
