import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { H6 } from 'components/typography/Headers';
import { Text1, Text1Bold } from 'components/typography/Texts';
import { buyerTileStatusLabel } from 'features/my-profile/my-activity/components/tracked-item/trackedItemUtils';
import { PurchaseDto } from 'services/order/orderService.dto';
import { PublicUserDto } from 'services/user/userService.dto';
import { extractUserName } from 'utils/extractUserName';

import { SellerActions } from './SellerActions';

const BorderContainer = styled.div`
  border: 1px solid ${theme.color.borderGray};
  border-radius: 5px;
  margin-bottom: 12px;
  padding: 24px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

const ToggleText = styled(Text1Bold)`
  cursor: pointer;
  color: ${theme.color.red};
`;

const StatusText = styled(Text1)`
  margin-left: auto;

  @media (max-width: 425px) {
    display: none;
  }
`;

const Spacer = styled.div`
  margin: 24px 0;
  border-top: 1px solid ${theme.color.borderGray};
`;

interface Props {
  order: PurchaseDto;
  user: PublicUserDto;
  onClick: () => void;
}

export const BuyerTile = ({ order, user, onClick }: Props) => {
  const { t } = useTranslation('myProfile');
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleToggle = () => {
    setIsCollapsed(prevCollapsed => !prevCollapsed);
  };

  const getToggleText = isCollapsed ? t('tracked-item.show') : t('tracked-item.collapse');

  return (
    <BorderContainer>
      <TextContainer>
        <H6 data-testid={'user-name'}>{extractUserName(user)}</H6>
        <StatusText data-testid={'order-status'}>{buyerTileStatusLabel[order.status]}</StatusText>
        <ToggleText data-testid={'toggle-text'} onClick={() => handleToggle()}>
          {getToggleText}
        </ToggleText>
      </TextContainer>
      {!isCollapsed && (
        <>
          <Spacer data-testid={'buyer-tile-spacer'} />
          <SellerActions order={order} isBuyer={false} onClick={onClick} />
        </>
      )}
    </BorderContainer>
  );
};
