import { useMemo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import banIcon from 'assets/svg/ban.svg';
import closeIcon from 'assets/svg/close-red.svg';
import Spinner from 'components/ui-elements/Spinner';
import { ImageDto } from 'services/image/useUploadImages';
import { convertToThumbnail300 } from 'utils/thumbnails';

const DeleteButton = styled.img`
  position: absolute;
  top: 1px;
  right: 1px;
  width: 10px;
  height: 10px;
  padding: 2px;
  margin: 2px;
  border-radius: 50%;
  background-color: ${theme.color.white};
  opacity: 0;
  cursor: pointer;
`;

const Container = styled.div<{ $isDragging: boolean }>`
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;

  &:hover ${DeleteButton} {
    opacity: 1;
  }
`;

const PhotoImg = styled.img<{ $success: boolean }>`
  width: 100%;
  background-color: ${theme.color.white};
  border-radius: 5px;
  opacity: ${props => (props.$success ? 1 : 0.3)};
  outline: none;
  cursor: pointer;
  object-fit: cover;
  aspect-ratio: 1;
`;

const UnsafeOverlay = styled.img`
  position: absolute;
  width: 30px;
  height: 30px;
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  pointer-events: none;
`;

interface Props {
  img: ImageDto;
  onClick: () => void;
  onDelete: (id: string) => void;
}

const SortableImage = ({ img, onClick, onDelete }: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: img.id,
    animateLayoutChanges: () => false,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? theme.zIndex.foremost : 'auto',
  };

  const url = useMemo(() => {
    if (img.localUrl) return img.localUrl;
    if (img.url) return convertToThumbnail300(img.url);
    return ''; // TODO imageNotFound
  }, [img]);

  return (
    <Container
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      $isDragging={isDragging}
      data-testid={'uploaded-image'}>
      <PhotoImg onClick={onClick} src={url} $success={img.safe && !img.isUploading} />
      {img.isUploading && <StyledSpinner />}
      {!img.safe && <UnsafeOverlay src={banIcon} />}
      <DeleteButton src={closeIcon} onClick={() => onDelete(img.id)} alt={'delete image'} />
    </Container>
  );
};

export default SortableImage;
