import { useTranslation } from 'react-i18next';

import FormStepWrapper from 'components/form/FormStepWrapper';
import LocationByZipPicker from 'components/location/LocationByZipPicker';
import { useAddItem } from 'features/add-item/context/AddItemProvider';

const SetServiceLocation = () => {
  const { t } = useTranslation('addItem');
  const { formData, errors, setFieldValue } = useAddItem();

  return (
    <FormStepWrapper
      header={t('delivery.service.header')}
      description={t('delivery.service.description')}
      isValid={!errors.itemLocation}>
      <LocationByZipPicker
        value={formData.itemLocation}
        onChange={value => setFieldValue('itemLocation', value)}
        validZipLength={5}
        placeholder={t('delivery.zip-placeholder')}
      />
    </FormStepWrapper>
  );
};

export default SetServiceLocation;
