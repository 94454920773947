import { BidDto } from 'services/item/itemService.dto';
import { PublicUserDto } from 'services/user/userService.dto';

export const mergeUserData = (bids: BidDto[], users?: PublicUserDto[]): BidderDto[] => {
  if (!users) return [];
  return bids
    .map(bid => {
      const user = users.find(u => u.id === bid.userId);

      if (user) {
        return {
          ...user,
          bid,
          resigned: false,
          winner: false,
        };
      }

      return null;
    })
    .filter(bidder => bidder !== null) as BidderDto[];
};

export interface BidderDto extends PublicUserDto {
  bid: BidDto;
  resigned: boolean;
  winner: boolean;
}
