import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import slugify from 'react-slugify';

import Carousel from 'components/carousel/Carousel';
import {
  ColumnsContainer,
  Container,
  ContentContainer,
  DetailsColumn,
  FloatingSectionContainer,
  MainColumn,
  PostSomethingAndOwnerContainer,
  Title,
} from 'components/containers/ItemCampaignContainers';
import { renderErrorOrSpinner } from 'components/containers/renderErrorOrSpinner';
import { LimitedText } from 'components/limited-text/LimitedText';
import { PostSomething } from 'components/post-something/PostSomething';
import ConversationAwareOwner from 'components/profile/ConversationAwareOwner';
import ActionPanel from 'components/ui-elements/ActionPanel';
import { ClosedInfo } from 'components/ui-elements/ClosedInfo';
import ObjectError from 'components/ui-elements/ObjectError';
import { ObjectNotFound } from 'components/ui-elements/ObjectNotFound';
import Spinner from 'components/ui-elements/Spinner';
import { useModal } from 'providers/ModalProvider';
import { useCampaignData } from 'services/campaign/useCampaignData';
import { ItemDto } from 'services/item/itemService.dto';
import { useItemData } from 'services/item/useItemData';

import { ItemDetails } from './components/ItemDetails';
import SimilarItems from './components/SimilarItems';

export const ItemPreview = () => {
  const { itemId } = useParams();
  const { content: item, error, loading, refreshData: refreshItemData } = useItemData(Number(itemId));
  const { content: campaign } = useCampaignData(item?.supportedCampaignId);
  const { showGetApp } = useModal();
  const navigate = useNavigate();
  const { t } = useTranslation('item');

  useEffect(() => {
    if (!!item) {
      navigate(`/items/${slugify(item.title)}/${item.id}`, { replace: true });
    }
  }, [item]);

  const getClosedInfoMessage = (item: ItemDto) => {
    if (item.sellingMethod === 'AUCTION') {
      return {
        title: t('closed-info.auction-title'),
        description: t('closed-info.auction-desc'),
      };
    }

    if (item.sellingMethod === 'STANDARD') {
      return {
        title: t('closed-info.standard-title'),
        description: t('closed-info.multi-standard-desc'),
      };
    }

    if (item.sellingMethod === 'MULTIPLE') {
      return {
        title: t('closed-info.multiple-title'),
        description: t('closed-info.multi-standard-desc'),
      };
    }
  };

  if (loading) return renderErrorOrSpinner(<Spinner displayLabel={true} />);
  if (error || !item) {
    if (error?.response?.status === 410) return renderErrorOrSpinner(<ObjectNotFound />);
    else return renderErrorOrSpinner(<ObjectError error={error} />);
  }
  if (!!item && item.itemStatus === 'BLOCKED') return renderErrorOrSpinner(<ObjectNotFound />);

  const closedInfoMessage =
    item.itemStatus === 'SOLD' || item.itemStatus === 'ARCHIVED' ? getClosedInfoMessage(item) : null;

  return (
    <Container>
      {closedInfoMessage && (
        <ClosedInfo title={closedInfoMessage?.title} description={closedInfoMessage?.description} />
      )}
      <ContentContainer>
        <FloatingSectionContainer>
          <ColumnsContainer>
            <MainColumn>
              <Carousel
                photos={item.imageUrlList}
                videoUrl={item.videoUrl}
                isCampaign={false}
                onHeartClick={() => showGetApp({ appAreaType: 'ITEM', objectId: item.id })}
              />
              <Title data-testid={'item-title'}>{item.title}</Title>
              <LimitedText description={item.description} />
            </MainColumn>
            <DetailsColumn>
              <ItemDetails item={item} campaign={campaign} refreshItemData={refreshItemData} />
              <ActionPanel appArea={'ITEM'} objectId={item.id} />
            </DetailsColumn>
          </ColumnsContainer>
        </FloatingSectionContainer>
        <SimilarItems itemId={Number(itemId)} />
        <PostSomethingAndOwnerContainer>
          <PostSomething campaignId={campaign?.id} />
          <ConversationAwareOwner userId={item.ownerId} context={'ITEM'} objectId={item.id} />
        </PostSomethingAndOwnerContainer>
      </ContentContainer>
    </Container>
  );
};
