import { usePopup } from 'hooks/usePopup';
import { CampaignDto } from 'services/campaign/campaignService.dto';

import CampaignPicker from './CampaignPicker';

export const useCampaignPicker = () => {
  const { showPopup, hidePopup } = usePopup();

  const showCampaignPicker = (onSelected: (campaign: CampaignDto) => void, keepPopups = false) => {
    showPopup(
      <CampaignPicker
        onSelected={campaign => {
          hidePopup();
          onSelected(campaign);
        }}
      />,
      { paddingVariant: 'REDUCED', keepPopups }
    );
  };

  return { showCampaignPicker };
};
