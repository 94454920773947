import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import errorIcon from 'assets/svg/circle-error.svg';
import { IconWrapper } from 'components/containers/Containers';
import { useMessengerAlert } from 'features/messenger/hooks/useMessengerAlert';
import { useConversations } from 'providers/ConversationProvider';
import { useNotification } from 'providers/NotificationProvider';
import { createTitle } from 'providers/utils/notifications/notificationActions';
import { InternalMessageDto, MessageInternalStatus, MessageType } from 'services/message/messageService.dto';

import { DaySeparator } from './DaySeparator';
import { NotificationMessage } from './NotificationMessage';
import { PhotoMessage } from './PhotoMessage';
import { RedWarning } from './RedWarning';
import { TextMessage } from './TextMessage';

const Container = styled.div<{ isMessageOwner: boolean }>`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.isMessageOwner ? 'flex-end' : 'flex-start')};
`;

const MessageWrapper = styled.div`
  display: flex;
  gap: 12px;
  overflow-wrap: break-word;
  width: 100%;
`;

interface Props {
  message: InternalMessageDto;
  isMessageOwner: boolean;
  isFirstMsgInDay: boolean;
}

export const MessageBubble = ({ message, isMessageOwner, isFirstMsgInDay }: Props) => {
  const { t } = useTranslation('message');
  const { isCustomNotification } = useNotification();
  const { handleMessengerAlert } = useMessengerAlert();
  const { resendTextMessage, resendPhotoMessage } = useConversations();
  const customWarning = message.type === 'NOTIFICATION' && isCustomNotification(message.payload.type);
  const messageInternalStatus = message.internalStatus;

  const resendMessage = () => {
    if (message.type === MessageType.PHOTO) {
      resendPhotoMessage({
        tmpMessageId: message.internalId,
        content: message.mediaUrl!,
        conversationId: message.conversationId,
      });
    } else if (message.type === MessageType.TEXT) {
      resendTextMessage({
        tmpMessageId: message.internalId,
        content: message.content!,
        conversationId: message.conversationId,
      });
    }
  };
  const handleResendMessage = () => {
    const firstButton = {
      label: t('resend.resend-button'),
      onClick: resendMessage,
    };
    const secondButton = {
      label: t('resend.cancel-button'),
    };
    handleMessengerAlert(t('resend.header'), firstButton, secondButton);
  };

  return (
    <Container id={message.internalId.toString()} isMessageOwner={isMessageOwner}>
      {isFirstMsgInDay && <DaySeparator messageCreatedAt={message.createdAt} />}
      {customWarning && <RedWarning title={createTitle(message.payload.type, message.payload.attributes)} />}
      {!customWarning && (
        <MessageWrapper>
          {message.type === 'NOTIFICATION' && <NotificationMessage message={message} isMessageOwner={isMessageOwner} />}
          {message.type === 'TEXT' && <TextMessage message={message} isMessageOwner={isMessageOwner} />}
          {message.type === 'PHOTO' && <PhotoMessage message={message} isMessageOwner={isMessageOwner} />}
          {(messageInternalStatus === MessageInternalStatus.ERROR ||
            messageInternalStatus === MessageInternalStatus.UPLOAD_ERROR) && (
            <IconWrapper src={errorIcon} onClick={handleResendMessage} />
          )}
        </MessageWrapper>
      )}
    </Container>
  );
};
