import { HTMLInputTypeAttribute, RefObject } from 'react';
import styled from 'styled-components';

import BaseInputField, {
  BaseInputProps,
  containerStyle,
  focusStyle,
  fontStyle,
  inputStyle,
  placeholderStyle,
} from './BaseInputField';

const Input = styled.input<{ $error: boolean; $success: boolean }>`
  ${containerStyle}
  ${fontStyle};
  ${inputStyle};

  &:focus {
    ${focusStyle}
  }

  &::placeholder {
    ${placeholderStyle}
  }
`;

interface Props extends BaseInputProps {
  inputType?: HTMLInputTypeAttribute;
  inputRef?: RefObject<HTMLInputElement>;
  readonly?: boolean;
}

const TextInputField = (props: Props) => {
  const { value, placeholder, errorMessage, successMessage, onChange } = props;
  const { className, testId, inputRef, inputType, disabled, maxLength, readonly } = props;

  return (
    <BaseInputField testId={testId} className={className} errorMessage={errorMessage} successMessage={successMessage}>
      <Input
        ref={inputRef}
        data-testid={'input'}
        value={value}
        placeholder={placeholder}
        onChange={event => onChange(event.target.value)}
        $error={!!errorMessage}
        $success={!!successMessage}
        type={inputType}
        disabled={disabled}
        maxLength={maxLength}
        readOnly={readonly}
      />
    </BaseInputField>
  );
};

export default TextInputField;
