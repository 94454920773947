import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import RadioButton from 'components/buttons/RadioButton';
import FormStepWrapper from 'components/form/FormStepWrapper';
import { ItemWeightSelector } from 'components/item/item-weight-selector/ItemWeightSelector';
import LocationByZipPicker from 'components/location/LocationByZipPicker';
import { useAddItem } from 'features/add-item/context/AddItemProvider';

const RadioButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: start;
`;

export const SetItemDeliveryContent = () => {
  const { t } = useTranslation('addItem');
  const { formData, setFieldValue } = useAddItem();
  return (
    <>
      <LocationByZipPicker
        value={formData.itemLocation}
        onChange={value => setFieldValue('itemLocation', value)}
        validZipLength={5}
        placeholder={t('delivery.zip-placeholder')}
      />
      <RadioButtonsContainer>
        <RadioButton
          data-testid={'radio-local-pickup'}
          checked={formData.canLocalPickUpDelivery}
          onChange={() => {}}
          onClick={() => setFieldValue('canLocalPickUpDelivery', !formData.canLocalPickUpDelivery)}>
          {t('common:local-pickup')}
        </RadioButton>
        <RadioButton
          data-testid={'radio-nationwide-shipment'}
          checked={formData.canNationwideShipping}
          onChange={() => {}}
          onClick={() => setFieldValue('canNationwideShipping', !formData.canNationwideShipping)}>
          {t('common:nationwide-shipping')}
        </RadioButton>
      </RadioButtonsContainer>
      {formData.canNationwideShipping && (
        <ItemWeightSelector
          selectedWeightUpTo={formData.weightUpTo}
          onWeightSelected={weightUpTo => setFieldValue('weightUpTo', weightUpTo)}
        />
      )}
    </>
  );
};

const SetItemDelivery = () => {
  const { t } = useTranslation('addItem');
  const { errors } = useAddItem();
  const isValid = !errors.itemLocation && !errors.canNationwideShipping && !errors.weightUpTo;

  return (
    <FormStepWrapper
      header={t('delivery.regular.header')}
      description={t('delivery.regular.description')}
      isValid={isValid}>
      <SetItemDeliveryContent />
    </FormStepWrapper>
  );
};

export default SetItemDelivery;
