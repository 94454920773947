import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { Text2 } from 'components/typography/Texts';
import { ItemDto } from 'services/item/itemService.dto';

const Quantity = styled(Text2)`
  color: ${theme.color.darkerGray2};
  text-align: center;
  margin-top: 10px;
`;

interface Props {
  item: ItemDto;
}

const QuantityInfo = ({ item }: Props) => {
  const { t } = useTranslation('item');
  const unitsLeft = item.unitsQuantity - item.unitsSold;

  if (item.sellingMethod !== 'MULTIPLE') return <></>;
  return (
    <Quantity data-testid={'item-quantity'}>
      {t('multiple-count', { remainingUnits: unitsLeft, unitsQuantity: item.unitsQuantity })}
    </Quantity>
  );
};

export default QuantityInfo;
