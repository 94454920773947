import { useTranslation } from 'react-i18next';

import { useAlertPopup } from './useAlertPopup';

export const useGlobalError = () => {
  const { showAlertPopup } = useAlertPopup();
  const { t } = useTranslation();

  const unknownError = () =>
    showAlertPopup({
      header: t('global-error.unknown-error-title'),
      description: t('global-error.unknown-error-message'),
      firstButton: { label: t('global-error.unknown-error-button') },
      testId: 'unknown-error-alert',
    });

  const messageError = (message: string, title?: string) =>
    showAlertPopup({
      header: title || t('global-error.unknown-error-title'),
      description: message,
      firstButton: { label: t('global-error.unknown-error-button') },
      testId: 'error-alert',
    });

  return { unknownError, messageError };
};
