import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { H3 } from 'components/typography/Headers';
import { CampaignDto } from 'services/campaign/campaignService.dto';

import { CampaignInactiveOverlay } from './CampaignInactiveOverlay';
import CampaignPhoto from './CampaignPhoto';
import { CampaignProgressBar } from './CampaignProgressBar';
import CampaignStats from './CampaignStats';

export const TileContainer = styled.div`
  position: relative;
  border: 1px solid ${theme.color.lightGray};
  border-radius: 10px;
  padding: 10px;
  background: ${theme.color.white};
  cursor: pointer;
  min-width: 0;
  height: 100%;
  gap: 10px;
  display: flex;

  ${theme.mq.phone} {
    flex-direction: column;
    }
  }
`;

const CampaignName = styled(H3)`
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding: 12px 0 0 0;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  width: 100%;
  padding: 48px;

  ${theme.mq.phone} {
    flex: 1;
    gap: 12px;
    justify-content: space-between;
    padding: 0;
  }
`;

interface Props {
  campaign: CampaignDto;
  onClick: (campaign: CampaignDto) => void;
}

const FeaturedCampaignTile = ({ campaign, onClick }: Props) => {
  return (
    <TileContainer data-testid={'campaign-tile_' + campaign.id} onClick={() => onClick(campaign)}>
      <CampaignPhoto
        image={campaign.imageUrls[0]}
        isHidden={campaign.isHidden ?? false}
        campaignStatus={campaign.status}
      />
      <DataContainer>
        <CampaignName data-testid={'campaign-name'}>{campaign.name}</CampaignName>
        <CampaignProgressBar value={campaign.fundsRaised} total={campaign.moneyGoal} />
        <CampaignStats
          timelineFrom={campaign.timelineFrom!!}
          timelineTo={campaign.timelineTo}
          supportersNumber={campaign.supportersNumber}
          status={campaign.status}
        />
      </DataContainer>
      <CampaignInactiveOverlay isHidden={campaign.isHidden!!} campaignStatus={campaign.status} />
    </TileContainer>
  );
};

export default FeaturedCampaignTile;
