import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

import { BreakPointConfigType } from 'components/featured-section/featuredConfig';

import SliderNavigationHeader from './SliderNavigationHeader';
import { useSliderControls } from './useSliderControls';

import 'swiper/css';

const Container = styled.div`
  width: 100%;
`;

const StyledSwiperSlide = styled(SwiperSlide)`
  height: auto;
`;

interface SliderProps {
  header: string;
  items: any[];
  renderItem: (item: any) => React.ReactNode;
  breakPointConfig: BreakPointConfigType;
  testIdPrefix: string;
}

export const TileSlider = ({ header, items, renderItem, breakPointConfig, testIdPrefix }: SliderProps) => {
  const { prevEnabled, nextEnabled, displayButtons, onSwiper, onPrevPress, onNextPress, onBreakpoint } =
    useSliderControls(items.length);

  return (
    <Container>
      <SliderNavigationHeader
        header={header}
        prevEnabled={prevEnabled}
        nextEnabled={nextEnabled}
        onPrevPress={onPrevPress}
        onNextPress={onNextPress}
        displayButtons={displayButtons}
        testIdPrefix={testIdPrefix}
      />
      <Swiper
        breakpoints={breakPointConfig}
        onSwiper={onSwiper}
        onSlideChange={onBreakpoint}
        onBreakpoint={onBreakpoint}>
        {items.map((item, index) => (
          <StyledSwiperSlide key={index}>{renderItem(item)}</StyledSwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};
