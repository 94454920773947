import { useTranslation } from 'react-i18next';

import { campaignBreakpointsConfig } from 'components/featured-section/featuredConfig';
import { FeaturedSection } from 'components/featured-section/FeaturedSection';
import { useFeaturedCampaigns } from 'features/campaign/hooks/useCampaigns';
import { useCampaignsFilters } from 'providers/CampaignFiltersProvider';
import { useModal } from 'providers/ModalProvider';

export const CampaignsFeatured = () => {
  const { t } = useTranslation('campaign');
  const { pageState } = useCampaignsFilters();
  const featuredCampaigns = useFeaturedCampaigns();
  const { showGetApp } = useModal();

  return (
    <FeaturedSection
      title={t('start-campaign-title')}
      description={t('start-campaign-desc')}
      buttonLabel={t('start-campaign-btn')}
      onButtonClick={() => showGetApp({ appAreaType: 'ADD_CAMPAIGN' })}
      state={featuredCampaigns.state}
      variant={'CAMPAIGN'}
      pageState={pageState}
      breakPointConfig={campaignBreakpointsConfig}
      testId={'campaigns'}
    />
  );
};
