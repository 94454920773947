import { initReactI18next } from 'react-i18next';
import i18n, { LanguageDetectorAsyncModule } from 'i18next';

const languageDetector: LanguageDetectorAsyncModule = {
  // TODO: handle language detection in next versions of Giveo
  type: 'languageDetector',
  async: true,
  detect: callback => callback('en'),
  // tslint:disable-next-line:no-empty
  init: () => {},
  // tslint:disable-next-line:no-empty
  cacheUserLanguage: () => {},
};

const resources = {
  en: {
    account: require('./en/account.json'),
    addItem: require('./en/add-item.json'),
    campaign: require('./en/campaign.json'),
    checkout: require('./en/checkout.json'),
    common: require('./en/common.json'),
    error: require('./en/error.json'),
    home: require('./en/home.json'),
    item: require('./en/item.json'),
    message: require('./en/message.json'),
    user: require('./en/user.json'),
    myProfile: require('./en/my-profile.json'),
    notifications: require('./en/notifications.json'),
    share: require('./en/share.json'),
  },
};

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    returnNull: false,
    resources,
    debug: false,
    joinArrays: '',
    fallbackLng: 'en',
    ns: [
      'account',
      'addItem',
      'campaign',
      'checkout',
      'common',
      'error',
      'item',
      'message',
      'user',
      'myProfile',
      'notifications',
      'share',
    ],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
