import { ItemFormData } from 'features/add-item/context/useItemForm';
import { EditItemDto, NewItemDto } from 'services/item/itemService.dto';

export const convertFormDataToNewItemDto = (formData: ItemFormData): NewItemDto => {
  const imgUrls = formData.photos ? formData.photos.filter(photo => photo.safe).map(photo => photo.url || '') : [];
  return {
    canAcceptLoHiOffers: formData.sellingMethod === 'STANDARD' ? formData.isNegotiable : false,
    canLocalPickUpDelivery: formData.type === 'REGULAR' ? formData.canLocalPickUpDelivery : true,
    canNationwideShipping: formData.type === 'REGULAR' ? formData.canNationwideShipping : false,
    category: formData.category,
    condition: formData.type === 'REGULAR' ? formData.condition : undefined,
    description: formData.description,
    imageUrlList: imgUrls,
    videoUrl: formData.videoUrl,
    itemLocation: formData.itemLocation!,
    price: formData.price,
    supportedCampaignId: formData.supportedCampaign!.id,
    title: formData.title,
    weightUpTo: formData.type === 'REGULAR' && formData.canNationwideShipping ? formData.weightUpTo : undefined,
    itemType: formData.type!,
    sellingMethod: formData.sellingMethod!,
    unitsQuantity: formData.sellingMethod === 'MULTIPLE' ? formData.unitsQuantity : 1,
    auction:
      formData.sellingMethod !== 'AUCTION'
        ? undefined
        : {
            minimumPrice: formData.minimumPrice ? formData.minimumPrice : undefined,
            endDateTime: formData.endDateTime ? formData.endDateTime.toISOString() : undefined,
          },
  };
};

export const convertFormDataToEditItemDto = (formData: ItemFormData, itemId: number): EditItemDto => {
  return { ...convertFormDataToNewItemDto(formData), id: itemId };
};
