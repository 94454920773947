import { InputHTMLAttributes, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { Text1 } from 'components/typography/Texts';

const Label = styled(Text1).attrs({ as: 'label' })`
  display: flex;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
`;

const Button = styled.input`
  width: 22px;
  height: 22px;
  appearance: none;
  border-radius: 50%;
  background: ${theme.color.white};
  border: 3px solid ${theme.color.white};
  box-shadow: 0 0 0 1px ${theme.color.darkGray};
  cursor: pointer;
  margin: 1px;
  transition: all 0.3s;

  &:checked {
    background: ${theme.color.red};
  }
`;

interface Props extends InputHTMLAttributes<HTMLInputElement>, PropsWithChildren {}

const RadioButton = ({ children, ...props }: Props) => {
  return (
    <Label>
      <Button data-testid={'radio-button'} type={'radio'} {...props} />
      {children}
    </Label>
  );
};

export default RadioButton;
