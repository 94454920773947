import { itemService } from 'services/item/itemService';
import { shipmentService } from 'services/shipment/shipmentService';
import { userService } from 'services/user/userService';

import { LocationSource } from './locationService.dto';

export interface LocationCords {
  longitude?: number;
  latitude?: number;
  type: LocationSource | 'ITEM' | 'UNKNOWN';
}

export class MeetupLocationHelper {
  async getItemLocation(itemId: number): Promise<LocationCords> {
    return itemService
      .fetchItemDetails(itemId)
      .then(response => shipmentService.fetchGeocoding(response.data.itemLocation.zipCode))
      .then(value =>
        Promise.resolve({
          type: 'ITEM',
          latitude: value.data.coordinates.latitude,
          longitude: value.data.coordinates.longitude,
        })
      );
  }

  async getUserLocation(userId: number): Promise<LocationCords | null> {
    const user = await userService.fetchPublicUser(userId);
    const locationZip = user.data?.location?.zip;

    if (!locationZip) {
      return null;
    }

    const geocoding = await shipmentService.fetchGeocoding(locationZip);
    const coordinates = geocoding.data.coordinates;

    return {
      type: 'PROFILE',
      latitude: coordinates.latitude,
      longitude: coordinates.longitude,
    };
  }
}

const userLocationService = new MeetupLocationHelper();
export { userLocationService };
