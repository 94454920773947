import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { H5 } from 'components/typography/Headers';
import { Text2 } from 'components/typography/Texts';
import Spinner from 'components/ui-elements/Spinner';
import { CampaignDto } from 'services/campaign/campaignService.dto';
import { convertToThumbnail300 } from 'utils/thumbnails';

const Container = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: row;
  border: 1px solid ${theme.color.borderGray};
  border-radius: 5px;
  height: auto;
  cursor: pointer;
`;

const ImgContainer = styled.div`
  width: 113px;
  height: 113px;
`;

const CampaignImg = styled.img`
  width: 113px;
  height: 113px;
  object-fit: cover;
  border-radius: 5px;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin: 0 24px;
`;

const Name = styled(H5)`
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

interface Props {
  campaign?: CampaignDto;
}

const CampaignInfo = ({ campaign }: Props) => {
  const { t } = useTranslation('item');
  const imgUrl = campaign?.imageUrls[0];

  if (!campaign) return <Spinner />;

  return (
    <Container data-testid={'campaign-info'} to={`/campaigns/${campaign?.id}`}>
      <ImgContainer>
        <CampaignImg data-testid={'campaign-photo'} src={imgUrl && convertToThumbnail300(imgUrl)} />
      </ImgContainer>
      <NameContainer>
        <Text2>{t('supports')}</Text2>
        <Name data-testid={'campaign-name'}>{campaign?.name}</Name>
      </NameContainer>
    </Container>
  );
};

export default CampaignInfo;
