import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { Text1, Text1Bold } from 'components/typography/Texts';
import { ItemDto } from 'services/item/itemService.dto';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 24px;

  ${theme.mq.desktop} {
    margin-bottom: 12px;
  }
`;

interface Props {
  item: ItemDto;
}

export const QuantityDetails = ({ item }: Props) => {
  const { t } = useTranslation('myProfile');
  const remainingUnits = item?.unitsQuantity - item?.unitsSold;

  return (
    <Container data-testid={'quantity-info'}>
      <Text1Bold data-testid={'sold'}>
        {t('tracked-item.multiple-sold', { unitsSold: item.unitsSold, count: item.unitsSold })}
      </Text1Bold>
      <Text1 data-testid={'left'}>{t('tracked-item.multiple-left', { remainingUnits, count: remainingUnits })}</Text1>
    </Container>
  );
};
