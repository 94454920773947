import { useEffect, useState } from 'react';

import { itemService } from 'services/item/itemService';
import { withAuctionsForBulkItems } from 'services/item/withAuctionsData';

import { searchService } from './searchService';
import { ItemsState, SearchMarketplaceItemsParams } from './searchService.dto';
import { sortByIds } from './searchSorting';

const initialState: ItemsState = {
  items: [],
  page: 0,
  loading: false,
  error: false,
  hasNextPage: true,
};

const useSearchItems = (searchParams: SearchMarketplaceItemsParams) => {
  const [state, setState] = useState<ItemsState>(initialState);

  useEffect(() => {
    if (state.page === 0) {
      fetchNextPage();
    }
  }, [state.page]);

  const loadData = async () => {
    const searchResult = await searchService.searchItems({ ...searchParams, page: state.page });

    const { last, content } = searchResult.data;
    const ids = content?.map(item => item.id);

    if (!!ids && ids.length > 0) {
      const itemDetails = await withAuctionsForBulkItems(() => itemService.fetchBulkItemsDetails(ids));
      const sortedItemDetails = sortByIds(ids, itemDetails.data);

      if (state.page === 0 && searchParams.offset && searchParams.offset > 0) {
        return { data: sortedItemDetails.slice(searchParams.offset), last };
      }

      return { data: sortedItemDetails, last };
    } else {
      return { data: [], last };
    }
  };

  const fetchNextPage = async () => {
    if (!state.hasNextPage || state.loading) return;

    setState(prevState => ({ ...prevState, loading: true }));

    try {
      const { data, last } = await loadData();
      setState(prevState => ({
        ...prevState,
        items: prevState.page === 0 ? data : [...prevState.items, ...data],
        hasNextPage: !last,
        page: prevState.page + 1,
      }));
    } catch (error) {
      setState(prevState => ({ ...prevState, error: true }));
    } finally {
      setState(prevState => ({ ...prevState, loading: false }));
    }
  };

  const refresh = () => {
    setState(initialState);
  };

  return {
    fetchNextPage,
    state,
    refresh,
  };
};

export { useSearchItems };
