import { useNavigate } from 'react-router-dom';

import { usePopup } from 'hooks/usePopup';

import ItemErrorPopup from './ItemErrorPopup';

export const useItemError = () => {
  const { showPopup, hidePopup } = usePopup();
  const navigate = useNavigate();

  const handleContactSupport = () => {
    hidePopup();
    navigate('/messenger/context/SUPPORT');
  };

  const showAddItemErrorPopup = () => {
    showPopup(<ItemErrorPopup onContactPress={handleContactSupport} />, { keepPopups: true });
  };

  return { showAddItemErrorPopup };
};
