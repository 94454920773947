import { useEffect, useState } from 'react';
import axios from 'axios';

import { useGlobalData } from 'providers/GlobalDataProvider';

import { ApiResponse } from './apiResponse';
import { campaignService } from './campaign/campaignService';
import { MoneyCollectedDto } from './campaign/campaignService.dto';
import { CurrentUserDto, PublicUserDto } from './user/userService.dto';

const useOwnerMoneyCollected = (user?: PublicUserDto | CurrentUserDto) => {
  const { currentUser } = useGlobalData();
  const [ownerMoneyCollected, setOwnerMoneyCollected] = useState<ApiResponse<MoneyCollectedDto>>({
    content: { moneyDonated: 0, moneyFundraised: 0 },
    loading: true,
  });

  const fetchMoneyCollected = async () => {
    try {
      const response =
        currentUser?.id === user?.id
          ? await campaignService.fetchCurrentUserMoneyCollected()
          : await campaignService.fetchUserMoneyCollected(user!.id);
      setOwnerMoneyCollected({ content: response.data, loading: false });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setOwnerMoneyCollected({ loading: false, error });
      } else {
        throw error;
      }
    }
  };

  useEffect(() => {
    if (!!user) {
      fetchMoneyCollected();
    }
  }, [user]);

  return { ownerMoneyCollected };
};

export default useOwnerMoneyCollected;
