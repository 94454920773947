import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { Text2 } from 'components/typography/Texts';

const Container = styled.div<{ $isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${props => (props.$isSelected ? theme.color.red : theme.color.white)};
  color: ${props => (props.$isSelected ? theme.color.white : theme.color.black)};

  & svg {
    width: 90px;
    fill: ${props => (props.$isSelected ? theme.color.white : theme.color.black)};
    stroke: ${props => (props.$isSelected ? theme.color.white : theme.color.black)};
  }
`;

interface Props extends PropsWithChildren {
  label: string;
  isSelected: boolean;
  onClick: () => void;
}

export const WeightButton = ({ label, isSelected, onClick, children }: Props) => {
  return (
    <Container $isSelected={isSelected} onClick={onClick}>
      {children}
      <Text2>{label}</Text2>
    </Container>
  );
};
