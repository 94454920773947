import { useEffect } from 'react';

import { useSearchCampaigns } from 'services/search/useSearchCampaigns';

export const useSupportableCampaigns = (searchPhrase: string) => {
  const adjustedSearchPhrase = adjustSearchPhrase(searchPhrase);

  const result = useSearchCampaigns({
    status: 'ACTIVE',
    sortType: 'RELEVANT',
    size: 10,
    page: 0,
    searchPhrase: adjustedSearchPhrase,
  });

  useEffect(() => {
    result.refresh();
  }, [adjustedSearchPhrase]);

  return result;
};

const adjustSearchPhrase = (searchPhrase: string) => {
  const trimmedSearchPhrase = searchPhrase.trim();
  return !!trimmedSearchPhrase ? trimmedSearchPhrase : undefined;
};
