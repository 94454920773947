import { EasyGiving } from 'components/ui-elements/EasyGiving';
import NoSearchResult from 'components/ui-elements/NoSearchResult';

import { AvailableCampaigns } from './AvailableCampaigns';

interface Props {
  resetFilters: () => void;
}

export const CampaignsNotFound = ({ resetFilters }: Props) => {
  return (
    <>
      <NoSearchResult />
      <AvailableCampaigns resetFilters={resetFilters} />
      <EasyGiving />
    </>
  );
};
