import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import SearchField from 'components/inputs/SearchField';
import { H4 } from 'components/typography/Headers';
import { CampaignDto } from 'services/campaign/campaignService.dto';

import CampaignsList from './CampaignsList';
import { useSupportableCampaigns } from './useSupportableCampaigns';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 395px;
  gap: 24px;
  height: 80vh;

  ${theme.mq.phone} {
    height: 85vh;
  }
`;

const Header = styled(H4)`
  margin-bottom: 12px;

  ${theme.mq.phone} {
    margin-bottom: 0;
  }
`;

interface Props {
  onSelected: (campaign: CampaignDto) => void;
}

const CampaignPicker = ({ onSelected }: Props) => {
  const { t } = useTranslation('campaign');

  const [searchPhrase, setSearchPhrase] = useState('');
  const { state: campaignsState, fetchNextPage } = useSupportableCampaigns(searchPhrase);

  return (
    <Container data-testid={'campaign-picker'}>
      <Header data-testid={'header'}>{t('picker.header')}</Header>
      <SearchField testId={'search-field'} placeholder={t('picker.search-placeholder')} onSubmit={setSearchPhrase} />
      <CampaignsList
        campaigns={campaignsState.campaigns}
        isLoading={campaignsState.loading}
        hasNextPage={campaignsState.hasNextPage}
        onLoadMore={fetchNextPage}
        onSelected={onSelected}
      />
    </Container>
  );
};

export default CampaignPicker;
