import { parsePhoneNumber } from 'libphonenumber-js';

import { PhoneDto } from 'services/user/userService.dto';

export const convertToPhoneDto = (phoneNumber: string): PhoneDto => {
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
  return {
    countryCode: parsedPhoneNumber.countryCallingCode,
    areaCode: '',
    number: parsedPhoneNumber.nationalNumber,
  };
};

export const convertToString = (phoneDto?: PhoneDto): string => {
  if (!phoneDto) return '';
  return `+${phoneDto.countryCode}${phoneDto.areaCode}${phoneDto.number}`;
};

export const formatMobilePhone = (phoneDto?: PhoneDto): string => {
  const convertedMobilePhone = convertToString(phoneDto);
  const parsedPhoneNumber = parsePhoneNumber(convertedMobilePhone);
  return parsedPhoneNumber ? parsedPhoneNumber.formatInternational() : '';
};
