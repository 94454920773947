import { LocalizationProvider, renderTimeViewClock, StaticDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import calendarIcon from 'assets/svg/calendar.svg';
import { usePopup } from 'hooks/usePopup';
import { formatDateTime } from 'utils/dateFormatter';

import { BaseInputProps } from './BaseInputField';
import TextInputField from './TextInputField';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const CalendarIcon = styled.img`
  position: absolute;
  cursor: pointer;
  right: 12px;
`;

const CustomDateTimePicker = styled(StaticDateTimePicker)`
  & .MuiPickersToolbar-root {
    background-color: ${theme.color.red};
    color: ${theme.color.white};
  }

  & .MuiPickersToolbarText-root {
    color: ${theme.color.white};
  }

  & .MuiPickersToolbarText-root.Mui-selected {
    color: ${theme.color.white};
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: ${theme.color.darkerGray};
  }

  & .MuiSvgIcon-root {
    color: ${theme.color.red};
    font-size: 20px;
  }

  & .MuiTabs-indicator {
    background-color: ${theme.color.red};
  }

  & .MuiDateTimePickerToolbar-ampmLabel.Mui-selected {
    font-weight: 700;
    text-decoration: underline;
    color: ${theme.color.white};
  }

  & .MuiPickersDay-root.Mui-selected {
    background-color: ${theme.color.red};
    color: ${theme.color.white};
  }

  & .MuiPickersDay-root.Mui-selected:focus {
    background-color: ${theme.color.red};
    color: ${theme.color.white};
  }

  & .MuiTypography-overline {
    display: none;
  }

  & .MuiPickersCalendarHeader-label {
    font-size: 14px;
  }

  & .MuiDayCalendar-weekDayLabel {
    font-size: 14px;
  }

  & .MuiPickersDay-root {
    font-size: 18px;
  }

  & .MuiClockNumber-root {
    font-size: 18px;
  }

  & .MuiClockNumber-root.Mui-selected {
    background-color: ${theme.color.red};
    color: ${theme.color.white};
  }

  & .MuiClockPointer-root {
    background-color: ${theme.color.red};
  }

  & .MuiClock-pin {
    background-color: ${theme.color.red};
  }

  & .MuiClockPointer-thumb {
    background-color: ${theme.color.red};
    border-color: ${theme.color.red};
  }

  & .MuiDateTimePickerToolbar-separator {
    margin-top: 6px;
  }

  & .MuiDialogActions-root .MuiButtonBase-root {
    background-color: ${theme.color.red};
    height: 30px;
    font-size: 14px;
    color: ${theme.color.white};
  }
`;

interface Props extends Omit<BaseInputProps, 'value' | 'onChange'> {
  value?: Date;
  onChange: (value?: Date) => void;
  minDate?: Date;
}

const DateInputField = (props: Props) => {
  const { value, onChange, minDate, ...otherProps } = props;
  const { showPopup, hidePopup } = usePopup();

  const formattedValue = value ? formatDateTime(value) : '';

  const handleDateFromCalendarChange = (inputValue: Dayjs | null) => {
    onChange(inputValue?.toDate());
  };

  const openCalendar = () => {
    showPopup(
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CustomDateTimePicker
          value={dayjs(value)}
          onChange={handleDateFromCalendarChange}
          views={['year', 'month', 'day', 'hours', 'minutes']}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
          }}
          onClose={hidePopup}
          minDateTime={minDate && dayjs(minDate)}
        />
      </LocalizationProvider>,
      { keepPopups: true, hideClose: true, paddingVariant: 'NONE' }
    );
  };

  return (
    <Container onClick={openCalendar}>
      <TextInputField value={formattedValue} onChange={() => {}} readonly={true} {...otherProps} />
      <CalendarIcon src={calendarIcon} />
    </Container>
  );
};

export default DateInputField;
