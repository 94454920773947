import { useLoginPopup } from 'features/account';
import { usePopup } from 'hooks/usePopup';
import { useAuth } from 'providers/AuthProvider';

import AddItemForm from './AddItemForm';

export const useItemPopup = () => {
  const { logged } = useAuth();
  const { showLoginPopup } = useLoginPopup();
  const { showPopup, hidePopup } = usePopup();

  const handleClose = () => {
    // TODO ask for confirmation
    hidePopup();
  };

  const checkLoginAndOpenPopup = (openFormPopup: () => void) => {
    if (!logged) showLoginPopup({ onLogged: () => openFormPopup() });
    else openFormPopup();
  };

  const showAddItemPopup = () => {
    checkLoginAndOpenPopup(() => showPopup(<AddItemForm />, { onClose: handleClose }));
  };

  return { showAddItemPopup };
};
