import { AxiosPromise } from 'axios';

import { apiService, apiUrl } from 'services/utils/apiService';

import { ImageRecognitionDto, PresignedPostUrlDto, PresignPostUrlRequest } from './imageService.dto';

class ImageService {
  static BASE_URL = `${apiUrl}/api/image`;

  getImageContent(imageUrl: string, withTopLabel?: boolean): AxiosPromise<ImageRecognitionDto> {
    const url = `${ImageService.BASE_URL}/public/image-content`;
    return apiService.get(url, { imageUrl, withTopLabel: withTopLabel || false });
  }

  presignPostUrls(presignPostUrlRequest: PresignPostUrlRequest): AxiosPromise<PresignedPostUrlDto[]> {
    const url = `${ImageService.BASE_URL}/public/images/presign-post-urls`;
    return apiService.post(url, presignPostUrlRequest);
  }
}

const imageService = new ImageService();

export { imageService, ImageService };
