import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import RedButton from 'components/buttons/RedButton';
import { H5 } from 'components/typography/Headers';
import { Text1 } from 'components/typography/Texts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 350px;

  ${theme.mq.phone} {
    justify-content: center;
    align-items: center;
  }
`;

const Title = styled(H5)`
  color: ${theme.color.white};
`;

const Description = styled(Text1)`
  color: ${theme.color.white};

  ${theme.mq.phone} {
    text-align: center;
  }
`;

const Button = styled(RedButton)`
  border: 1px solid ${theme.color.white};
  max-width: 195px;
`;

interface Props {
  title: string;
  description: string;
  buttonLabel: string;
  onButtonClick: () => void;
}

export const ActionItem = ({ title, description, buttonLabel, onButtonClick }: Props) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Button onClick={onButtonClick}>{buttonLabel}</Button>
    </Container>
  );
};
