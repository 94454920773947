import { useRef } from 'react';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { useGridLayout } from 'hooks/useGridLayout';
import { ItemDto } from 'services/item/itemService.dto';

import ItemTile from './item-tile/ItemTile';

const TilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: 24px;
  padding: 24px 0 24px 0;

  ${theme.mq.desktop} {
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
  }

  ${theme.mq.phone} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

interface Props {
  items: ItemDto[];
  hasNextPage: boolean;
}
export const ItemTiles = ({ items, hasNextPage }: Props) => {
  const gridRef = useRef<HTMLDivElement>(null);
  const visibleItems = useGridLayout(items, gridRef, hasNextPage);

  return (
    <TilesContainer ref={gridRef}>
      {visibleItems.map(item => (
        <ItemTile key={item.id.toString()} item={item} />
      ))}
    </TilesContainer>
  );
};
