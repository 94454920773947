import styled from 'styled-components';

import { LoadMoreButton } from 'components/buttons/LoadMoreButton';
import SupportCampaignTile from 'components/campaign/campaign-tile/SupportCampaignTile';
import NoSearchResult from 'components/ui-elements/NoSearchResult';
import { CampaignDto } from 'services/campaign/campaignService.dto';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
`;

const BottomWrapper = styled.div`
  margin-bottom: 24px;
`;

interface Props {
  campaigns: CampaignDto[];
  isLoading: boolean;
  hasNextPage: boolean;
  onLoadMore: () => void;
  onSelected: (campaign: CampaignDto) => void;
}

const CampaignsList = ({ campaigns, isLoading, hasNextPage, onSelected, onLoadMore }: Props) => {
  const displayNoResult = !isLoading && campaigns.length === 0;
  const displayData = campaigns.length > 0;

  return (
    <Container>
      {displayNoResult && <NoSearchResult />}
      {displayData &&
        campaigns.map(campaign => (
          <SupportCampaignTile key={campaign.id} campaign={campaign} onSupportClick={() => onSelected(campaign)} />
        ))}

      <BottomWrapper>
        <LoadMoreButton loading={isLoading} hasNextPage={hasNextPage} onClick={onLoadMore} />
      </BottomWrapper>
    </Container>
  );
};

export default CampaignsList;
