import { useTranslation } from 'react-i18next';

import { usePopup } from 'hooks/usePopup';
import { useNotification } from 'providers/NotificationProvider';
import { PurchaseDto } from 'services/order/orderService.dto';

import { CancelOrder } from './CancelOrder';

export const useCancelPopup = () => {
  const { t } = useTranslation();
  const { showPopup, hidePopup } = usePopup();
  const { showNotification } = useNotification();

  const orderCancelled = () => {
    showNotification({
      title: t('notifications:success-action'),
      type: 'success',
    });
  };
  const invalidRequest = () => {
    showNotification({
      title: t('notifications:invalid-action'),
      type: 'error',
    });
  };

  const showCancelPopup = (order: PurchaseDto) => {
    showPopup(
      <CancelOrder
        order={order}
        hidePopup={hidePopup}
        onInvalidRequest={invalidRequest}
        onOrderCancelled={orderCancelled}
      />
    );
  };

  return { showCancelPopup };
};
