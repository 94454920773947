import styled from 'styled-components';

import PhotoPlaceholderSource from 'assets/svg/profile-placeholder.svg';
import VipIconSource from 'assets/svg/vip-icon.svg';
import { H5 } from 'components/typography/Headers';
import Spinner from 'components/ui-elements/Spinner';
import { PublicUserDto } from 'services/user/userService.dto';
import { extractUserName } from 'utils/extractUserName';
import { convertToThumbnail300 } from 'utils/thumbnails';

const Container = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SellerPhoto = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-right: 15px;
`;

const SellerName = styled(H5)`
  margin-right: 10px;
`;

const VipIcon = styled.img`
  width: 21px;
  height: 21px;
`;

interface Props {
  seller?: PublicUserDto;
}

const SellerInfo = ({ seller }: Props) => {
  if (!seller) {
    return (
      <Container data-testid={'seller-info'}>
        <Spinner />
      </Container>
    );
  } else {
    return (
      <Container data-testid={'seller-info'}>
        <SellerPhoto
          data-testid={'seller-photo'}
          src={seller.imageUrl ? convertToThumbnail300(seller.imageUrl) : PhotoPlaceholderSource}
        />
        <SellerName data-testid={'seller-name'}>{extractUserName(seller)}</SellerName>
        {seller.vip && <VipIcon data-testid={'vip-icon'} src={VipIconSource} />}
      </Container>
    );
  }
};

export default SellerInfo;
