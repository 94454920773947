import styled from 'styled-components';

import { LoadingIndicator } from 'components/ui-elements/LoadingIndicator';
import { convertToThumbnail600 } from 'utils/thumbnails';

import CampaignStatusIcon from './CampaignStatusIcon';

const ImageContainer = styled.div`
  width: 100%;
  aspect-ratio: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  image?: string;
  isHidden: boolean;
  campaignStatus: string;
  className?: string;
}

const CampaignPhoto = ({ image, isHidden, campaignStatus, className }: Props) => {
  return (
    <ImageContainer className={className}>
      {!!image ? (
        <Image src={convertToThumbnail600(image)} alt={'campaign image'} data-testid={'campaign-image'} />
      ) : (
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      )}
      <CampaignStatusIcon isHidden={isHidden} campaignStatus={campaignStatus} />
    </ImageContainer>
  );
};

export default CampaignPhoto;
