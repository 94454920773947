import { CheckoutResult } from 'features/checkout/payment/checkoutResult.types';
import { OrderUserDto } from 'services/order/orderService.dto';
import { BillingDataDto, PaymentTokenDataDto } from 'services/payment/paymentService.dto';

export interface PaymentButtonProps {
  amount: number;
  currency: string;
  paymentId: number;
  onPaymentAuthorized: PaymentAuthorizedHandler;
  onStateChange: ButtonStateChangeHandler;
}

export interface PaymentTokenData {
  payment: PaymentTokenDataDto;
  user: OrderUserDto;
  billingData: BillingDataDto;
}

export const isPaymentTokenData = (data: any): data is PaymentTokenData => {
  return 'payment' in data && 'token' in data.payment;
};

export type ButtonStateChangeHandler = (state: PaymentButtonState) => void;

export type PaymentAuthorizedHandler = (request: PaymentTokenData) => Promise<CheckoutResult>;

export type PaymentButtonState = 'notInitialized' | 'ready' | 'notAvailable';
