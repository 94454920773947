import { useState } from 'react';

import { usePurchasePayment } from 'features/checkout/payment/usePurchasePayment';
import { usePurchasePreparation } from 'features/checkout/payment/usePurchasePreparation';
import { PaymentTokenData } from 'features/checkout/social-media/socialMedia.types';
import { usePayment } from 'providers/PaymentProvider';
import { useShippingAddress } from 'providers/ShippingAddressProvider';
import { ItemDto } from 'services/item/itemService.dto';
import { PurchaseDto } from 'services/order/orderService.dto';
import { TransactionDto } from 'services/payment/paymentService.dto';

import { deriveCheckoutStatus, deriveDefaultDeliveryType, deriveIsDeliveryTypeLocked } from './checkoutUtils';

export const useItemCheckout = (
  item: ItemDto,
  payForOffer: boolean,
  onPurchasePaid: (purchase: PurchaseDto, transaction: TransactionDto) => void,
  existingOrder?: PurchaseDto
) => {
  const [deliveryType, setDeliveryType] = useState(deriveDefaultDeliveryType(item));
  const isDeliveryTypeLocked = deriveIsDeliveryTypeLocked(item);

  const { defaultPaymentMethod: paymentMethod, isLoading: isLoading1, isSaving: isSaving1 } = usePayment();
  const { defaultShippingAddress: shippingAddress, isLoading: isLoading2, isSaving: isSaving2 } = useShippingAddress();
  const isLoadingData = isLoading1 || isLoading2 || isSaving1 || isSaving2;

  const { purchase, confirmPurchase, resetPurchase, isProcessingPurchase } = usePurchasePreparation(
    item,
    payForOffer,
    deliveryType,
    shippingAddress,
    existingOrder
  );

  const { confirmCheckout, isProcessingPayment } = usePurchasePayment(confirmPurchase, resetPurchase, onPurchasePaid);

  const confirmCheckoutWithCard = () => confirmCheckout(paymentMethod!);
  const confirmCheckoutWithToken = (tokenData: PaymentTokenData) => confirmCheckout(tokenData);

  const checkoutStatusData = { isLoadingData, deliveryType, paymentMethod, shippingAddress, purchase };
  const checkoutTokenStatus = deriveCheckoutStatus({ ...checkoutStatusData, isTokenPayment: true });
  const checkoutCardStatus = deriveCheckoutStatus({ ...checkoutStatusData, isTokenPayment: false });

  return {
    deliveryType,
    setDeliveryType,
    isDeliveryTypeLocked,
    isProcessingPurchase,
    isProcessingPayment,
    checkoutTokenStatus,
    checkoutCardStatus,
    purchase,
    confirmCheckoutWithCard,
    confirmCheckoutWithToken,
  };
};
