import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

const Tooltip = styled.div<{ $visible: boolean }>`
  position: absolute;
  background-color: ${theme.color.red};
  color: white;
  padding: 3px 6px;
  border-radius: 6px;
  font-size: 12px;
  white-space: nowrap;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
  transition: opacity 0.2s ease, visibility 0.2s ease;
  left: -75%;
  top: -120%;

  &:after {
    content: '';
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 9px;
    border-style: solid;
    border-color: ${theme.color.red} transparent transparent transparent;
  }
`;

interface Props {
  visible: boolean;
}

const LinkCopied = ({ visible }: Props) => {
  const { t } = useTranslation('share');
  return <Tooltip $visible={visible}>{t('menu.link-copied')}</Tooltip>;
};

export default LinkCopied;
