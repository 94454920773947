import { HTMLInputTypeAttribute, RefObject } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';

import BaseInputField, {
  BaseInputProps,
  containerStyle,
  focusStyle,
  fontStyle,
  inputStyle,
  placeholderStyle,
} from './BaseInputField';

const Textarea = styled(TextareaAutosize)<{ $error: boolean; $success: boolean }>`
  ${containerStyle}
  ${fontStyle};
  ${inputStyle};

  min-height: 80px;
  resize: none;
  scrollbar-gutter: stable;
  white-space: pre-wrap;

  &:focus {
    ${focusStyle}
  }

  &::placeholder {
    ${placeholderStyle}
  }
`;

interface Props extends BaseInputProps {
  inputType?: HTMLInputTypeAttribute;
  inputRef?: RefObject<HTMLInputElement>;
  readonly?: boolean;
}

const TextArea = (props: Props) => {
  const { value, placeholder, errorMessage, successMessage, onChange } = props;
  const { className, testId, disabled, maxLength, readonly } = props;

  return (
    <BaseInputField testId={testId} className={className} errorMessage={errorMessage} successMessage={successMessage}>
      <Textarea
        data-testid={'input'}
        value={value}
        placeholder={placeholder}
        onChange={event => onChange(event.target.value)}
        $error={!!errorMessage}
        $success={!!successMessage}
        disabled={disabled}
        maxLength={maxLength}
        readOnly={readonly}
        maxRows={8}
      />
    </BaseInputField>
  );
};

export default TextArea;
