import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import WhiteButton from 'components/buttons/WhiteButton';
import ProgressBar from 'components/ui-elements/ProgressBar';
import { useItemPopup } from 'features/add-item/form/useItemPopup';
import { useModal } from 'providers/ModalProvider';
import { CampaignDto } from 'services/campaign/campaignService.dto';
import calculateDaysLeft from 'utils/daysLeftUtils';

import CampaignSummary from './campaign-summary/CampaignSummary';

const CampaignDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 140px;
  max-height: 400px;
  width: 100%;
  border-radius: 5px;
  border: solid 1px ${theme.color.darkGray};
  padding: 36px;

  ${theme.mq.tablet} {
    padding: 12px;
  }
`;

const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 0 0;
  width: 100%;
  justify-content: center;
  align-items: center;

  ${theme.mq.tablet} {
    margin: 0;
    position: relative;
  }
`;

const DonateButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const DonateButton = styled(WhiteButton)`
  margin: 5px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  right: 0;
  background-color: ${theme.color.red};
  color: ${theme.color.white};

  ${theme.mq.tablet} {
    position: static;
  }
`;

interface Props {
  campaign: CampaignDto;
}

const CampaignDetails = (props: Props) => {
  const { id, fundsRaised, moneyGoal, status, supportersNumber, timelineFrom, timelineTo } = props.campaign;
  const daysLeft = calculateDaysLeft(timelineFrom, timelineTo);
  const { t } = useTranslation('campaign');
  const { showGetApp } = useModal();
  const { showAddItemPopup } = useItemPopup();

  const onDonateNowClick = () => showGetApp({ appAreaType: 'CAMPAIGN', objectId: id, description: t('donate-now') });

  const isActive = status === 'ACTIVE';

  return (
    <CampaignDetailsContainer>
      <ProgressBarWrapper data-testid={'campaign-progress-bar'}>
        <ProgressBar value={fundsRaised} total={moneyGoal} />
      </ProgressBarWrapper>
      <DetailsWrapper>
        {isActive && (
          <DonateButtonWrapper>
            <DonateButton data-testid={'donate-item-button'} onClick={showAddItemPopup}>
              {t('donate-item')}
            </DonateButton>
            <DonateButton data-testid={'donate-now-button'} onClick={onDonateNowClick}>
              {t('donate-now')}
            </DonateButton>
          </DonateButtonWrapper>
        )}
        <CampaignSummary supporters={supportersNumber} daysLeft={daysLeft} />
      </DetailsWrapper>
    </CampaignDetailsContainer>
  );
};

export default CampaignDetails;
