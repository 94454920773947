import { useEffect } from 'react';

import { PageState } from 'providers/filtersUtils';
import { SearchCampaignsParams } from 'services/search/searchService.dto';
import { useSearchCampaigns } from 'services/search/useSearchCampaigns';

const FEATURED_CAMPAIGNS_COUNT = 5;
const UNFILTERED_PAGE_SIZE = 14;
const FILTERED_PAGE_SIZE = 9;

export const useFeaturedCampaigns = () =>
  useSearchCampaigns({ status: 'ACTIVE', sortType: 'RELEVANT', size: FEATURED_CAMPAIGNS_COUNT, page: 0 });

export const useCampaigns = (filters: SearchCampaignsParams, pageState: PageState) => {
  const pageSize = filters.size ?? (pageState === 'Default' ? UNFILTERED_PAGE_SIZE : FILTERED_PAGE_SIZE);

  const campaigns = useSearchCampaigns({
    ...(pageState === 'Default' ? { status: 'ACTIVE' } : { status: filters.status }),
    sortType: filters.sortType,
    size: pageSize,
    page: 0,
    lat: filters.lat,
    lon: filters.lon,
    category: filters.category,
    ...(filters.sortType === 'RELEVANT' && pageState === 'Default' && { offset: FEATURED_CAMPAIGNS_COUNT }),
    searchPhrase: filters.searchPhrase ?? undefined,
  });

  const { refresh } = campaigns;

  useEffect(() => {
    refresh();
  }, [filters]);

  return campaigns;
};

export const useHomeCampaigns = (campaignsCount: number) => {
  const homeCampaigns = useSearchCampaigns({
    status: 'ACTIVE',
    sortType: 'RELEVANT',
    size: campaignsCount,
    page: 0,
  });

  const { refresh } = homeCampaigns;

  useEffect(() => {
    refresh();
  }, [campaignsCount]);

  return homeCampaigns;
};
