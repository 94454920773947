import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { H6 } from 'components/typography/Headers';
import { Text2 } from 'components/typography/Texts';
import Spinner from 'components/ui-elements/Spinner';
import { ShippingAddressFormHeader } from 'features/my-profile/shipping-address/components/ShippingAddressFormHeader';
import { useShippingAddress } from 'providers/ShippingAddressProvider';

import { ProposedShippingAddress } from './ProposedShippingAddress';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SuggestionInfo = styled(Text2)`
  margin-top: 15px;
  margin-bottom: 20px;
`;

interface Props {
  header?: string;
}

export const ShippingAddressSuggestions = ({ header }: Props) => {
  const { t } = useTranslation('myProfile');
  const { proposedAddress, submittedAddressRequest, editedShippingAddressId, shippingAddresses, isSaving, initState } =
    useShippingAddress();
  const shippingAddress = shippingAddresses.find(address => address.id === editedShippingAddressId);

  return (
    <div data-testid={'shipping-address-suggestions'}>
      <ShippingAddressFormHeader header={header} onCancel={initState} />
      <H6>{t('shipping-address.suggestion-header')}</H6>
      <SuggestionInfo>{t('shipping-address.suggestion-description')}</SuggestionInfo>
      {isSaving && <Spinner />}
      {!isSaving && (
        <Container>
          <ProposedShippingAddress shippingAddress={shippingAddress} shippingAddressRequest={submittedAddressRequest} />
          <ProposedShippingAddress
            shippingAddress={shippingAddress}
            shippingAddressRequest={submittedAddressRequest}
            proposedAddress={proposedAddress}
          />
        </Container>
      )}
    </div>
  );
};
