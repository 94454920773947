import styled from 'styled-components';

import { ReasonDto } from 'services/report/reportService.dto';

import ReportReason from './ReportReason';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

interface Props {
  reasons: ReasonDto[];
  selectedReasons: ReasonDto[];
  onSelectReason: (reasonDto: ReasonDto) => void;
  onUnselectReason: (reasonDto: ReasonDto) => void;
}

const ReportReasonsList = (props: Props) => {
  const { reasons, selectedReasons, onSelectReason, onUnselectReason } = props;

  return (
    <Container>
      {reasons.map((item: ReasonDto) => (
        <ReportReason
          key={item.name}
          name={item.name}
          isActive={isReasonActive(selectedReasons, item)}
          onSelect={(name: string) => onSelectReason({ name: name })}
          onUnselect={(name: string) => onUnselectReason({ name: name })}
        />
      ))}
    </Container>
  );
};

const isReasonActive = (selectedReasons: ReasonDto[], current: ReasonDto): boolean => {
  return selectedReasons.map((reason: ReasonDto) => reason.name).includes(current.name, 0);
};

export default ReportReasonsList;
