import { useTranslation } from 'react-i18next';

import auctionItemIcon from 'assets/svg/item-auction-icon.svg';
import multipleItemIcon from 'assets/svg/item-multiple-icon.svg';
import standardItemIcon from 'assets/svg/item-standard-icon.svg';
import SelectWithArrowButton from 'components/buttons/SelectWithArrowButton';
import FormStepWrapper from 'components/form/FormStepWrapper';
import { useMultiStepForm } from 'components/form/MultiStepFormProvider';
import { useAddItem } from 'features/add-item/context/AddItemProvider';
import { ItemSellingMethod } from 'services/item/itemService.dto';

const SelectSellingMethod = () => {
  const { t } = useTranslation('addItem');
  const { formData, setFieldValue, errors } = useAddItem();
  const { onNextPress } = useMultiStepForm();

  const handleSelected = (sellingMethod: ItemSellingMethod) => {
    setFieldValue('sellingMethod', sellingMethod);
    onNextPress();
  };

  return (
    <FormStepWrapper
      header={t('type-of-sale.header')}
      description={t('type-of-sale.description')}
      isValid={!errors.sellingMethod}
      isNextHidden={!formData.sellingMethod}>
      <SelectWithArrowButton
        onClick={() => handleSelected('STANDARD')}
        label={t('type-of-sale.type-standard.title')}
        subLabel={t('type-of-sale.type-standard.description')}
        selected={formData.sellingMethod === 'STANDARD'}
        testId={'add-item-select-selling-method-standard'}>
        <img src={standardItemIcon} alt={t('type-of-sale.type-standard.title')} />
      </SelectWithArrowButton>
      <SelectWithArrowButton
        onClick={() => handleSelected('AUCTION')}
        label={t('type-of-sale.type-auction.title')}
        subLabel={t('type-of-sale.type-auction.description')}
        selected={formData.sellingMethod === 'AUCTION'}
        testId={'add-item-select-selling-method-auction'}>
        <img src={auctionItemIcon} alt={t('type-of-sale.type-auction.title')} />
      </SelectWithArrowButton>
      <SelectWithArrowButton
        onClick={() => handleSelected('MULTIPLE')}
        label={t('type-of-sale.type-multiple.title')}
        subLabel={t('type-of-sale.type-multiple.description')}
        selected={formData.sellingMethod === 'MULTIPLE'}
        testId={'add-item-select-selling-method-multiple'}>
        <img src={multipleItemIcon} alt={t('type-of-sale.type-multiple.title')} />
      </SelectWithArrowButton>
    </FormStepWrapper>
  );
};

export default SelectSellingMethod;
