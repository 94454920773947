import { imageService } from 'services/image/imageService';
import { PresignedPostUrlDto, UploadContext } from 'services/image/imageService.dto';
import { messageService } from 'services/message/messageService';
import { MessageUrlsDto } from 'services/message/messageService.dto';
import { parseBackendDate } from 'utils/backendDateParser';
import { base64ToFile } from 'utils/fileUtils';

import { apiService } from './apiService';

interface UploadResult {
  imageUrl?: string;
}

const PRESIGNED_POST_URL_FETCH_SIZE = 12;
const PRESIGNED_POST_URL_USER_FETCH_SIZE = 1;

export class ImageUploadService {
  private static EXPIRATION_OFFSET = 5 * 1000;
  private PRESIGNED_POST_URL_CACHE: Map<UploadContext, PresignedPostUrlDto[]> = new Map();

  async uploadMessageImage(imgSrc: string, conversationId: number): Promise<UploadResult> {
    const tempUrl: MessageUrlsDto = await this.getMessageTempUrl(conversationId);
    const file = base64ToFile(imgSrc);
    await this.uploadImageFromFile(tempUrl, file);
    return { imageUrl: tempUrl.key };
  }

  uploadImageFromFile = async (presignedUrl: PresignedPostUrlDto | MessageUrlsDto, file: File) => {
    const formData = new FormData();
    for (const key in presignedUrl.fields) formData.append(key, presignedUrl.fields[key]);
    formData.set('Content-Type', file.type);
    formData.append('file', file);
    return this.postFile(presignedUrl.url, formData);
  };

  getPresignedPostUrl = async (context: UploadContext): Promise<PresignedPostUrlDto> => {
    const cache = this.PRESIGNED_POST_URL_CACHE.get(context);
    const currentTime = new Date().getTime();

    // first try to find valid url in cache
    while (cache && cache.length) {
      const tempUrl = cache.shift();
      // expiration offset is used to skip url too close of expiration, to have time to start upload
      if (
        tempUrl &&
        currentTime < parseBackendDate(tempUrl.expirationTime).getTime() - ImageUploadService.EXPIRATION_OFFSET
      ) {
        return tempUrl;
      }
    }
    // if no valid temp url found, then ask for couple of new urls from backend
    const urls: PresignedPostUrlDto[] = await this.fetchNewPresignedPostUrls(context);
    // take one of temp urls and save rest for later
    const result = urls.shift();
    this.PRESIGNED_POST_URL_CACHE.set(context, urls);
    return result!;
  };

  private fetchNewPresignedPostUrls = async (context: UploadContext): Promise<PresignedPostUrlDto[]> => {
    const numberOfImages = context === 'USER' ? PRESIGNED_POST_URL_USER_FETCH_SIZE : PRESIGNED_POST_URL_FETCH_SIZE;
    const response = await imageService.presignPostUrls({ numberOfImages, context });
    return response.data;
  };

  private postFile = async (url: string, formData: FormData) => {
    return apiService.post(url, formData, {}, { 'Content-Type': 'multipart/form-data' });
  };

  async getMessageTempUrl(conversationId: number): Promise<MessageUrlsDto> {
    const urls: MessageUrlsDto[] = await this.fetchNewMessageTempUrls(conversationId);
    return urls[0];
  }

  async fetchNewMessageTempUrls(conversationId: number): Promise<MessageUrlsDto[]> {
    const response = await messageService.generateMsgUrls(conversationId);
    return response.data;
  }
}

const imageUploadService = new ImageUploadService();

export { imageUploadService };
