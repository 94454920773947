import { BaseInputProps } from './BaseInputField';
import TextInputField from './TextInputField';

export interface NumericInputProps extends Omit<BaseInputProps, 'value' | 'onChange'> {
  value: number | undefined;
  onChange: (value?: number) => void;
  maxValue?: number;
  valueFormatter?: (value?: number) => string;
}

const NumericInputField = (props: NumericInputProps) => {
  const { value, onChange, maxValue, valueFormatter, ...otherProps } = props;

  const formattedValue = valueFormatter ? valueFormatter(value) : value?.toString();

  const handleInputChange = (inputValue: string) => {
    const numericValue = Number(inputValue.replace(/\D/g, ''));
    const limitedValue = maxValue ? Math.min(numericValue, maxValue) : numericValue;
    onChange(limitedValue > 0 ? limitedValue : undefined);
  };

  return <TextInputField value={formattedValue || ''} onChange={handleInputChange} {...otherProps} />;
};

export default NumericInputField;
