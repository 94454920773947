import i18n from 'assets/translations/config';
import { DeliveryType } from 'features/checkout/delivery/deliveryType';
import { ItemConditionDto, ItemSellingMethod } from 'services/item/itemService.dto';

export interface OptionConfig {
  id: string;
  label: string;
  onFilterChange: () => void;
}

export const filtersConfig = {
  deliveryOptions: (updateFilters: (updatedFields: { deliveryType?: DeliveryType }) => void): OptionConfig[] => [
    {
      id: 'Shipment',
      label: i18n.t('item:filters.delivery.shipping'),
      onFilterChange: () => {
        updateFilters({ deliveryType: 'Shipment' });
      },
    },
    {
      id: 'PickUp',
      label: i18n.t('item:filters.delivery.pickup'),
      onFilterChange: () => {
        updateFilters({ deliveryType: 'PickUp' });
      },
    },
  ],

  sellingMethodOptions: (
    updateFilters: (updatedFields: { sellingMethod?: ItemSellingMethod }) => void
  ): OptionConfig[] => [
    {
      id: 'STANDARD',
      label: i18n.t('item:filters.item-type.hot-deals'),
      onFilterChange: () => {
        updateFilters({ sellingMethod: 'STANDARD' });
      },
    },
    {
      id: 'AUCTION',
      label: i18n.t('item:filters.item-type.auctions'),
      onFilterChange: () => {
        updateFilters({ sellingMethod: 'AUCTION' });
      },
    },
    {
      id: 'MULTIPLE',
      label: i18n.t('item:filters.item-type.multiple'),
      onFilterChange: () => {
        updateFilters({ sellingMethod: 'MULTIPLE' });
      },
    },
  ],
  conditionOptions: (
    itemConditions: ItemConditionDto[],
    updateFilters: (updatedFields: { conditionCode?: string }) => void
  ): OptionConfig[] =>
    itemConditions.map(({ code, shortDisplayName }) => ({
      id: code,
      label: shortDisplayName,
      onFilterChange: () => {
        updateFilters({
          conditionCode: code,
        });
      },
    })),
};

export const labelsConfig = {
  sellingMethodOptions: {
    STANDARD: i18n.t('item:filters.item-type.hot-deals'),
    AUCTION: i18n.t('item:filters.item-type.auctions'),
    MULTIPLE: i18n.t('item:filters.item-type.multiple'),
  },
  deliveryType: {
    PickUp: i18n.t('item:filters.delivery.pickup'),
    Shipment: i18n.t('item:filters.delivery.shipping'),
  },
};
