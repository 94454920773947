import { PaymentTokenData } from 'features/checkout/social-media/socialMedia.types';
import { OrderUserDto } from 'services/order/orderService.dto';
import { BillingDataDto } from 'services/payment/paymentService.dto';

const toToken = (paymentData: google.payments.api.PaymentData) => {
  return paymentData.paymentMethodData.tokenizationData.token;
};

const toUser = (paymentData: google.payments.api.PaymentData): OrderUserDto => {
  const { firstName, lastName } = extractName(paymentData.paymentMethodData.info?.billingAddress);
  const email = paymentData.email || '';

  return { email, firstName, lastName };
};

const toBillingData = (paymentData: google.payments.api.PaymentData): BillingDataDto => {
  const billingAddress = paymentData.paymentMethodData.info?.billingAddress;

  const { firstName, lastName } = extractName(billingAddress);
  const city = billingAddress?.locality || '';
  const postcode = billingAddress?.postalCode || '';
  const state = billingAddress?.administrativeArea || '';
  const mobilePhone = billingAddress?.phoneNumber || '';
  const country = billingAddress?.countryCode || '';
  const address = extractAddress(billingAddress);

  return { firstName, lastName, address, state, city, country, mobilePhone, postcode };
};

const extractName = (address?: google.payments.api.Address) => {
  const name = address?.name || '';

  const [firstName, ...lastName] = name.split(' ').filter(namePart => !!namePart);

  return { firstName, lastName: lastName.join(' ') };
};

const extractAddress = (address?: google.payments.api.Address) => {
  const { address1, address2, address3 } = address || {};
  return [address1, address2, address3].filter(line => !!line).join(' ');
};

const preparePaymentRequest = (paymentData: google.payments.api.PaymentData, paymentId: number): PaymentTokenData => ({
  payment: { paymentId, token: toToken(paymentData) },
  user: toUser(paymentData),
  billingData: toBillingData(paymentData),
});

export { preparePaymentRequest };
