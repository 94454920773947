import styled from 'styled-components';

import { H6 } from 'components/typography/Headers';

export const CampaignName = styled(H6)`
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding: 12px 0 0 0;
`;
