import { Reducer, useReducer } from 'react';
import { uniqBy } from 'lodash';

import { toLastMessage } from 'services/message/lastMessageConverter';
import {
  ConversationDto,
  ConversationLastMessageDto,
  ConversationWithLastMessageDto,
} from 'services/message/messageService.dto';
import PageableType from 'services/pageableType';

enum ConversationActionTypes {
  CONVERSATIONS_FETCH_REQUEST = 'CONVERSATIONS_FETCH_REQUEST',
  CONVERSATIONS_FETCH_SUCCESS = 'CONVERSATIONS_FETCH_SUCCESS',
  CONVERSATIONS_FETCH_ERROR = 'CONVERSATIONS_FETCH_ERROR',
  CONVERSATION_ADD = 'CONVERSATION_ADD',
  CONVERSATIONS_RESET = 'CONVERSATIONS_RESET',
  CONVERSATION_REMOVE = 'CONVERSATION_REMOVE',
  CONVERSATION_LAST_MESSAGE_UPDATE = 'CONVERSATION_LAST_MESSAGE_UPDATE',
  CONVERSATION_READ = 'CONVERSATION_READ',
  CONVERSATION_ADD_MESSAGE = 'CONVERSATION_ADD_MESSAGE',
  CONVERSATION_SET_CURRENT = 'CONVERSATION_SET_CURRENT',
}

type ConversationAction =
  | { type: ConversationActionTypes.CONVERSATIONS_FETCH_REQUEST }
  | {
      type: ConversationActionTypes.CONVERSATIONS_FETCH_SUCCESS;
      payload: {
        conversations: PageableType<ConversationDto>;
        currentUserId?: number;
      };
    }
  | { type: ConversationActionTypes.CONVERSATIONS_FETCH_ERROR }
  | {
      type: ConversationActionTypes.CONVERSATION_ADD;
      payload: {
        conversation: ConversationDto;
      };
    }
  | { type: ConversationActionTypes.CONVERSATION_LAST_MESSAGE_UPDATE; payload: ConversationLastMessageDto }
  | { type: ConversationActionTypes.CONVERSATION_READ; payload: { conversationId: number } }
  | { type: ConversationActionTypes.CONVERSATION_ADD_MESSAGE; payload: ConversationLastMessageDto }
  | { type: ConversationActionTypes.CONVERSATION_REMOVE; payload: { conversationId: number } }
  | { type: ConversationActionTypes.CONVERSATIONS_RESET }
  | { type: ConversationActionTypes.CONVERSATION_SET_CURRENT; payload: { conversationId?: number } };

interface ConversationsState {
  conversations: ConversationWithLastMessageDto[];
  isLoading: boolean;
  pageNumber: number;
  isLastPage: boolean;
  currentConversationId?: number;
}

const conversationsInitialState: ConversationsState = {
  conversations: [],
  isLoading: false,
  pageNumber: 0,
  isLastPage: false,
};

const conversationsReducer = (state: ConversationsState, action: ConversationAction) => {
  switch (action.type) {
    case ConversationActionTypes.CONVERSATIONS_FETCH_REQUEST:
      return { ...state, isLoading: true };
    case ConversationActionTypes.CONVERSATIONS_FETCH_SUCCESS: {
      const currentUserId = action.payload.currentUserId;
      const fetchedConversations = action.payload.conversations;
      if (!fetchedConversations.content) return { ...state, isLoading: false };
      const newConversations: ConversationWithLastMessageDto[] = fetchedConversations.content.map(conversation => {
        return {
          conversation: conversation,
          lastMessage: {
            ...toLastMessage(conversation.lastMessage),
            unreadMessageCount:
              conversation.lastMessage?.authorId === currentUserId ? 0 : conversation.unreadMessageCount || 0,
          },
        };
      });
      return {
        ...state,
        isLoading: false,
        conversations: uniqBy([...state.conversations, ...newConversations], conv => conv.conversation.conversationId),
        isLastPage: action.payload.conversations.last || false,
        pageNumber: action.payload.conversations.last ? state.pageNumber : state.pageNumber + 1,
      };
    }
    case ConversationActionTypes.CONVERSATIONS_FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ConversationActionTypes.CONVERSATION_ADD: {
      const addedConversation = action.payload.conversation;
      const newConversation = {
        conversation: addedConversation,
        lastMessage: {
          ...toLastMessage(addedConversation.lastMessage),
          unreadMessageCount: addedConversation.unreadMessageCount || 0,
        },
      };
      return {
        ...state,
        conversations: uniqBy([...state.conversations, newConversation], conv => conv.conversation.conversationId),
        isLoading: false,
      };
    }
    case ConversationActionTypes.CONVERSATION_REMOVE:
      return {
        ...state,
        conversations: state.conversations.filter(
          it => it.conversation.conversationId !== action.payload.conversationId
        ),
        isLoading: false,
      };
    case ConversationActionTypes.CONVERSATION_LAST_MESSAGE_UPDATE: {
      const conversationMessage = action.payload;
      return {
        ...state,
        conversations: state.conversations
          .map(it => {
            if (
              it.conversation.conversationId === action.payload.conversationId &&
              lastMessageToTime(conversationMessage) > lastMessageToTime(it.lastMessage)
            ) {
              return {
                ...it,
                lastMessage: {
                  ...conversationMessage,
                  unreadMessageCount:
                    it.conversation.unreadMessageCount +
                    resolveUnreadMessageCountIncrement(
                      action.payload.conversationId,
                      state.currentConversationId,
                      action.payload.unreadMessageCount
                    ),
                },
              };
            }
            return it;
          })
          .sort((convA: ConversationWithLastMessageDto, convB: ConversationWithLastMessageDto) => {
            return lastMessageToTime(convB.lastMessage) - lastMessageToTime(convA.lastMessage);
          }),
      };
    }
    case ConversationActionTypes.CONVERSATION_READ: {
      return {
        ...state,
        conversations: state.conversations.map(it => {
          if (it.conversation.conversationId === action.payload.conversationId) {
            return {
              ...it,
              lastMessage: { ...it.lastMessage, unreadMessageCount: 0 },
            };
          }
          return it;
        }),
      };
    }
    case ConversationActionTypes.CONVERSATION_ADD_MESSAGE: {
      const conversationMessage = action.payload;
      return {
        ...state,
        conversations: state.conversations
          .map(it => {
            if (it.conversation.conversationId === action.payload.conversationId) {
              return {
                ...it,
                lastMessage: { ...conversationMessage, unreadMessageCount: 0 },
              };
            }
            return it;
          })
          .sort((convA: ConversationWithLastMessageDto, convB: ConversationWithLastMessageDto) => {
            return lastMessageToTime(convB.lastMessage) - lastMessageToTime(convA.lastMessage);
          }),
      };
    }
    case ConversationActionTypes.CONVERSATIONS_RESET:
      return conversationsInitialState;
    case ConversationActionTypes.CONVERSATION_SET_CURRENT:
      return { ...state, currentConversationId: action.payload.conversationId };
    default:
      return state;
  }
};

const resolveUnreadMessageCountIncrement = (
  conversationId: number,
  currentConversationId?: number,
  unreadMessageCount?: number
) => {
  if (currentConversationId === conversationId && unreadMessageCount === 0) {
    return 0;
  }
  return 1;
};

const lastMessageToTime = (lastMessage: ConversationLastMessageDto) => {
  return new Date(lastMessage.lastMessageCreated || 0).getTime();
};

export const useConversationsReducer = () => {
  const [state, dispatch] = useReducer<Reducer<ConversationsState, ConversationAction>>(
    conversationsReducer,
    conversationsInitialState
  );
  return { state, dispatch };
};

export { ConversationActionTypes, ConversationAction, conversationsInitialState, ConversationsState };
