import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { Text2 } from 'components/typography/Texts';
import { InternalMessageDto } from 'services/message/messageService.dto';

import { MessageDetails } from './MessageDetails';

const TextWrapper = styled.div<{ $isMessageOwner: boolean }>`
  padding: 12px;
  border-radius: 6px;
  border: ${props => (props.$isMessageOwner ? '1px' : '0')} solid ${theme.color.lightGray};
  word-break: break-word;
  background-color: ${props => (props.$isMessageOwner ? theme.color.white : theme.color.lighterGray)};
  margin-bottom: 6px;
  align-self: ${props => (props.$isMessageOwner ? 'flex-end' : 'flex-start')};
`;

const Container = styled.div<{ $isMessageOwner: boolean }>`
  display: flex;
  flex-direction: column;
  margin-left: ${props => (props.$isMessageOwner ? 'auto' : '0')};
  margin-right: ${props => (props.$isMessageOwner ? '0' : 'auto')};
  max-width: 50%;

  ${theme.mq.tablet} {
    max-width: 70%;
  }

  ${theme.mq.phone} {
    max-width: 100%;
  }
`;

interface Props {
  isMessageOwner: boolean;
  message: InternalMessageDto;
}

export const TextMessage = ({ isMessageOwner, message }: Props) => {
  return (
    <Container $isMessageOwner={isMessageOwner}>
      <TextWrapper $isMessageOwner={isMessageOwner}>
        <Text2 data-testid={'message-text'}>{message.content}</Text2>
      </TextWrapper>
      <MessageDetails message={message} isMessageOwner={isMessageOwner} />
    </Container>
  );
};
