import Select from 'react-select';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { makeTransparentColor } from 'assets/styles/transparentColor';

const StyledSelect = styled(Select)`
  width: 170px;
  padding: 0 20px;
  font-size: 16px;

  & .search-dropdown__control {
    cursor: pointer;
    background-color: ${theme.color.lighterGray};
    box-shadow: none;
    border: 0;
  }

  & .search-dropdown__option {
    z-index: 1000;
    cursor: pointer;
  }

  & .search-dropdown__option--is-focused {
    background-color: ${makeTransparentColor(theme.color.red, 0.2)};
  }

  & .search-dropdown__option--is-selected {
    background-color: ${theme.color.red};
  }

  & .search-dropdown__indicators {
    display: none;
  }
`;

interface Option<T> {
  value: T;
  label: string;
}

interface Props<T> {
  value: T;
  options: Option<T>[];
  testId?: string;
  onChange: (value: T) => void;
  onMenuOpen: () => void;
}

const SearchDropdown = <T,>({ options, value, testId, onChange, onMenuOpen }: Props<T>) => {
  const handleChange = (option?: any) => {
    if (option) onChange(option.value);
  };

  return (
    <StyledSelect
      data-testid={testId}
      className='search-dropdown'
      classNamePrefix='search-dropdown'
      menuPosition={'absolute'}
      value={options.find(option => option.value === value)}
      options={options}
      isSearchable={false}
      isMulti={false}
      onChange={handleChange}
      onMenuOpen={onMenuOpen}
    />
  );
};

export default SearchDropdown;
