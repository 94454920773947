import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { makeTransparentColor } from 'assets/styles/transparentColor';
import MessageIconSource from 'assets/svg/message-icon.svg';
import ButtonBase from 'components/buttons/ButtonBase';

const StyledButton = styled(ButtonBase)`
  background-color: ${theme.color.white};
  color: ${theme.color.blue};
  border: 2px solid ${theme.color.blue};

  &:hover {
    box-shadow: 0 0 10px ${makeTransparentColor(theme.color.blue, 0.7)};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const MessageIcon = styled.img`
  width: 22px;
  height: 22px;
`;

const ContactButton = ({ children, ...otherProps }: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <StyledButton {...otherProps}>
      <ContentContainer>
        <MessageIcon src={MessageIconSource} alt={'message-icon'} />
        {children}
      </ContentContainer>
    </StyledButton>
  );
};

export default ContactButton;
