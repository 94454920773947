import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import styled from 'styled-components';

import { Text1, Text1Bold } from 'components/typography/Texts';
import { shipmentService } from 'services/shipment/shipmentService';

import { TrackedItemComponentsProps } from './trackedItemUtils';

const TRACKING_STATUS: { [key: string]: string } = {
  PAID: i18n.t('myProfile:tracked-item.tracking-status.preparing'),
  SHIPPABLE: i18n.t('myProfile:tracked-item.tracking-status.preparing'),
  SHIPPED: i18n.t('myProfile:tracked-item.tracking-status.in-transit'),
  COMPLETED: i18n.t('myProfile:tracked-item.tracking-status.delivered'),
};

const Container = styled.div`
  display: flex;
  margin-top: 19px;
`;

const StatusText = styled(Text1Bold)<{ disabled: boolean }>`
  margin-left: 5px;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
`;

export const TrackingDetails = ({ order, invalidRequest, onClick }: TrackedItemComponentsProps) => {
  const { t } = useTranslation('myProfile');
  const status = order?.status;
  const orderId = order?.id;

  const trackingStatus = TRACKING_STATUS[status!!];
  const isClickable = status === 'SHIPPED';

  const trackInWebBrowser = () => {
    if (isClickable) {
      shipmentService
        .fetchShipments(orderId!!)
        .then(response => {
          onClick?.();
          if (response.data.length > 0 && response.data[0].tracking) {
            window.location.href = response.data[0].tracking;
          }
        })
        .catch(invalidRequest);
    }
  };
  return (
    <Container>
      <Text1 data-testid={'tracking-title'}>{t('tracked-item.tracking')}</Text1>
      <StatusText data-testid={'tracking-status'} disabled={!isClickable} onClick={() => trackInWebBrowser()}>
        {trackingStatus}
      </StatusText>
    </Container>
  );
};
