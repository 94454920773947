import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import arrowIcon from 'assets/svg/red-right-arrow.svg';
import { Text1, Text1Bold } from 'components/typography/Texts';

import ButtonBase from './ButtonBase';

const Button = styled(ButtonBase)<{ $isSelected: boolean }>`
  width: 100%;
  padding: 24px;
  height: auto;
  justify-content: start;
  background-color: ${theme.color.lighterGray};
  border: 2px solid ${props => (props.$isSelected ? theme.color.red : theme.color.lighterGray)};
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
  flex: 1;
  margin: 0 12px;
  white-space: pre-wrap;
`;

const IconWrapper = styled.div`
  display: flex;
  width: 48px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: ${theme.color.borderGray};
  align-items: center;
  justify-content: center;
`;

const ArrowIconWrapper = styled(IconWrapper)`
  width: 36px;
  background-color: ${theme.color.white};
`;

const Icon = styled.img`
  width: 20px;
  aspect-ratio: 1;
`;

interface Props extends PropsWithChildren {
  onClick: () => void;
  label: string;
  subLabel: string;
  selected?: boolean;
  testId?: string;
}

export const SelectWithArrowButton = ({ onClick, label, subLabel, selected, testId, children }: Props) => {
  return (
    <Button data-testid={testId} onClick={onClick} $isSelected={selected || false}>
      <IconWrapper>{children}</IconWrapper>
      <LabelContainer>
        <Text1Bold>{label}</Text1Bold>
        <Text1>{subLabel}</Text1>
      </LabelContainer>
      <ArrowIconWrapper>
        <Icon src={arrowIcon} />
      </ArrowIconWrapper>
    </Button>
  );
};

export default SelectWithArrowButton;
