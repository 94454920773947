import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { CreateButton } from 'components/buttons/CreateButton';
import { RedTextButton } from 'components/buttons/RedTextButton';
import { LoadingContainer } from 'components/containers/Containers';
import { H4 } from 'components/typography/Headers';
import { LoadingIndicator } from 'components/ui-elements/LoadingIndicator';
import { useItemPopup } from 'features/add-item/form/useItemPopup';
import { getNoContentConfig } from 'features/my-profile/my-activity/utils/noContentConfig';
import { useMyActivityNavigate } from 'features/my-profile/my-activity/utils/useMyActivityNavigate';
import { useMyItemNavigate } from 'features/my-profile/my-activity/utils/useMyItemNavigate';
import { useResponsive } from 'hooks/useResponsive';
import { useModal } from 'providers/ModalProvider';
import useMyItems, { ItemFilter } from 'services/useMyItems';

import { FilterButtons } from './FilterButtons';
import { MyActivityItemTile } from './MyActivityItemTile';
import { NoContent } from './NoContent';

const Container = styled.div`
  margin-top: 48px;

  ${theme.mq.phone} {
    margin-top: 24px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InnerContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;

  ${theme.mq.phone} {
    gap: 12px;
  }
`;

const TileContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  ${theme.mq.tablet} {
    gap: 12px;
  }
`;

const ITEMS_PREVIEW_SIZE = 3;

export const HotDealsSection = () => {
  const { t } = useTranslation('myProfile');
  const [filter, setFilter] = useState(ItemFilter.SELLING);
  const { state } = useMyItems({ filter, pageSize: ITEMS_PREVIEW_SIZE });
  const showSeeAll = !state.loading && state.hasNextPage;
  const { showGetApp } = useModal();
  const { showAddItemPopup } = useItemPopup();
  const onAddClick = () => showAddItemPopup();
  const onBuyClick = () => showGetApp({ appAreaType: 'MARKETPLACE' });
  const { goToHotDeals } = useMyActivityNavigate();
  const { goToMyItem } = useMyItemNavigate();

  const { isMobile } = useResponsive();

  const renderLoading = () => (
    <LoadingContainer>
      <LoadingIndicator />
    </LoadingContainer>
  );

  const renderItems = () => (
    <TileContainer>
      {state.items.map(item => (
        <MyActivityItemTile
          key={`${item.id}_${item.orderId}`}
          item={item}
          onClick={(itemId, orderId) => goToMyItem(filter, itemId, orderId)}
        />
      ))}
    </TileContainer>
  );

  const renderNoContent = () => {
    const noContentConfig = getNoContentConfig(onAddClick, onBuyClick);
    const { text, buttonText, onClick } = noContentConfig[filter];
    return <NoContent text={text} buttonText={buttonText} onClick={onClick} />;
  };

  const resolveButtonText = () => (isMobile ? t('my-activity.add') : t('my-activity.add-item'));

  const renderContent = () => {
    if (state.loading) return renderLoading();
    if (state.items && state.items.length > 0) return renderItems();
    return renderNoContent();
  };

  return (
    <Container data-testid={'hot-deals-section'}>
      <HeaderContainer>
        <InnerContainer>
          <H4>{t('my-activity.hot-deals')}</H4>
          <CreateButton onClick={onAddClick}>{resolveButtonText()}</CreateButton>
          {showSeeAll && <RedTextButton onClick={() => goToHotDeals(filter)}>{t('my-activity.see-all')}</RedTextButton>}
        </InnerContainer>
        <FilterButtons selectedFilter={filter} handleFilterChange={setFilter} />
      </HeaderContainer>
      {renderContent()}
    </Container>
  );
};
