import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import RedButton from 'components/buttons/RedButton';
import { RedTextButton } from 'components/buttons/RedTextButton';
import { H4 } from 'components/typography/Headers';
import { Text2 } from 'components/typography/Texts';

import { FormStepProps } from './form.types';
import FormProgressIndicator from './FormProgressIndicator';
import { useMultiStepForm } from './MultiStepFormProvider';

const Container = styled.div`
  width: 535px;
  max-height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  > * {
    padding: 0 70px;
  }

  ${theme.mq.phone} {
    width: 100%;
    > * {
      padding: 0;
      width: 100%;
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${theme.color.lighterGray};
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${theme.color.darkGray};
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${theme.color.darkerGray2};
    cursor: pointer;
  }
`;

const Description = styled(Text2)`
  margin: 24px 12px;
`;

const ButtonsContainer = styled.div`
  width: 100%;
`;

const NextButton = styled(RedButton)`
  margin-top: 48px;
  height: 46px;
`;

const PrevButton = styled(RedTextButton)`
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  overflow-y: auto;
`;

const FormStepWrapper = (props: FormStepProps) => {
  const { t } = useTranslation();
  const { header, description, isNextHidden, isValid, children } = props;
  const { activeStepIndex, totalStepsCount, onNextPress, onPrevPress } = useMultiStepForm();

  const isNextBtnVisible = !isNextHidden && activeStepIndex < totalStepsCount - 1;
  const isPrevBtnVisible = activeStepIndex > 0;

  return (
    <Container data-testid={`form-step-${activeStepIndex}`}>
      <H4 data-testid={'form-header'}>{header}</H4>
      <FormProgressIndicator />
      {description && <Description data-testid={'form-description'}>{description}</Description>}
      <ContentContainer>{children}</ContentContainer>
      <ButtonsContainer>
        {isNextBtnVisible && (
          <NextButton data-testid={'form-next-btn'} disabled={!isValid} onClick={onNextPress}>
            {t('next')}
          </NextButton>
        )}
        {isPrevBtnVisible && (
          <PrevButton data-testid={'form-prev-btn'} onClick={onPrevPress}>
            {t('previous-step')}
          </PrevButton>
        )}
      </ButtonsContainer>
    </Container>
  );
};

export default FormStepWrapper;
