export interface ConversationDto {
  conversationId: number;
  item?: ConversationContextualObjectDto;
  interlocutors: InterlocutorDto[];
  lastMessage?: MessageDto;
  campaign?: ConversationContextualObjectDto;
  context: ConversationMessagingContext;
  unreadMessageCount: number;
  archived: boolean;
}

export interface InterlocutorDto {
  id: number;
  name: string;
  profileImageUrl?: string;
  role: string;
  fundraiserVerificationCompleted: boolean;
  vip: boolean;
}

export interface ConversationContextualObjectDto {
  id: number;
  title: string;
  imageUrl: string;
}

export interface MessageDto {
  id?: number;
  content?: string;
  notificationId?: number;
  conversationId: number;
  payload?: any;
  mediaUrl?: string;
  mediaUrlExpirationTime?: string;
  type: MessageType;
  authorId?: number;
  authorName?: string;
  deliveredToInterlocutors?: InterlocutorDto[];
  deliveredToCurrentUser?: boolean;
  createdAt: string;
  updatedAt: string;
  confirmationReceived?: boolean;
}

export interface InternalMessageDto extends MessageDto {
  internalId: number;
  internalStatus: MessageInternalStatus;
}

export interface UnreadMessageCountDto {
  messagesCount: number;
}

export interface ConversationLastMessageDto {
  conversationId?: number;
  lastMessageContent?: string;
  lastMessageCreated?: string;
  unreadMessageCount?: number;
  type?: MessageType;
  authorId?: number;
  payload?: any;
}

export interface ConversationWithLastMessageDto {
  conversation: ConversationDto;
  lastMessage: ConversationLastMessageDto;
}

export enum MessageType {
  TEXT = 'TEXT',
  NOTIFICATION = 'NOTIFICATION',
  PHOTO = 'PHOTO',
}

export enum MessageInternalStatus {
  UPLOAD_ERROR = 'UPLOAD_ERROR',
  ERROR = 'ERROR',
  UPLOADING = 'UPLOADING',
  SYNC = 'UPLOADED',
}

export type ConversationMessagingContext = 'SUPPORT' | 'ITEM' | 'CAMPAIGN';

export interface MessageUrlsDto {
  expirationTime: Date;
  url: string;
  key: string;
  fields: any;
}
