import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { Location } from 'components/filters/Location';
import { useCampaignsFilters } from 'providers/CampaignFiltersProvider';
import { CampaignCategoryDto } from 'services/campaign/campaignService.dto';

import { Categories } from './Categories';
import { Status } from './Status';

const Spacer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.color.lighterGray};
  margin: 12px 0;
`;

interface Props {
  categories: CampaignCategoryDto[];
}

export const CampaignsFilters = ({ categories }: Props) => {
  const { filters, updateFilters } = useCampaignsFilters();
  return (
    <>
      <Categories
        categories={categories}
        selectedCategories={filters.category || []}
        onCategoryChange={category => updateFilters({ category })}
      />
      <Spacer />
      <Location cityName={filters.cityName || ''} onLocationChange={location => updateFilters({ location })} />
      <Spacer />
      <Status selectedStatus={filters.status || 'ALL'} onStatusChange={status => updateFilters({ status })} />
    </>
  );
};
