import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { clipPathStyle } from 'assets/styles/featuredWave';
import { theme } from 'assets/styles/theme';
import { FeaturedCarousel } from 'components/carousel/FeaturedCarousel';
import { H1, H5, H6 } from 'components/typography/Headers';
import { useFeaturedCampaigns } from 'features/campaign/hooks/useCampaigns';

import { ActionSection } from './ActionSection';

const MainHeader = styled(H1)`
  color: ${theme.color.white};
`;

const SubHeader = styled(H6)`
  color: ${theme.color.white};
`;

const HeaderDescription = styled(H5)`
  color: ${theme.color.white};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 48px 0;
  gap: 24px;
  text-align: center;

  ${theme.mq.phone} {
    margin: 24px 0;
  }
`;

const FeaturedContainer = styled.div`
  background-color: ${theme.color.red};
  width: 100%;
  height: auto;
  position: relative;
  clip-path: ${clipPathStyle};
`;

export const FeaturedSection = () => {
  const { t } = useTranslation('home');
  const { state } = useFeaturedCampaigns();

  return (
    <FeaturedContainer>
      <TextContainer>
        <SubHeader>{t('featured.sub-header')}</SubHeader>
        <MainHeader>{t('featured.main-header')}</MainHeader>
        <HeaderDescription>{t('featured.header-description')}</HeaderDescription>
      </TextContainer>
      <FeaturedCarousel campaigns={state.campaigns} />
      <ActionSection />
    </FeaturedContainer>
  );
};
