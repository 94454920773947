import i18n from 'i18next';

import { CheckoutResult } from 'features/checkout/payment/checkoutResult.types';
import { TransactionValidationErrorCode } from 'services/payment/paymentService.dto';

const convertPaymentResult = (response: CheckoutResult): ApplePayJS.ApplePayPaymentAuthorizationResult => {
  if (response.paymentStatus === 'SUCCESSFUL') {
    return { status: ApplePaySession.STATUS_SUCCESS };
  } else {
    const formErrors = response.validationErrorCodes?.map(error => mapValidationError(error));
    return { status: ApplePaySession.STATUS_FAILURE, errors: formErrors };
  }
};

const mapValidationError = (error: TransactionValidationErrorCode): ApplePayJS.ApplePayError => {
  switch (error) {
    case 'FIRST_NAME_REQUIRED':
      return toBillingContactError('name', i18n.t('checkout:payment-declined.first-name-required'));
    case 'LAST_NAME_REQUIRED':
      return toBillingContactError('name', i18n.t('checkout:payment-declined.last-name-required'));
    case 'CITY_REQUIRED':
      return toBillingContactError('locality', i18n.t('checkout:payment-declined.city-required'));
    case 'COUNTRY_REQUIRED':
      return toBillingContactError('country', i18n.t('checkout:payment-declined.country-required'));
    case 'ADDRESS_REQUIRED':
      return toBillingContactError('addressLines', i18n.t('checkout:payment-declined.address-required'));
    case 'PHONE_REQUIRED':
      return toShippingContactError('phoneNumber', i18n.t('checkout:payment-declined.phone-number-required'));
    default:
      return new ApplePayError('unknown');
  }
};

const toBillingContactError = (field: ApplePayJS.ApplePayErrorContactField, message: string) => {
  return new ApplePayError('billingContactInvalid', field, message);
};

const toShippingContactError = (field: ApplePayJS.ApplePayErrorContactField, message: string) => {
  return new ApplePayError('shippingContactInvalid', field, message);
};

export { convertPaymentResult };
