import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { TextButton1 } from 'components/typography/TextButtons';
import { ShippingAddressForm } from 'features/my-profile/shipping-address/components/ShippingAddressForm';
import { useShippingAddressForm } from 'features/my-profile/shipping-address/hooks/useShippingAddressForm';
import { useShippingAddress } from 'providers/ShippingAddressProvider';
import {
  ProposedShippingAddressDto,
  UserShippingAddressDto,
  UserShippingAddressRequestDto,
} from 'services/user/userService.dto';

import { ProposedShippingAddressDetails } from './ProposedShippingAddressDetails';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.color.lighterGray};
  border-radius: 5px;
  padding: 20px;
  gap: 20px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const StyledTextButton = styled(TextButton1)`
  background-color: ${theme.color.lighterGray};
`;

interface Props {
  shippingAddress?: UserShippingAddressDto;
  shippingAddressRequest?: UserShippingAddressRequestDto;
  proposedAddress?: ProposedShippingAddressDto;
}

export const ProposedShippingAddress = ({ shippingAddress, shippingAddressRequest, proposedAddress }: Props) => {
  const { t } = useTranslation('myProfile');
  const { isSaving } = useShippingAddress();
  const isEditMode = !proposedAddress;
  const { values, errors, submitForm, setFieldValue, isValid, dirty } = useShippingAddressForm(
    shippingAddress,
    shippingAddressRequest
  );

  useEffect(() => {
    if (proposedAddress) {
      setFieldValue('city', proposedAddress.proposedCity);
      setFieldValue('state', proposedAddress.proposedState);
      setFieldValue('zip', proposedAddress.proposedZipCodeLow);
    }
  }, [proposedAddress]);

  const handleSelect = () => proposedAddress && submitForm();

  if (!shippingAddressRequest) return <></>;
  return (
    <Container data-testid={'shipping-address-suggestion'}>
      <HeaderContainer>
        <ProposedShippingAddressDetails
          shippingAddressRequest={shippingAddressRequest}
          proposedAddress={proposedAddress}
          isSelected={!proposedAddress}
          onSelected={handleSelect}
        />
        {isEditMode && !isSaving && (
          <StyledTextButton data-testid={'save-btn'} onClick={submitForm} disabled={!dirty || !isValid}>
            {t('common:save')}
          </StyledTextButton>
        )}
      </HeaderContainer>
      {isEditMode && (
        <ShippingAddressForm values={values} setFieldValue={setFieldValue} errors={errors} isEditMode={true} />
      )}
    </Container>
  );
};
