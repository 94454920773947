import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import pointerMapIcon from 'assets/svg/pointer-map.svg';
import shippingPackageIcon from 'assets/svg/shipping-package.svg';
import { Text1 } from 'components/typography/Texts';
import { ItemDto } from 'services/item/itemService.dto';
import { formatLocationLong } from 'utils/locationFormatter';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 7px;
`;

const IconWrapper = styled.div`
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfoTextWrapper = styled.div`
  margin-left: 10px;
  &:first-of-type {
    margin-right: 10px;
  }
`;

interface Props {
  item: ItemDto;
}

const ShippingInfo = ({ item }: Props) => {
  const { t } = useTranslation('item');
  const itemLocation = item.itemLocation;

  const renderShipping = () => (
    <Row>
      <IconWrapper>
        <img src={shippingPackageIcon} alt='Shipping' />
      </IconWrapper>
      <InfoTextWrapper data-testid={'item-shipping'}>
        <Text1>{t('shipping')}</Text1>
      </InfoTextWrapper>
    </Row>
  );

  const renderPickUp = () => (
    <Row>
      <IconWrapper>
        <img src={pointerMapIcon} alt='Pick-up' />
      </IconWrapper>
      <InfoTextWrapper data-testid={'item-pick-up'}>
        <Text1>
          {t(item.itemType === 'SERVICE' ? 'location' : 'pickup', {
            address: formatLocationLong(itemLocation),
          })}
        </Text1>
      </InfoTextWrapper>
    </Row>
  );

  return (
    <Container>
      {item.canNationwideShipping && renderShipping()}
      {item.canLocalPickUpDelivery && renderPickUp()}
    </Container>
  );
};

export default ShippingInfo;
