import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import logo from 'assets/svg/logo-with-name-vertical.svg';
import RedButton from 'components/buttons/RedButton';
import PasswordInput from 'components/inputs/PasswordInputField';
import { H2 } from 'components/typography/Headers';
import { useSearchParams } from 'hooks/useSearchParams';
import { useSetPassword } from 'hooks/useSetPassword';
import { authService } from 'services/auth/authService';

const Section = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  max-width: 350px;
  overflow: hidden;
  justify-content: center;

  ${theme.mq.tablet} {
    justify-content: flex-start;
  }
`;

const Logo = styled.img`
  height: 140px;
  margin-bottom: 20px;
  margin-top: 48px;
`;

const StyledSubTitle = styled(H2)`
  text-align: center;
  margin-bottom: 70px;
`;

const PasswordInputContainer = styled.div`
  margin-bottom: 15px;
  width: 100%;
`;

const StyledRedButton = styled(RedButton)`
  padding: 11px 20px;
  margin-bottom: 20px;
  height: auto;
  width: 100%;
`;

export const ResetPassword = () => {
  const { t } = useTranslation('account');
  const navigate = useNavigate();
  const { token } = useSearchParams();

  const {
    password,
    setPassword,
    passwordConfirmed,
    setPasswordConfirmed,
    passwordError,
    passwordConfirmedError,
    hasErrors,
  } = useSetPassword();

  const resetPassword = () =>
    authService
      .resetPassword(password, token)
      .then(() => navigate('/reset-password-success'))
      .catch(() => navigate('/reset-password-error'));

  return (
    <Section>
      <Logo src={logo} alt='Giveo' />
      <StyledSubTitle>{t('password.create-new')}</StyledSubTitle>

      <PasswordInputContainer>
        <PasswordInput
          value={password}
          placeholder={t('password.input-placeholder-new')}
          onChange={setPassword}
          errorMessage={passwordError}
        />
      </PasswordInputContainer>

      <PasswordInputContainer>
        <PasswordInput
          value={passwordConfirmed}
          placeholder={t('password.input-placeholder-repeat')}
          onChange={setPasswordConfirmed}
          errorMessage={passwordConfirmedError}
        />
      </PasswordInputContainer>

      <StyledRedButton onClick={resetPassword} disabled={hasErrors}>
        {t('password.create-new')}
      </StyledRedButton>
    </Section>
  );
};
