import { createContext, FC, ReactNode, useContext, useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import closeIconRed from 'assets/svg/close-red.svg';
import closeIcon from 'assets/svg/close-white.svg';
import getAppOverlay from 'assets/svg/getapp-overlay.svg';
import getAppOverlayMobile from 'assets/svg/getapp-overlay-mobile.svg';
import { GetApp } from 'features/get-app/GetApp';
import Share from 'features/share/Share';
import { useResponsive } from 'hooks/useResponsive';
import { AppAreaType } from 'services/notification/notificationService.dto';
import { ShareType } from 'services/utils/shareUtils';

import './popupStyle.css';

const modalStyle = {
  content: {
    top: '10%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translateX(-50%)',
    width: '80%',
    maxWidth: '720px',
    padding: '0',
    border: `1px solid ${theme.color.overlay}`,
  },
  overlay: {
    backgroundColor: `${theme.color.overlay}`,
    zIndex: `${theme.zIndex.foremost}`,
  },
};

const mobileStyle = {
  content: {
    top: 0,
    width: '100%',
    maxWidth: '760px',
  },
};

const Container = styled.div`
  background-color: ${theme.color.white};
  padding: 32px 40px 0 40px;
  display: flex;
  justify-content: center;
  text-align: center;
`;

const CloseButton = styled.img`
  position: absolute;
  top: 23px;
  right: 24px;
  cursor: pointer;
`;

const Overlay = styled.img`
  position: absolute;
  width: 100%;
`;

interface Props {
  appAreaType?: AppAreaType;
  objectId?: number;
  header?: string;
  description?: string;
  shareType?: ShareType;
}

interface ModalContextType {
  showGetApp: (props?: Props, onClose?: () => void) => void;
  showShare: (props?: Props, onClose?: () => void) => void;
}

Modal.setAppElement('body');

const ModalContext = createContext<ModalContextType>(null!);

const ModalProvider: FC<{ children: ReactNode }> = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOverlay, setIsOverlay] = useState(false);
  const [modalContent, setModalContent] = useState<ReactNode>();
  const [onModalClose, setOnModalClose] = useState<() => () => void>();
  const { isMobile } = useResponsive();

  const showGetApp = (props?: Props, onClose?: () => void) => {
    setIsOpen(true);
    setIsOverlay(true);
    setModalContent(<GetApp {...props} />);
    if (onClose) {
      setOnModalClose(() => () => onClose());
    }
  };

  const showShare = (props?: Props) => {
    setIsOpen(true);
    setIsOverlay(false);
    setModalContent(<Share {...props} />);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  return (
    <ModalContext.Provider value={{ showGetApp, showShare }}>
      {props.children}
      <Modal
        isOpen={isOpen}
        style={isMobile ? { ...modalStyle, content: { ...modalStyle.content, ...mobileStyle.content } } : modalStyle}
        closeTimeoutMS={300}>
        {isOverlay && <Overlay src={isMobile ? getAppOverlayMobile : getAppOverlay} />}
        <CloseButton
          data-testid={'close-button'}
          src={isOverlay ? closeIcon : closeIconRed}
          alt='close modal'
          onClick={onModalClose || hideModal}
        />
        <Container>{modalContent}</Container>
      </Modal>
    </ModalContext.Provider>
  );
};

const useModal = () => useContext(ModalContext);

export { useModal, ModalProvider };
