import { useEffect, useState } from 'react';

import { DeliveryType } from 'features/checkout/delivery/deliveryType';
import { useGlobalError } from 'hooks/useGlobalError';
import { ItemDto } from 'services/item/itemService.dto';
import { orderService } from 'services/order/orderService';
import { NewPurchaseDto, PurchaseDto, PurchaseUpdateDto } from 'services/order/orderService.dto';
import { UserShippingAddressDto } from 'services/user/userService.dto';

import { isItemUnavailable } from './paymentErrorUtils';
import { usePurchaseError } from './usePurchaseError';

export const usePurchasePreparation = (
  item: ItemDto,
  payForOffer: boolean,
  deliveryType: DeliveryType,
  shippingAddress?: UserShippingAddressDto,
  existingOrder?: PurchaseDto
) => {
  const { itemNotAvailable } = usePurchaseError();
  const { unknownError } = useGlobalError();

  const [purchase, setPurchase] = useState(existingOrder);
  const [isProcessingPurchase, setProcessingPurchase] = useState(false);

  useEffect(() => {
    preparePurchase();
  }, [item, deliveryType]);

  useEffect(() => {
    if (deliveryType === 'Shipment') {
      preparePurchase();
    }
  }, [shippingAddress]);

  const preparePurchase = async () => {
    if (!!purchase && (purchase.status === 'CREATED' || purchase.status === 'CONFIRMED')) {
      await updatePurchase(purchase);
    } else {
      await createPurchase();
    }
  };

  const createPurchase = async () => {
    try {
      setProcessingPurchase(true);
      setPurchase(undefined);

      if (deliveryType === 'PickUp') {
        await createPickup();
      } else if (deliveryType === 'Shipment' && !!shippingAddress) {
        await createShipment(shippingAddress);
      } else {
        await createNoDelivery();
      }
    } catch (error) {
      handlePreparationError();
    } finally {
      setProcessingPurchase(false);
    }
  };

  const updatePurchase = async (existingPurchase: PurchaseDto) => {
    try {
      setProcessingPurchase(true);

      if (deliveryType === 'PickUp') {
        await updateToPickup(existingPurchase);
      } else if (deliveryType === 'Shipment' && !!shippingAddress) {
        await updateToShipment(existingPurchase, shippingAddress);
      } else if (existingPurchase.status === 'CREATED') {
        await updateToNoDelivery(existingPurchase);
      }
    } catch (error) {
      handlePreparationError();
    } finally {
      setProcessingPurchase(false);
    }
  };

  const confirmPurchase = async () => {
    if (!purchase) return;

    try {
      setProcessingPurchase(true);

      if (purchase.status === 'CONFIRMED') {
        return purchase;
      } else {
        const confirmationRequest: PurchaseUpdateDto = { status: 'CONFIRMED' };
        const { data: confirmedPurchase } = await orderService.updatePurchase(purchase.id, confirmationRequest);
        setPurchase(confirmedPurchase);
        return confirmedPurchase;
      }
    } catch (error) {
      handleConfirmationError(error);
    } finally {
      setProcessingPurchase(false);
    }
  };

  const createPickup = async () => postPurchase({ itemId: item.id, payForOffer, shipmentRequired: false });

  const createShipment = async ({ id: destinationAddressId }: UserShippingAddressDto) =>
    postPurchase({ itemId: item.id, payForOffer, shipmentRequired: true, destinationAddressId });

  const createNoDelivery = async () => postPurchase({ itemId: item.id, payForOffer });

  const postPurchase = async (newPurchase: NewPurchaseDto) => {
    const { data: createdPurchase } = await orderService.createPurchase(newPurchase);
    setPurchase(createdPurchase);
  };

  const updateToPickup = async (existingPurchase: PurchaseDto) =>
    patchPurchase(existingPurchase, { shipmentRequired: false });

  const updateToShipment = async (existingPurchase: PurchaseDto, { id: addressToId }: UserShippingAddressDto) =>
    patchPurchase(existingPurchase, { shipmentRequired: true, addressToId });

  const updateToNoDelivery = async (existingPurchase: PurchaseDto) =>
    patchPurchase(existingPurchase, { shipmentRequired: null });

  const patchPurchase = async (existingPurchase: PurchaseDto, purchaseUpdate: PurchaseUpdateDto) => {
    const { data: updatedPurchase } = await orderService.updatePurchase(existingPurchase.id, purchaseUpdate);
    setPurchase(updatedPurchase);
  };

  const handlePreparationError = () => {
    unknownError();
  };

  const handleConfirmationError = (error: any) => {
    if (isItemUnavailable(error)) {
      itemNotAvailable();
    } else {
      unknownError();
    }
  };

  const resetPurchase = async () => {
    if (!existingOrder) {
      await createPurchase();
    }
  };

  return { purchase, confirmPurchase, resetPurchase, isProcessingPurchase };
};
