import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import ItemTile from 'components/item/item-tile/ItemTile';
import { H5 } from 'components/typography/Headers';
import { ItemWithAuctionDto } from 'services/item/withAuctionsData';

const Container = styled.div`
  width: 100%;
`;

const TileContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-gap: 24px;

  ${theme.mq.huge} {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-gap: 12px;
  }

  ${theme.mq.desktop} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  ${theme.mq.tablet} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  ${theme.mq.phone} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const Header = styled(H5)`
  margin-bottom: 50px;

  ${theme.mq.huge} {
    margin-bottom: 30px;
  }
`;

interface Props {
  header: string;
  items: ItemWithAuctionDto[];
}

const ItemTileList = ({ header, items }: Props) => {
  return (
    <Container>
      <Header>{header}</Header>
      <TileContainer>
        {items.map(item => (
          <ItemTile key={item.id.toString()} item={item} />
        ))}
      </TileContainer>
    </Container>
  );
};

export default ItemTileList;
