import axios, { AxiosPromise } from 'axios';
import { ResponseType } from 'axios';

import { getAuthToken } from 'utils/localStorageData';

const publicUrlPattern = /.\/api\/[^/]+\/public\/.*/; // matches "*/api/*/public/*"

axios.interceptors.request.use(
  async config => {
    config.headers = config.headers ?? {};

    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json';
    }

    if (config.url && config.url.startsWith(apiUrl)) {
      const token = getAuthToken();
      if (token) {
        config.headers['X-Auth-Token'] = token;
      } else if (isUrlProtected(config.url)) {
        return Promise.reject({
          response: {
            status: 401,
            statusText: 'Unauthorized',
            data: 'Access denied. Only public endpoints are allowed.',
          },
        });
      }
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const isUrlProtected = (url: string) => !publicUrlPattern.test(url);

class ApiService {
  get(url: string, params: any = {}, headers: any = {}, responseType?: ResponseType) {
    return axios({
      method: 'get',
      url,
      headers,
      params,
      responseType: responseType || 'json',
    });
  }

  post(url: string, data: any = {}, params: any = {}, headers: any = {}): AxiosPromise {
    return axios({
      method: 'post',
      url,
      headers,
      params,
      data,
    });
  }

  patch(url: string, data: any = {}, params: any = {}, headers: any = {}): AxiosPromise {
    return axios({
      method: 'patch',
      url,
      headers,
      params,
      data,
    });
  }

  put(url: string, data: any = {}, params: any = {}, headers: any = {}): AxiosPromise {
    return axios({
      method: 'put',
      url,
      headers,
      params,
      data,
    });
  }

  delete(url: string, data: any = {}, params: any = {}, headers: any = {}): AxiosPromise {
    return axios({
      method: 'delete',
      url,
      headers,
      params,
      data,
    });
  }
}

const apiService = new ApiService();

const apiUrl = process.env.REACT_APP_API_URL as string;

export { apiService, ApiService, apiUrl };
