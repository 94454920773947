import { SyntheticEvent } from 'react';
import styled from 'styled-components';

const Image = styled.img`
  display: block;
  width: auto;
  height: 100%;
`;

interface Props {
  imageUrl: string;
}

const CarouselPhotoSlide = ({ imageUrl }: Props) => {
  const keepAspectRatio = (event: SyntheticEvent<HTMLImageElement>) => {
    const swiperSlide = event.currentTarget.closest<HTMLElement>('.swiper-slide');
    if (swiperSlide) {
      const width = event.currentTarget.width;
      swiperSlide.style.width = width + 'px';
    }
  };

  return <Image src={imageUrl} alt={'Photo'} onLoad={event => keepAspectRatio(event)} />;
};

export default CarouselPhotoSlide;
