import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import RedButton from 'components/buttons/RedButton';
import { H4 } from 'components/typography/Headers';
import { AuctionDto } from 'services/item/itemService.dto';
import { userService } from 'services/user/userService';
import { PublicUserDto } from 'services/user/userService.dto';

import BiddersList from './BiddersList';
import { convertToBidders, findBids } from './biddersUtils';
import { usePlaceBidPopup } from './hooks/usePlaceBidPopup';

const Container = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
`;

const TopSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Header = styled(H4)`
  padding: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
`;

const LineShadow = styled.div`
  height: 30px;
  width: calc(100% + 80px);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  clip-path: inset(5px 0 -30px 0);
`;

const PlaceBidButton = styled(RedButton)`
  width: 240px;
`;

const BiddersListWrapper = styled.div`
  padding: 25px 0 10px 0;
  flex: 1;
  overflow: auto;
`;

interface Props {
  auction: AuctionDto;
  onBidPlaced: () => void;
  isAuctionOwner: boolean;
}

const BiddersPopup = ({ auction, onBidPlaced, isAuctionOwner }: Props) => {
  const { bids } = findBids(auction);
  const bidderIds = bids.map(it => it.userId);
  const [bidders, setBidders] = useState<PublicUserDto[]>([]);
  const { t } = useTranslation('item');

  const { showPlaceBidPopup } = usePlaceBidPopup();

  useEffect(() => {
    if (bidderIds.length > 0 && bidders.length === 0) {
      userService.fetchBulkPublicUsers(bidderIds).then(response => setBidders(response.data));
    }
  }, [bidderIds, bidders]);

  return (
    <Container>
      <TopSectionWrapper>
        <Header>{t('bidders')}</Header>
        <ButtonWrapper>
          <PlaceBidButton
            data-testid={'place-bid-button'}
            onClick={() => showPlaceBidPopup(auction!!, onBidPlaced)}
            disabled={auction.status !== 'ONGOING' || isAuctionOwner}>
            {t('place-bid-button')}
          </PlaceBidButton>
        </ButtonWrapper>
        <LineShadow />
      </TopSectionWrapper>
      <BiddersListWrapper data-testid={'bidders-list'}>
        <BiddersList bidders={convertToBidders(bids, bidders, auction)} />
      </BiddersListWrapper>
    </Container>
  );
};

export default BiddersPopup;
