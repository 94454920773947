import { LoginStatus, useFacebook } from 'react-facebook';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { makeTransparentColor } from 'assets/styles/transparentColor';
import ButtonBase from 'components/buttons/ButtonBase';
import { useGlobalError } from 'hooks/useGlobalError';

import { FacebookIcon } from './socialMediaIcons';

const StyledButton = styled(ButtonBase)`
  border: 1px solid ${theme.color.socialMediaFacebook};
  background-color: ${theme.color.white};
  color: ${theme.color.lightBlack};

  &:hover {
    box-shadow: 0 0 10px ${makeTransparentColor(theme.color.socialMediaFacebook, 0.7)};
  }
`;

interface Props {
  onAccessToken: (accessToken: string) => void;
}

const FacebookLoginButton = ({ onAccessToken }: Props) => {
  const { t } = useTranslation('account');
  const { api, isLoading } = useFacebook();
  const { unknownError } = useGlobalError();

  const handleLogin = async () => {
    try {
      const currentStatus = await api!.getLoginStatus();
      if (currentStatus.status === LoginStatus.CONNECTED) {
        await api!.logout();
      }

      const loginResponse = await api!.login({ scope: 'email,public_profile' });
      if (loginResponse.status === LoginStatus.CONNECTED) {
        onAccessToken(loginResponse.authResponse.accessToken);
      }
    } catch (error) {
      unknownError();
    }
  };

  return (
    <StyledButton data-testid={'login-facebook-button'} onClick={handleLogin} disabled={isLoading}>
      <FacebookIcon />
      {t('login.facebook-button')}
    </StyledButton>
  );
};

export default FacebookLoginButton;
