import Select from 'react-select';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { makeTransparentColor } from 'assets/styles/transparentColor';

const StyledSelect = styled(Select)`
  font-size: 16px;

  & .sorting-dropdown__control {
    cursor: pointer;
    border-color: ${theme.color.silver};
    box-shadow: none;
  }

  & .sorting-dropdown__control:hover {
    border-color: ${theme.color.silver};
  }

  & .sorting-dropdown__option {
    cursor: pointer;
  }

  & .sorting-dropdown__option--is-focused {
    background-color: ${makeTransparentColor(theme.color.red, 0.2)};
  }

  & .sorting-dropdown__option--is-selected {
    background-color: ${theme.color.red};
  }
`;

interface Option<T> {
  value: T;
  label: string;
}

interface Props<T> {
  value: T;
  options: Option<T>[];
  testId?: string;
  onChange: (value: T) => void;
}

const SortingDropdown = <T,>({ options, value, testId, onChange }: Props<T>) => {
  const handleChange = (option?: any) => {
    if (option) onChange(option.value);
  };

  return (
    <StyledSelect
      data-testid={testId}
      className='sorting-dropdown'
      classNamePrefix='sorting-dropdown'
      menuPosition={'absolute'}
      value={options.find(option => option.value === value)}
      options={options}
      isSearchable={false}
      isMulti={false}
      onChange={handleChange}
    />
  );
};

export default SortingDropdown;
