import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import FormStepWrapper from 'components/form/FormStepWrapper';
import ImageBrowser from 'components/image/ImageBrowser';
import TextInputField from 'components/inputs/TextInputField';
import { useAddItem } from 'features/add-item/context/AddItemProvider';

const VideoUrlInput = styled(TextInputField)`
  margin-top: 14px;
`;

export const AddPhotosStepContent = () => {
  const { t } = useTranslation('addItem');
  const { formData, setFieldValue, errors, addPhoto, deletePhoto, movePhoto, maxPhotosCount } = useAddItem();
  return (
    <>
      <ImageBrowser
        images={formData.photos}
        onAddPhoto={addPhoto}
        onDeletePhoto={deletePhoto}
        onMovePhoto={movePhoto}
        maxPhotosCount={maxPhotosCount}
      />
      <VideoUrlInput
        value={formData.videoUrl || ''}
        onChange={value => setFieldValue('videoUrl', value)}
        placeholder={t('add-photos.video-url-placeholder')}
        errorMessage={errors.videoUrl}
        testId={'add-item-video-url-input'}
      />
    </>
  );
};

const AddPhotos = () => {
  const { t } = useTranslation('addItem');
  const { errors } = useAddItem();

  return (
    <FormStepWrapper
      header={t('add-photos.header')}
      description={t('add-photos.description')}
      isValid={!errors.photos && !errors.videoUrl}>
      <AddPhotosStepContent />
    </FormStepWrapper>
  );
};

export default AddPhotos;
