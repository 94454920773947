import styled from 'styled-components';

import ArrowSource from 'assets/svg/arrow-right.svg';
import ArrowDisabledSource from 'assets/svg/arrow-right-disabled.svg';

type Direction = 'left' | 'right';

const Image = styled.img<{ $enabled: boolean; direction: Direction }>`
  cursor: ${props => (props.$enabled ? 'pointer' : 'auto')};
  width: 10px;
  height: 17px;
  transform: ${props => (props.direction === 'left' ? 'rotate(180deg)' : 'none')};
`;

interface Props {
  enabled: boolean;
  direction: Direction;
  onPress: () => void;
  testId?: string;
}

const ArrowButton = ({ enabled, direction, onPress, testId }: Props) => {
  return (
    <Image
      data-testid={testId}
      src={enabled ? ArrowSource : ArrowDisabledSource}
      $enabled={enabled}
      direction={direction}
      onClick={() => enabled && onPress()}
    />
  );
};

export default ArrowButton;
