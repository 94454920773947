import React from 'react';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { formatMoneyInteger } from 'utils/numberFormatter';

import { focusStyle, placeholderStyle } from './BaseInputField';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

const Input = styled.input`
  width: 100%;
  height: 70px;
  font-family: ${theme.font.family.text};
  font-weight: 400;
  font-size: 40px;
  line-height: 18px;
  color: ${theme.color.lightBlack};
  background: ${theme.color.lighterGray};
  border-radius: 5px;
  border: none;
  text-align: center;

  &:focus {
    ${focusStyle};
  }

  &::placeholder {
    ${placeholderStyle};
  }
`;

interface Props {
  value: number | undefined;
  placeholder?: string;
  className?: string;
  testId?: string;
  onChange: (value: number) => void;
  maxValue?: number;
}

const PriceInputPopupField = (props: Props) => {
  const { value, placeholder, onChange, className, testId, maxValue } = props;

  const formattedPrice = value ? formatMoneyInteger(value) : '';

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const numericValue = Number(inputValue.replace(/\D/g, ''));
    const limitedValue = maxValue ? Math.min(numericValue, maxValue) : numericValue;
    onChange(limitedValue);
  };

  return (
    <Container data-testid={testId} className={className}>
      <Input
        data-testid={'input'}
        value={formattedPrice}
        placeholder={placeholder}
        onChange={handleInputChange}
        inputMode={'numeric'}
      />
    </Container>
  );
};

export default PriceInputPopupField;
