import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { theme } from 'assets/styles/theme';
import fullScreenIcon from 'assets/svg/fullscreen-icon.svg';
import heartIcon from 'assets/svg/heart-icon.svg';

import { CarouselContainer, FullScreenIcon, HeartIcon } from './carousel.styles';
import { CarouselProps } from './carousel.types';
import { CarouselFullScreen } from './CarouselFullScreen';
import CarouselPhotoSlide from './CarouselPhotoSlide';
import CarouselVideoSlide from './CarouselVideoSlide';
import { resolveSlidesLength } from './utils/carouselUtils';
import { toGalleyItems } from './utils/galleryConverter';
import { GalleryItem } from './utils/galleryTypes';

import 'swiper/css';
import 'swiper/css/pagination';

const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;
  border-radius: 5px;

  & .swiper-slide {
    width: 80%;
  }

  & .swiper-pagination-bullets {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 50px;
    bottom: 50px;

    ${theme.mq.huge} {
      padding-right: 30px;
      bottom: 30px;
    }

    ${theme.mq.phone} {
      padding-right: 24px;
      bottom: 24px;
    }
  }

  & .swiper-pagination-bullet {
    width: 13px;
    height: 13px;
    background-color: white;
    opacity: 0.8;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  }

  & .swiper-pagination-bullet-active {
    width: 17px;
    height: 17px;
    opacity: 1;
  }
`;

const SlidingCarousel = (props: CarouselProps) => {
  const { photos, videoUrl, isCampaign: canNotLike, onHeartClick, isFullScreen, setFullScreen } = props;
  const galleryItems = toGalleyItems(photos, videoUrl);

  const swiperRef = useRef<SwiperRef>(null);
  const slidesLength = resolveSlidesLength(props);
  const [isVideoPlaying, setIsVideoPlaying] = useState(!!videoUrl);

  const handlePhotoSlideClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    if (swiperRef.current) {
      const { clientX } = event;
      const slideRect = event.currentTarget.getBoundingClientRect();
      const middle = slideRect.left + slideRect.width / 2;

      if (swiperRef.current.swiper.activeIndex !== index) {
        swiperRef.current.swiper.slideTo(index);
      } else if (swiperRef.current.swiper.activeIndex === slidesLength - 1) {
        swiperRef.current.swiper.slideTo(0);
      } else if (clientX < middle) {
        swiperRef.current.swiper.slidePrev();
      } else {
        swiperRef.current.swiper.slideNext();
      }
    }
  };

  const handleAutomaticSlideClick = () => {
    if (swiperRef.current) {
      if (swiperRef.current.swiper.activeIndex === slidesLength - 1) {
        swiperRef.current.swiper.slideTo(0);
      } else {
        swiperRef.current.swiper.slideNext();
      }
    }
  };

  useEffect(() => {
    if (!isFullScreen && !isVideoPlaying) {
      const intervalId = setInterval(handleAutomaticSlideClick, 3000);

      return () => clearInterval(intervalId);
    }
  }, [isFullScreen, isVideoPlaying]);

  const activeIndex = swiperRef.current?.swiper.activeIndex;

  const renderSlide = (galleryItem: GalleryItem, index: number) => {
    if (galleryItem.type === 'video') {
      return (
        <SwiperSlide key={index}>
          <CarouselVideoSlide
            videoUrl={galleryItem.videoUrl}
            inactive={isFullScreen}
            onVideoStop={() => setIsVideoPlaying(false)}
            onVideoPlay={() => setIsVideoPlaying(true)}
          />
        </SwiperSlide>
      );
    } else {
      return (
        <SwiperSlide key={index} onClick={event => handlePhotoSlideClick(event, index)}>
          <CarouselPhotoSlide imageUrl={galleryItem.imageUrl} />
        </SwiperSlide>
      );
    }
  };

  return (
    <CarouselContainer>
      <StyledSwiper
        ref={swiperRef}
        data-testid={'photo-carousel'}
        slidesPerView={'auto'}
        spaceBetween={1}
        pagination={{ clickable: true }}
        modules={[Pagination]}>
        {galleryItems.map((galleryItem, index) => renderSlide(galleryItem, index))}
        {!canNotLike && <HeartIcon src={heartIcon} onClick={onHeartClick} />}
        <FullScreenIcon src={fullScreenIcon} onClick={setFullScreen} />
        <CarouselFullScreen {...props} initSlideIndex={activeIndex} />
      </StyledSwiper>
    </CarouselContainer>
  );
};

export default SlidingCarousel;
