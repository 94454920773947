import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { LoadingContainer } from 'components/containers/Containers';
import { LoadingIndicator } from 'components/ui-elements/LoadingIndicator';
import { useNotification } from 'providers/NotificationProvider';
import { ItemDto } from 'services/item/itemService.dto';
import { PurchaseDto } from 'services/order/orderService.dto';

import { ListOfSoldButton } from './list-of-sold/ListOfSoldButton';
import { AddressChange } from './tracked-item/AddressChange';
import { AddressDetails } from './tracked-item/AddressDetails';
import { CancelButton } from './tracked-item/CancelButton';
import { ContactUserButton } from './tracked-item/ContactUserButton';
import { MeetUpButtons } from './tracked-item/MeetUpButtons';
import { PayNowButton } from './tracked-item/PayNowButton';
import { PrintLabelButton } from './tracked-item/PrintLabelButton';
import { QuantityDetails } from './tracked-item/QuantityDetails';
import { RefundButton } from './tracked-item/RefundButton';
import { TrackingDetails } from './tracked-item/TrackingDetails';

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 36px;
  border: 1px solid ${theme.color.darkGray};
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  ${theme.mq.huge} {
    padding: 24px;
  }

  ${theme.mq.desktop} {
    padding: 12px;
  }
`;

const ShipmentDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 24px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

interface Props {
  isBuyer: boolean;
  order: PurchaseDto;
  loading: boolean;
  item: ItemDto;
  refreshData: () => void;
}

export const TrackedItemOrderDetails = ({ isBuyer, order, loading, item, refreshData }: Props) => {
  const { t } = useTranslation('myProfile');
  const { showNotification } = useNotification();
  const isShippable = order?.shipmentRequired;
  const isMultiple = item.sellingMethod === 'MULTIPLE' && !isBuyer;

  const invalidRequest = () => {
    showNotification({
      title: t('notifications:invalid-action'),
      type: 'error',
    });
  };

  const renderLoading = () => {
    return (
      <LoadingContainer>
        <LoadingIndicator />
      </LoadingContainer>
    );
  };

  if (loading) {
    return <Container>{renderLoading()}</Container>;
  }

  return (
    <Container>
      {isShippable && !isMultiple && (
        <ShipmentDetails>
          <AddressChange isBuyer={isBuyer} order={order} onAddressUpdated={refreshData} />
          <AddressDetails isBuyer={isBuyer} order={order} />
          <TrackingDetails order={order} invalidRequest={invalidRequest} />
        </ShipmentDetails>
      )}
      {isMultiple && <QuantityDetails item={item} />}
      <ButtonsContainer>
        {isMultiple ? (
          <ListOfSoldButton item={item} />
        ) : (
          <>
            <PayNowButton isBuyer={isBuyer} order={order} />
            <MeetUpButtons isBuyer={isBuyer} order={order} invalidRequest={invalidRequest} />
            <PrintLabelButton isBuyer={isBuyer} order={order} invalidRequest={invalidRequest} />
            <ContactUserButton isBuyer={isBuyer} order={order} />
            <RefundButton isBuyer={isBuyer} order={order} />
            <CancelButton isBuyer={isBuyer} order={order} />
          </>
        )}
      </ButtonsContainer>
    </Container>
  );
};
