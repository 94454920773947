import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ContactButton from 'components/buttons/ContactButton';
import { H4 } from 'components/typography/Headers';
import { Text1 } from 'components/typography/Texts';

const Container = styled.div`
  width: 100%;
`;

const Info = styled(Text1)`
  padding: 36px 0;
`;

interface Props {
  onContactPress: () => void;
}

const ItemErrorPopup = ({ onContactPress }: Props) => {
  const { t } = useTranslation('addItem');

  return (
    <Container data-testid={'item-error-popup'}>
      <H4>{t('common:sorry')}</H4>
      <Info>{t('error.unable-to-list')}</Info>
      <ContactButton onClick={onContactPress}>{t('common:contact-support')}</ContactButton>
    </Container>
  );
};

export default ItemErrorPopup;
