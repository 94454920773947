import { useState } from 'react';
import styled from 'styled-components';

import arrowDownGray from 'assets/svg/arrow-down-gray.svg';
import Checkbox from 'components/inputs/Checkbox';
import { Text1Bold } from 'components/typography/Texts';

import { OptionConfig } from './filterOptionsConfig';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const OptionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  margin-top: 12px;
`;

const OptionItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 14px;
`;

const Arrow = styled.img<{ isOpen: boolean }>`
  width: 16px;
  height: 10px;
  transform: rotate(${props => (props.isOpen ? '180deg' : '0')});
  transition: transform 0.3s;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

interface Props {
  title: string;
  selectedFilters: string | undefined;
  options: OptionConfig[];
  isMultiSelect?: boolean;
  testId: string;
}

export const ToggleFilterSection = ({ testId, title, options, selectedFilters, isMultiSelect }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSection = () => {
    setIsOpen(prevState => !prevState);
  };

  const handleOptionChange = (
    option: string,
    selectedFilters: string[] | string | undefined,
    onFilterChange: (filters: string[] | string | undefined) => void
  ) => {
    if (isMultiSelect) {
      const currentFilters = Array.isArray(selectedFilters) ? selectedFilters : [];
      const isSelected = currentFilters.includes(option);
      const updatedFilters = isSelected
        ? currentFilters.filter(filter => filter !== option)
        : [...currentFilters, option];
      onFilterChange(updatedFilters);
    } else {
      const newFilter = selectedFilters === option ? undefined : option;
      onFilterChange(newFilter);
    }
  };

  return (
    <Container data-testid={`${testId}-container`}>
      <Header onClick={toggleSection} data-testid={`${testId}-header`}>
        <Text1Bold>{title}</Text1Bold>
        <Arrow src={arrowDownGray} isOpen={isOpen} />
      </Header>
      {isOpen && (
        <OptionList data-testid={`${testId}-options`}>
          {options.map(({ id, label, onFilterChange }) => (
            <OptionItem key={id} data-testid={`${testId}-option-item-${id}`}>
              <Checkbox
                checked={isMultiSelect ? selectedFilters?.split(',').includes(id) : selectedFilters === id}
                onChange={() => handleOptionChange(id, selectedFilters, onFilterChange)}
                label={label}
                testId={`${testId}-checkbox-${id}`}
              />
            </OptionItem>
          ))}
        </OptionList>
      )}
    </Container>
  );
};
