import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { useGridLayout } from 'hooks/useGridLayout';
import { CampaignsState } from 'services/search/searchService.dto';

import CampaignTile from './campaign-tile/CampaignTile';

const TilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  gap: 24px;
  margin-bottom: 24px;

  ${theme.mq.huge} {
    gap: 12px;
  }

  ${theme.mq.desktop} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${theme.mq.phone} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

interface Props {
  campaigns: CampaignsState;
}
export const HomeCampaignsTiles = ({ campaigns }: Props) => {
  const navigate = useNavigate();
  const gridRef = useRef<HTMLDivElement>(null);
  const visibleCampaigns = useGridLayout(campaigns.campaigns, gridRef, campaigns.hasNextPage);

  return (
    <TilesContainer ref={gridRef}>
      {visibleCampaigns.map(campaign => (
        <CampaignTile key={campaign.id} campaign={campaign} onClick={() => navigate(`/campaigns/${campaign.id}`)} />
      ))}
    </TilesContainer>
  );
};
