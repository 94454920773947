import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { RedButtonWithArrow } from 'components/buttons/RedButtonWithArrow';
import { HomeCampaignsTiles } from 'components/campaign/HomeCampaignsTiles';
import { H4 } from 'components/typography/Headers';
import { useHomeCampaigns } from 'features/campaign/hooks/useCampaigns';

const CAMPAIGNS_COUNT = 10;

const Container = styled.div`
  width: 100%;
  padding: 24px;

  ${theme.mq.tablet} {
    padding: 12px;
  }
`;

const HeaderContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;

  ${theme.mq.phone} {
    margin-bottom: 12px;
  }
`;

export const CampaignsHomeList = () => {
  const { t } = useTranslation('home');
  const campaigns = useHomeCampaigns(CAMPAIGNS_COUNT);
  const navigate = useNavigate();

  return (
    <Container data-testid={'home-campaigns-list'}>
      <HeaderContainer>
        <H4 data-testid={'home-campaigns-list-header'}>{t('campaigns.header')}</H4>
        <RedButtonWithArrow onClick={() => navigate('/campaigns')} label={t('campaigns.more-campaigns')} />
      </HeaderContainer>
      <HomeCampaignsTiles campaigns={campaigns.state} />
    </Container>
  );
};
