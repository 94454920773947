import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import uploadIcon from 'assets/svg/upload-files-icon.svg';
import { Caption, Text1Bold } from 'components/typography/Texts';
import { ImageDto } from 'services/image/useUploadImages';

import SortableImagesList from './SortableImagesList';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DropAreaContainer = styled.div<{ $isDragging: boolean }>`
  border: ${props => (props.$isDragging ? '2px' : '1px')} dashed ${theme.color.red};
  border-radius: 5px;
  background-color: ${theme.color.lighterGray};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  gap: 10px;
`;

const UploadImg = styled.img`
  width: 40px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  gap: 5px;
`;

const BrowseButton = styled(Text1Bold)<{ disabled: boolean }>`
  color: ${props => (props.disabled ? theme.color.disabled : theme.color.red)};
  text-decoration: underline;
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
`;

const Description = styled(Caption)`
  color: ${theme.color.placeholderGrey};
`;

const ImgInput = styled.input`
  display: none;
`;

interface Props {
  images: ImageDto[];
  onAddPhoto: (file: File) => void;
  onDeletePhoto: (id: string) => void;
  onMovePhoto: (from: number, to: number) => void;
  maxPhotosCount?: number;
}

const ImageBrowser = ({ images, onAddPhoto, onDeletePhoto, onMovePhoto, maxPhotosCount = 12 }: Props) => {
  const { t } = useTranslation();
  const [isDragging, setIsDragging] = useState(false);
  const isPhotosLimitReached = images.length >= maxPhotosCount;

  const handleAddImg = async (e: React.ChangeEvent<HTMLInputElement>) => {
    Array.from(e.target.files || []).forEach(file => onAddPhoto(file));
    e.target.value = '';
  };

  const handleDropImg = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    Array.from(e.dataTransfer.files || []).forEach(file => onAddPhoto(file));
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  return (
    <Container data-testid={'image-browser'}>
      <DropAreaContainer
        $isDragging={isDragging}
        onDrop={handleDropImg}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}>
        <UploadImg src={uploadIcon} />
        <HeaderContainer>
          <Text1Bold>{t('photos.drag-and-drop-label')}</Text1Bold>
          <Text1Bold>{t('or')}</Text1Bold>
          <label htmlFor='img-input'>
            <BrowseButton data-testid={'browse-btn'} disabled={isPhotosLimitReached}>
              {t('photos.browse')}
            </BrowseButton>
          </label>
        </HeaderContainer>
        <Description>{t('photos.supported-formats')}</Description>
      </DropAreaContainer>
      <ImgInput
        id='img-input'
        data-testid={'img-input'}
        type='file'
        multiple
        accept='image/jpeg, image/png, image/heic'
        disabled={isPhotosLimitReached}
        onChange={handleAddImg}
      />
      <SortableImagesList images={images} onDeletePhoto={onDeletePhoto} onMovePhoto={onMovePhoto} />
    </Container>
  );
};

export default ImageBrowser;
