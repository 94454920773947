import { useState } from 'react';
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToHorizontalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import styled from 'styled-components';

import { CarouselFullScreen } from 'components/carousel/CarouselFullScreen';
import { useFullScreen } from 'hooks/useFullScreen';
import { ImageDto } from 'services/image/useUploadImages';

import SortableImage from './SortableImage';

const ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  overflow-x: auto;
`;

interface Props {
  images: ImageDto[];
  onDeletePhoto: (id: string) => void;
  onMovePhoto: (from: number, to: number) => void;
}

const SortableImagesList = ({ images, onDeletePhoto, onMovePhoto }: Props) => {
  const fullScreenProps = useFullScreen();
  const [fullScreenInitialIndex, setFullScreenInitialIndex] = useState(0);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = images.findIndex(item => item.id === active.id);
      const newIndex = images.findIndex(item => item.id === over?.id);
      onMovePhoto(oldIndex, newIndex);
    }
  };

  const handleClick = (index: number) => {
    fullScreenProps.setFullScreen();
    setFullScreenInitialIndex(index);
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      sensors={sensors}
      modifiers={[restrictToParentElement, restrictToHorizontalAxis]}>
      <SortableContext items={images} strategy={horizontalListSortingStrategy}>
        <ImagesContainer>
          {images?.map((image, index) => (
            <SortableImage key={image.id} img={image} onClick={() => handleClick(index)} onDelete={onDeletePhoto} />
          ))}
          <CarouselFullScreen
            {...fullScreenProps}
            photos={images.map(img => img.url || '')}
            initSlideIndex={fullScreenInitialIndex}
          />
        </ImagesContainer>
      </SortableContext>
    </DndContext>
  );
};

export default SortableImagesList;
