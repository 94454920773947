import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

export const TileContainer = styled.div`
  position: relative;
  border: 1px solid ${theme.color.lightGray};
  border-radius: 10px;
  padding: 10px;
  background: ${theme.color.white};
  cursor: pointer;
  min-width: 0;
  height: 100%;
  gap: 10px;
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
`;
