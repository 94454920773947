import { useShippingAddressForm } from 'features/my-profile/shipping-address/hooks/useShippingAddressForm';
import { useShippingAddress } from 'providers/ShippingAddressProvider';

import { ShippingAddressForm } from './ShippingAddressForm';
import { ShippingAddressFormHeader } from './ShippingAddressFormHeader';

interface Props {
  header?: string;
}

export const NewShippingAddress = ({ header }: Props) => {
  const { setIsInsertMode, setEditedShippingAddressId } = useShippingAddress();
  const { values, errors, submitForm, setFieldValue, resetForm, isValid, dirty } = useShippingAddressForm();

  const handleCancel = () => {
    setIsInsertMode(false);
    setEditedShippingAddressId();
    resetForm();
  };

  return (
    <>
      <ShippingAddressFormHeader
        header={header}
        onSave={submitForm}
        onCancel={handleCancel}
        isFormValid={isValid && dirty}
      />
      <ShippingAddressForm values={values} setFieldValue={setFieldValue} errors={errors} isEditMode={false} />
    </>
  );
};
