import { CampaignDto } from 'services/campaign/campaignService.dto';

import { CampaignInactiveOverlay } from './CampaignInactiveOverlay';
import { CampaignName } from './CampaignName';
import CampaignPhoto from './CampaignPhoto';
import { CampaignProgressBar } from './CampaignProgressBar';
import CampaignStats from './CampaignStats';
import { DataContainer, TileContainer } from './containers';

interface Props {
  campaign: CampaignDto;
  onClick: (campaign: CampaignDto) => void;
}

const CampaignTile = ({ campaign, onClick }: Props) => {
  return (
    <TileContainer data-testid={'campaign-tile_' + campaign.id} onClick={() => onClick(campaign)}>
      <CampaignPhoto
        image={campaign.imageUrls[0]}
        isHidden={campaign.isHidden ?? false}
        campaignStatus={campaign.status}
      />
      <DataContainer>
        <CampaignName data-testid={'campaign-name'}>{campaign.name}</CampaignName>
        <CampaignProgressBar value={campaign.fundsRaised} total={campaign.moneyGoal} />
        <CampaignStats
          timelineFrom={campaign.timelineFrom!!}
          timelineTo={campaign.timelineTo}
          supportersNumber={campaign.supportersNumber}
          status={campaign.status}
        />
      </DataContainer>
      <CampaignInactiveOverlay isHidden={campaign.isHidden!!} campaignStatus={campaign.status} />
    </TileContainer>
  );
};

export default CampaignTile;
