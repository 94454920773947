import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import RedButton from 'components/buttons/RedButton';
import WhiteButton from 'components/buttons/WhiteButton';
import { HorizontalSpacer } from 'components/ui-elements/HorizontalSpacer';
import Spinner from 'components/ui-elements/Spinner';
import { useAuth } from 'providers/AuthProvider';
import { useGlobalData } from 'providers/GlobalDataProvider';
import { AuctionDto, ItemDto } from 'services/item/itemService.dto';

import BiddersPreview from './auction/BiddersPreview';

const Container = styled.div`
  margin-top: 24px;
`;

interface Props {
  item: ItemDto;
  onBuyClick: () => void;
  onMakeOfferClick: () => void;
  onPlaceBidClick: () => void;
  auction?: AuctionDto;
  onBidPlaced: () => void;
}

export const ButtonsSection = (props: Props) => {
  const { t } = useTranslation('item');
  const { item, onBuyClick, onMakeOfferClick, onPlaceBidClick, auction, onBidPlaced } = props;
  const { logged } = useAuth();
  const { currentUser } = useGlobalData();
  const isOwner = item.ownerId === currentUser?.id;

  if (logged && !currentUser)
    return (
      <Container>
        <Spinner />
      </Container>
    );

  if (item.sellingMethod === 'AUCTION') {
    return (
      <Container>
        <RedButton
          data-testid={'place-bid-button'}
          onClick={onPlaceBidClick}
          disabled={auction?.status !== 'ONGOING' || isOwner}>
          {t('place-bid-button')}
        </RedButton>
        {!!auction && <BiddersPreview isAuctionOwner={isOwner} auction={auction} onBidPlaced={onBidPlaced} />}
      </Container>
    );
  } else {
    return (
      <Container>
        {!!item.price && (
          <RedButton
            data-testid={'buy-button'}
            onClick={onBuyClick}
            disabled={item.itemStatus !== 'PUBLISHED' || isOwner}>
            {t('buy-button')}
          </RedButton>
        )}
        {item.itemStatus === 'PUBLISHED' && !isOwner && (
          <>
            {!!item.price && item.canAcceptLoHiOffers && <HorizontalSpacer padding={20} label={t('common:or')} />}
            {item.canAcceptLoHiOffers && (
              <WhiteButton data-testid={'make-offer-button'} onClick={onMakeOfferClick}>
                {t('make-offer-button')}
              </WhiteButton>
            )}
          </>
        )}
      </Container>
    );
  }
};
