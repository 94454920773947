import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import BinocularsIconSource from 'assets/svg/binoculars-icon.svg';
import { H5 } from 'components/typography/Headers';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 300px;
`;

const BinocularsIcon = styled.img`
  width: 48px;
  height: 34px;
`;

interface Props {
  className?: string;
}

const NoSearchResult = ({ className }: Props) => {
  const { t } = useTranslation('common');

  return (
    <Container data-testid={'no-search-result'} className={className}>
      <BinocularsIcon src={BinocularsIconSource} alt={'binoculars'} />
      <H5>{t('no-results')}</H5>
    </Container>
  );
};

export default NoSearchResult;
