import { useCallback, useEffect, useState } from 'react';
import { GoogleMap, MarkerClustererF, useJsApiLoader } from '@react-google-maps/api';
import { debounce } from 'lodash';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import closeIcon from 'assets/svg/close-red.svg';
import safetySpotIconBlack from 'assets/svg/safety-black-marker.svg';
import safetySpotIconRed from 'assets/svg/safety-red-marker.svg';
import userMarker from 'assets/svg/user-marker.svg';
import { useMessengerAlert } from 'features/messenger/hooks/useMessengerAlert';
import { useGlobalData } from 'providers/GlobalDataProvider';
import { useSafetySpots } from 'providers/SafetySpotProvider';
import { orderService } from 'services/order/orderService';
import { MeetupPointDto, PurchaseDto, SafetySpotDto } from 'services/order/orderService.dto';
import { userService } from 'services/user/userService';
import { CurrentUserDto, PublicUserDto } from 'services/user/userService.dto';
import { LocationCords, userLocationService } from 'services/utils/location/meetupLocationHelper';

import MessageClusteredMarker from './MessageClusteredMarker';
import { MessageLocationSelectPanel } from './MessageLocationSelectPanel';

const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_KEY as string;
const googleMapId = process.env.REACT_APP_GOOGLE_MAP_ID as string;
const MIN_CLUSTERING_ZOOM = 10;
const MAX_CLUSTERING_ZOOM = 12;

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  border-left: 1px solid ${theme.color.darkGray};
  border-right: 1px solid ${theme.color.darkGray};
`;

const CloseButton = styled.img`
  cursor: pointer;
  padding: 10px;
  position: absolute;
  background-color: white;
  border-radius: 20px;
  top: 25px;
  right: 60px;
`;

interface Props {
  orderId: number;
  onClose: () => void;
  perspective: string | null;
}

interface SelectedMeetupPoint {
  coords?: { latitude: number; longitude: number };
  details?: MeetupPointDto;
  notRecognized?: boolean;
}

interface UserLocation {
  lat: number;
  lng: number;
}

const CLEAR_SPOT_ZOOM_BOUNDARY = 10;
const MIN_ZOOM = 13;

const MessageLocationMap = ({ orderId, onClose, perspective }: Props) => {
  const [orderData, setOrderData] = useState<PurchaseDto>();
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const { fetchAndCacheSpots } = useSafetySpots();
  const { currentUser } = useGlobalData();
  const { handleMessengerActionError } = useMessengerAlert();

  const [safetySpots, setSafetySpots] = useState<SafetySpotDto[]>([]);
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number } | undefined>();
  const [selectedMeetupPoint, setSelectedMeetupPoint] = useState<SelectedMeetupPoint>();
  const [sellerInitials, setSellerInitials] = useState('');
  const [buyerInitials, setBuyerInitials] = useState('');
  const [itemLocation, setItemLocation] = useState<LocationCords>();
  const [sellerLocation, setSellerLocation] = useState<UserLocation>();
  const [buyerLocation, setBuyerLocation] = useState<UserLocation>();
  const [zoom, setZoom] = useState<number>(MIN_ZOOM);

  const { isLoaded } = useJsApiLoader({ googleMapsApiKey: googleMapKey, mapIds: [googleMapId] });

  const resolveTitle = (userData: PublicUserDto | CurrentUserDto) => {
    const firstChar = userData.firstName.charAt(0).toUpperCase();
    const secondChar = userData.lastName?.charAt(0).toUpperCase() || '';
    return `${firstChar}${secondChar}`;
  };

  useEffect(() => {
    if (!orderData) {
      orderService.fetchPurchaseDetails(orderId).then(response => setOrderData(response.data));
    }
  }, [orderId]);

  const setInitials = (orderData: PurchaseDto) => {
    if (perspective === 'BUYER') {
      userService.fetchPublicUser(orderData.ownerId).then(({ data }) => setSellerInitials(resolveTitle(data)));
      setBuyerInitials(resolveTitle(currentUser!));
    } else if (perspective === 'SELLER') {
      setSellerInitials(resolveTitle(currentUser!));
      userService.fetchPublicUser(orderData.buyerId).then(({ data }) => setBuyerInitials(resolveTitle(data)));
    } else {
      userService.fetchPublicUser(orderData.ownerId).then(({ data }) => setSellerInitials(resolveTitle(data)));
      userService.fetchPublicUser(orderData.buyerId).then(({ data }) => setBuyerInitials(resolveTitle(data)));
    }
  };

  useEffect(() => {
    if (!!orderData && !itemLocation) {
      setInitials(orderData);
      userLocationService.getItemLocation(orderData.itemId).then(response => {
        setItemLocation(response);
        if (response.latitude && response.longitude) {
          setMapCenter({ lat: response.latitude, lng: response.longitude });
          setSellerLocation({ lat: response.latitude, lng: response.longitude });
        }
      });
      if (perspective === 'BUYER' && !!currentUser?.location?.lat && !!currentUser?.location?.lon) {
        setBuyerLocation({ lat: currentUser.location.lat, lng: currentUser.location.lon });
      } else {
        userLocationService.getUserLocation(orderData.buyerId).then(data => {
          if (data?.latitude && data.longitude) {
            setBuyerLocation({ lat: data.latitude, lng: data.longitude });
          }
        });
      }
    }
  }, [orderData, itemLocation]);

  useEffect(() => {
    if (map && itemLocation) {
      setMapCenter({ lat: itemLocation.latitude!, lng: itemLocation.longitude! });

      setTimeout(() => {
        const updatedBounds = map.getBounds();

        if (updatedBounds) {
          const latitudeDelta = Math.abs(updatedBounds.getNorthEast().lat() - updatedBounds.getSouthWest().lat());
          const longitudeDelta = Math.abs(updatedBounds.getNorthEast().lng() - updatedBounds.getSouthWest().lng());
          fetchAndCacheSpots(itemLocation.latitude!, itemLocation.longitude!, latitudeDelta, longitudeDelta).then(
            spots => setSafetySpots(spots)
          );
        }
      }, 1000);
    }
  }, [map, itemLocation]);

  const onMapLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  const debouncedFetchSafetySpots = useCallback(
    debounce((centerLatitude, centerLongitude, latitudeDelta, longitudeDelta) => {
      fetchAndCacheSpots(centerLatitude, centerLongitude, latitudeDelta, longitudeDelta).then(spots =>
        setSafetySpots(spots)
      );
    }, 500),
    []
  );

  const onIdle = useCallback(() => {
    if (map) {
      const center = map.getCenter();
      const bounds = map.getBounds();
      const zoom = map.getZoom();

      if (center && bounds && zoom) {
        const centerLatitude = center.lat();
        const centerLongitude = center.lng();
        const latitudeDelta = Math.abs(bounds.getNorthEast().lat() - bounds.getSouthWest().lat());
        const longitudeDelta = Math.abs(bounds.getNorthEast().lng() - bounds.getSouthWest().lng());

        setMapCenter(prevCenter => {
          if (prevCenter && prevCenter.lat === centerLatitude && prevCenter.lng === centerLongitude) {
            return prevCenter;
          }
          return { lat: centerLatitude, lng: centerLongitude };
        });
        setZoom(zoom);

        if (zoom < CLEAR_SPOT_ZOOM_BOUNDARY) {
          clearSafetySpots();
        } else {
          debouncedFetchSafetySpots(centerLatitude, centerLongitude, latitudeDelta, longitudeDelta);
        }
      }
    }
  }, [map, debouncedFetchSafetySpots]);

  const clearSafetySpots = () => {
    setSafetySpots([]);
  };

  const onMarkerPress = (item: SafetySpotDto) => {
    if (item.id !== selectedMeetupPoint?.details?.id) {
      const newSelectedSafetySpot = {
        id: item.id,
        name: item.name,
        city: item.city,
        state: item.state,
        zip: item.zip,
        streetAddress: item.streetAddress,
        country: 'US',
        lat: item.lat,
        lon: item.lon,
        customMeetupPoint: false,
      };
      setSelectedMeetupPoint({ details: newSelectedSafetySpot });
    }
  };

  const renderSafetySpotMarker = (safetySpot: SafetySpotDto, clusterer?: any) => {
    const isSelected = safetySpot.id === selectedMeetupPoint?.details?.id;
    const icon = isSelected ? safetySpotIconRed : safetySpotIconBlack;

    return (
      <MessageClusteredMarker
        key={safetySpot.id}
        position={{ lat: safetySpot.lat, lng: safetySpot.lon }}
        icon={icon}
        onClick={() => onMarkerPress(safetySpot)}
        clusterer={clusterer}
      />
    );
  };

  const renderSafetySpotsWithoutClustering = () => {
    return safetySpots.map(safetySpot => renderSafetySpotMarker(safetySpot));
  };

  const renderSafetySpotsWithClustering = () => {
    return (
      <MarkerClustererF gridSize={250} styles={clusterStyles}>
        {clusterer => <>{safetySpots.map(safetySpot => renderSafetySpotMarker(safetySpot, clusterer))} </>}
      </MarkerClustererF>
    );
  };

  const renderSafetySpot = () => {
    if (!zoom || zoom < MIN_CLUSTERING_ZOOM || zoom > MAX_CLUSTERING_ZOOM) {
      return renderSafetySpotsWithoutClustering();
    } else {
      return renderSafetySpotsWithClustering();
    }
  };

  const renderBuyerLocation = () => {
    return <MessageClusteredMarker position={buyerLocation!!} icon={userMarker} label={buyerInitials} />;
  };

  const renderSellerLocation = () => {
    return <MessageClusteredMarker position={sellerLocation!!} icon={userMarker} label={sellerInitials} />;
  };

  const onSendClick = () => {
    if (selectedMeetupPoint?.details) {
      orderService
        .proposeMeetupPoint(orderId, selectedMeetupPoint?.details)
        .then(onClose)
        .catch(e => handleMessengerActionError(e));
    }
  };

  if (!isLoaded) return null;

  return (
    <Container>
      <GoogleMap
        data-testid={'message-location-map'}
        mapContainerStyle={mapContainerStyle}
        center={mapCenter}
        zoom={MIN_ZOOM}
        options={{
          disableDefaultUI: true,
          mapId: googleMapId,
        }}
        onLoad={onMapLoad}
        onIdle={onIdle}>
        {renderSafetySpot()}
        {renderBuyerLocation()}
        {renderSellerLocation()}
      </GoogleMap>
      <CloseButton onClick={onClose} src={closeIcon} alt={'close button'} />
      {selectedMeetupPoint?.details && (
        <MessageLocationSelectPanel onSendClick={onSendClick} meetupPoint={selectedMeetupPoint.details!!} />
      )}
    </Container>
  );
};

export default MessageLocationMap;

const clusterStyles = [
  {
    textColor: 'white',
    url: userMarker,
    height: 30,
    width: 30,
  },
];
