import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import arrowDownIcon from 'assets/svg/arrow-down.svg';
import SearchDropdown from 'components/filters/SearchDropdown';
import { SearchType } from 'services/searchTypes';

const Spacer = styled.div`
  height: 30px;
  border-left: 1px solid ${theme.color.borderGray};
`;

const ArrowIcon = styled.img`
  position: absolute;
  right: 10px;
  padding: 10px;
  pointer-events: none;
`;

interface Option {
  value: SearchType;
  label: string;
}

interface Props {
  searchType: SearchType;
  onTypeChange: (searchType: SearchType) => void;
  onMenuOpen: () => void;
}

export const SearchTypeDropdown = ({ searchType, onTypeChange, onMenuOpen }: Props) => {
  const { t } = useTranslation();

  const options: Option[] = [
    { value: 'Items', label: t('navbar.hot-deals') },
    { value: 'Campaigns', label: t('navbar.campaigns') },
  ];

  return (
    <>
      <Spacer />
      <SearchDropdown
        testId={'search-type-dropdown'}
        value={searchType}
        options={options}
        onChange={onTypeChange}
        onMenuOpen={onMenuOpen}
      />
      <ArrowIcon data-testid={'search-type-dropdown-arrow'} src={arrowDownIcon} alt='arrow-icon' />
    </>
  );
};
