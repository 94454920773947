import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import ArrowBackSource from 'assets/svg/arrow-back.svg';
import { H6 } from 'components/typography/Headers';
import { ItemDto } from 'services/item/itemService.dto';

const Container = styled(Link)`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  align-items: center;
  margin-bottom: 24px;

  ${theme.mq.phone} {
    margin-top: 12px;
  }
`;

const ArrowBackIcon = styled.img`
  width: 18px;
  height: 17px;
  margin-right: 10px;
`;

interface Props {
  item: ItemDto;
}

const ItemHeader = ({ item }: Props) => {
  const { t } = useTranslation('checkout');

  return (
    <Container data-testid={'item-header'} to={`/items/${item.id}`}>
      <ArrowBackIcon src={ArrowBackSource} />
      <H6>{t('item.return-to-item')}</H6>
    </Container>
  );
};

export default ItemHeader;
