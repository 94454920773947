import { AuctionDto, BidDto } from 'services/item/itemService.dto';
import { PublicUserDto } from 'services/user/userService.dto';

import { findWinningBid, isWinnerDetermined } from './auctionUtils';
import { BidderDto, mergeUserData } from './bidderType';

interface Bids {
  hasMore: boolean;
  bids: BidDto[];
}

export const convertToBidders = (bids: BidDto[], users?: PublicUserDto[], auction?: AuctionDto): BidderDto[] => {
  if (!auction) return [];
  const winningBid = isWinnerDetermined(auction) ? findWinningBid(auction) : null;

  const bidderData = mergeUserData(bids, users);
  return bidderData
    .map(({ bid, ...other }) => ({
      ...other,
      bid,
      resigned: !!winningBid && bid.created > winningBid.created,
      winner: !!winningBid && bid.id === winningBid.id,
    }))
    .sort(
      (bidderData, bidderDataToCompare) =>
        new Date(bidderDataToCompare.bid.created).getTime() - new Date(bidderData.bid.created).getTime()
    );
};

export const findBids = (auction: AuctionDto, sliceSize?: number): Bids => {
  if (!auction) return { bids: [], hasMore: false };

  if (isWinnerDetermined(auction)) {
    const winningBid = findWinningBid(auction);
    if (!!winningBid) {
      return { bids: [winningBid], hasMore: auction.bids.length > 1 };
    } else {
      return { bids: [], hasMore: auction.bids.length > 1 };
    }
  } else {
    const bids = sortBids(auction.bids).slice(0, sliceSize);
    return { bids: bids, hasMore: !!sliceSize && auction.bids.length > sliceSize };
  }
};

const sortBids = (bids: BidDto[]) => {
  return bids.sort(
    (bidData, bidDataToCompare) => new Date(bidDataToCompare.created).getTime() - new Date(bidData.created).getTime()
  );
};
