import { useNavigate } from 'react-router-dom';

import CampaignTile from 'components/campaign/campaign-tile/CampaignTile';
import ItemTile from 'components/item/item-tile/ItemTile';
import { TileSlider } from 'components/tile-slider/TileSlider';
import { CampaignDto } from 'services/campaign/campaignService.dto';
import { ItemWithAuctionDto } from 'services/item/withAuctionsData';

import { BreakPointConfigType } from './featuredConfig';
import { FeaturedVariant, TileItem } from './featuredTypes';

interface Props {
  header: string;
  items: TileItem[];
  variant: FeaturedVariant;
  breakPointConfig: BreakPointConfigType;
}

export const FeaturedTileSlider = ({ header, items, variant, breakPointConfig }: Props) => {
  const navigate = useNavigate();

  const renderItem = (item: TileItem) => {
    if (variant === 'CAMPAIGN') {
      return <CampaignTile campaign={item as CampaignDto} onClick={() => navigate(`/campaigns/${item.id}`)} />;
    }
    return <ItemTile item={item as ItemWithAuctionDto} />;
  };

  return (
    <TileSlider
      header={header}
      items={items}
      renderItem={renderItem}
      breakPointConfig={breakPointConfig}
      testIdPrefix={'featured'}
    />
  );
};
