import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { AxiosError } from 'axios';

import { usePaymentError } from 'features/my-profile/payment/hooks/usePaymentError';
import { usePaymentNotification } from 'features/my-profile/payment/hooks/usePaymentNotification';
import { useGlobalError } from 'hooks/useGlobalError';
import { paymentService } from 'services/payment/paymentService';
import {
  PaymentMethodDto,
  PaymentMethodRequestDto,
  PaymentMethodUpdateRequestDto,
} from 'services/payment/paymentService.dto';

type DisplayMode = 'NEW' | 'LIST';

interface PaymentContextType {
  paymentMethods: PaymentMethodDto[];
  defaultPaymentMethod?: PaymentMethodDto;
  isLoading: boolean;
  isSaving: boolean;
  addPaymentMethod: (request: PaymentMethodRequestDto) => void;
  updatePaymentMethod: (id: number, request: PaymentMethodUpdateRequestDto) => void;
  deletePaymentMethod: (id: number) => void;
  editedPaymentMethodId?: number;
  setEditedPaymentMethodId: (id?: number) => void;
  setIsInsertMode: (value: boolean) => void;
  displayMode: DisplayMode;
}

const PaymentContext = createContext<PaymentContextType>(null!);

const PaymentProvider = ({ children }: PropsWithChildren) => {
  const { unknownError } = useGlobalError();
  const { invalidRequest, limitExceeded } = usePaymentError();
  const { paymentMethodAdded, paymentMethodUpdated, paymentMethodDeleted } = usePaymentNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [editedPaymentMethodId, setEditedPaymentMethodId] = useState<number>();
  const [isInsertMode, setIsInsertMode] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodDto[]>([]);

  const defaultPaymentMethod = paymentMethods.find(paymentMethod => paymentMethod.isDefault);

  const displayMode: DisplayMode = isInsertMode ? 'NEW' : 'LIST';

  useEffect(() => {
    refreshPaymentMethods();
  }, []);

  const refreshPaymentMethods = () => {
    setIsLoading(true);
    setEditedPaymentMethodId(undefined);
    setIsInsertMode(false);

    paymentService
      .fetchAllPaymentMethods()
      .then(response => {
        setPaymentMethods(response.data);
        if (response.data.length === 0) setIsInsertMode(true);
      })
      .catch(error => handleError(error))
      .finally(() => setIsLoading(false));
  };

  const addPaymentMethod = (request: PaymentMethodRequestDto) => {
    setIsSaving(true);
    paymentService
      .addPaymentMethod(request)
      .then(paymentMethodAdded)
      .then(refreshPaymentMethods)
      .catch(error => handleError(error))
      .finally(() => setIsSaving(false));
  };

  const updatePaymentMethod = (id: number, request: PaymentMethodUpdateRequestDto) => {
    setIsSaving(true);
    paymentService
      .updatePaymentMethod(id, request)
      .then(paymentMethodUpdated)
      .then(refreshPaymentMethods)
      .catch(error => handleError(error))
      .finally(() => setIsSaving(false));
  };

  const deletePaymentMethod = (id: number) => {
    setIsSaving(true);
    paymentService
      .deletePaymentMethod(id)
      .then(paymentMethodDeleted)
      .then(refreshPaymentMethods)
      .catch(error => handleError(error))
      .finally(() => setIsSaving(false));
  };

  const handleError = (error: AxiosError) => {
    if (error.response?.status === 400) {
      invalidRequest();
    } else if (error.response?.status === 401) {
      return;
    } else if (error.response?.status === 409) {
      limitExceeded();
    } else {
      unknownError();
    }
  };

  return (
    <PaymentContext.Provider
      value={{
        paymentMethods,
        defaultPaymentMethod,
        isLoading,
        isSaving,
        addPaymentMethod,
        updatePaymentMethod,
        deletePaymentMethod,
        editedPaymentMethodId,
        setEditedPaymentMethodId,
        setIsInsertMode,
        displayMode,
      }}>
      {children}
    </PaymentContext.Provider>
  );
};

const usePayment = () => useContext(PaymentContext);

export { PaymentProvider, usePayment };
