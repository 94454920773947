import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { theme } from 'assets/styles/theme';
import FeaturedCampaignTile from 'components/campaign/campaign-tile/FeaturedCampaignTile';
import { useAutoSlide } from 'features/home/hooks/useAutoSlide';
import { useResponsive } from 'hooks/useResponsive';
import { CampaignDto } from 'services/campaign/campaignService.dto';

import 'swiper/css';
import 'swiper/css/pagination';

const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 450px;
  border-radius: 5px;

  & .swiper-slide {
    max-width: 800px;
    height: 400px;
    opacity: 0.5;
  }

  & .swiper-slide-active {
    opacity: 1;
  }

  & .swiper-pagination-bullets {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: 2px solid ${theme.color.white};
    opacity: 0.8;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  }

  & .swiper-pagination-bullet-active {
    width: 24px;
    height: 24px;
    background-color: ${theme.color.white};
    opacity: 1;
  }

  ${theme.mq.tablet} {
    height: 400px;
    & .swiper-slide {
      max-width: 700px;
      height: 350px;
    }
  }

  ${theme.mq.phone} {
    height: 500px;

    & .swiper-slide {
      max-width: 300px;
      height: 500px;
    }
  }
`;

interface Props {
  campaigns: CampaignDto[];
}
export const FeaturedCarousel = ({ campaigns }: Props) => {
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const { swiperRef, stopAutoSlide } = useAutoSlide(3000);

  const handleSlideClick = (campaign: CampaignDto) => {
    navigate(`/campaigns/${campaign.name}/${campaign.id}`);
  };

  return (
    <StyledSwiper
      ref={swiperRef}
      data-testid={'campaign-carousel'}
      slidesPerView={'auto'}
      centeredSlides={true}
      spaceBetween={12}
      initialSlide={0}
      loop={true}
      watchOverflow={true}
      pagination={{
        clickable: true,
      }}
      modules={!isMobile ? [Pagination] : []}
      style={{ overflow: 'visible' }}
      onTouchStart={stopAutoSlide}>
      {campaigns.map((campaign, index) => (
        <SwiperSlide key={index}>
          <FeaturedCampaignTile campaign={campaign} onClick={() => handleSlideClick(campaign)} />
        </SwiperSlide>
      ))}
    </StyledSwiper>
  );
};
