import { RowContainer } from 'components/containers/Containers';

import BackButton from './BackButton';
import { GiveoLogo } from './GiveoLogo';
import { Container, InnerContainer } from './headerContainers';
import { useHeaderVisibility } from './useHeaderVisibility';

interface Props {
  showBackButton: boolean;
  isOnlyLogo: boolean;
}

const LogoOnlyHeader = ({ showBackButton, isOnlyLogo }: Props) => {
  const isNavbarVisible = useHeaderVisibility();

  return (
    <Container $isVisible={isNavbarVisible} $isOnlyLogo={isOnlyLogo}>
      <InnerContainer>
        <RowContainer>
          <GiveoLogo />
          {showBackButton && <BackButton />}
        </RowContainer>
      </InnerContainer>
    </Container>
  );
};

export default LogoOnlyHeader;
