import { useTranslation } from 'react-i18next';

import FormStepWrapper from 'components/form/FormStepWrapper';
import NumericInputField from 'components/inputs/NumericInputField';
import { MAX_UNITS_QUANTITY, useAddItem } from 'features/add-item/context/AddItemProvider';

const SetMultipleItemQuantity = () => {
  const { t } = useTranslation('addItem');
  const { formData, errors, setFieldValue } = useAddItem();

  return (
    <FormStepWrapper
      header={t('set-multiple-items-quantity.header')}
      description={t('set-multiple-items-quantity.description')}
      isValid={!errors.unitsQuantity}>
      <NumericInputField
        value={formData.unitsQuantity}
        onChange={value => setFieldValue('unitsQuantity', value)}
        errorMessage={errors.unitsQuantity}
        maxValue={MAX_UNITS_QUANTITY}
        testId={'quantity-input'}
      />
    </FormStepWrapper>
  );
};

export default SetMultipleItemQuantity;
