import { useTranslation } from 'react-i18next';

import FormStepWrapper from 'components/form/FormStepWrapper';
import DateInputField from 'components/inputs/DateInputField';
import { useAddItem } from 'features/add-item/context/AddItemProvider';
import { currentDate } from 'utils/backendDateParser';

const SetAuctionEnd = () => {
  const { t } = useTranslation('addItem');
  const { formData, setFieldValue, errors } = useAddItem();

  return (
    <FormStepWrapper
      header={t('set-auction-end.header')}
      description={t('set-auction-end.description')}
      isValid={!errors.endDateTime}>
      <DateInputField
        value={formData.endDateTime}
        onChange={value => setFieldValue('endDateTime', value)}
        minDate={currentDate()}
        testId={'end-date-input'}
      />
    </FormStepWrapper>
  );
};

export default SetAuctionEnd;
