import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import RedButton from 'components/buttons/RedButton';
import PriceInputPopupField from 'components/inputs/PriceInputPopupField';
import { H4 } from 'components/typography/Headers';
import { Text1 } from 'components/typography/Texts';
import { formatMoneyInteger } from 'utils/numberFormatter';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled(Text1)`
  margin: 26px 0;
`;

interface Props {
  currentItemPrice?: number | null;
  minBidPrice: number;
  maxBidPrice?: number;
  onPlaceBid: (price: number) => void;
}

const PlaceBidPopup = ({ currentItemPrice, maxBidPrice, onPlaceBid, minBidPrice }: Props) => {
  const { t } = useTranslation('item');
  const [bid, setBid] = useState<number>();

  const handleBidChange = (newPrice: number) => setBid(newPrice);

  const inputPlaceholder =
    currentItemPrice != null
      ? t('place-bid-popup.input-placeholder', { bidAmount: formatMoneyInteger(currentItemPrice) })
      : '';

  const onPlaceBidClick = () => {
    bid && onPlaceBid(bid);
  };

  return (
    <Container data-testid={'place-bid-popup'}>
      <H4 data-testid={'place-bid-popup-header'}>{t('place-bid-popup.header')}</H4>
      <Description data-testid={'place-bid-popup-description'}>{t('popup-desc')}</Description>
      <PriceInputPopupField
        testId={'place-bid-popup-input'}
        value={bid}
        onChange={handleBidChange}
        placeholder={inputPlaceholder}
        maxValue={maxBidPrice}
      />
      <RedButton
        data-testid={'place-bid-popup-button'}
        onClick={onPlaceBidClick}
        disabled={bid!! < minBidPrice || !bid}>
        {t('place-bid-button')}
      </RedButton>
    </Container>
  );
};

export default PlaceBidPopup;
