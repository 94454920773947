import { useLoginPopup } from 'features/account';
import EnterOfferPopup from 'features/item/components/EnterOfferPopup';
import { usePopup } from 'hooks/usePopup';
import { useAuth } from 'providers/AuthProvider';
import { useGlobalData } from 'providers/GlobalDataProvider';
import { itemService } from 'services/item/itemService';

import { useEnterOfferNotifications } from './useEnterOfferNotifications';

export const useEnterOfferPopup = () => {
  const { showPopup, hidePopup } = usePopup();
  const { logged } = useAuth();
  const { showLoginPopup } = useLoginPopup();
  const { paymentConfig } = useGlobalData();
  const { offerRegistered, unknownError } = useEnterOfferNotifications();
  const maxPrice = paymentConfig?.maxItemPrice;

  const enterOffer = (itemId: number, amount: number, onOfferPlaced: () => void) => {
    itemService
      .registerOffer({ itemId, amount })
      .then(() => onOfferPlaced())
      .then(() => offerRegistered())
      .catch(() => unknownError());
  };

  const showEnterOfferPopup = (itemId: number, onOfferPlaced: () => void) => {
    if (logged) {
      showPopup(
        <EnterOfferPopup
          maxPrice={maxPrice}
          onEnterOffer={amount => {
            hidePopup();
            enterOffer(itemId, amount, onOfferPlaced);
          }}
        />
      );
    } else {
      showLoginPopup();
    }
  };

  return { showEnterOfferPopup };
};
