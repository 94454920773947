import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { LoadMoreButton } from 'components/buttons/LoadMoreButton';
import ItemTileList from 'components/item/item-list/ItemTileList';
import { useSupportingItems } from 'features/campaign/hooks/useSupportingItems';

const Container = styled.div`
  width: 100%;
  max-width: ${theme.maxWidthContainer}px;
  padding: 0 24px;
  margin: 0 auto;

  ${theme.mq.phone} {
    padding: 0 12px;
  }
`;

interface Props {
  campaignId: number;
  campaignName: string;
}

const SupportingItems = ({ campaignId, campaignName }: Props) => {
  const { t } = useTranslation('campaign');
  const { state, fetchNextPage } = useSupportingItems(campaignId);
  const { items, hasNextPage, loading } = state;

  if (items.length === 0) return null;

  return (
    <Container>
      <ItemTileList
        data-testid={'campaign-supporting-items'}
        header={t('buy-items-supporting', { campaignName: campaignName || '' })}
        items={items}
      />
      <LoadMoreButton hasNextPage={hasNextPage} loading={loading} onClick={fetchNextPage} />
    </Container>
  );
};

export default SupportingItems;
