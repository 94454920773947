import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import { useGeocoding } from 'services/shipment/useGeocoding';

import LocationMarker from './LocationMarker';

const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_KEY as string;
const googleMapId = process.env.REACT_APP_GOOGLE_MAP_ID as string;

const mapContainerStyle = {
  width: '100%',
  aspectRatio: '2.6/1',
};

interface Props {
  zipCode: string;
}

const LocationMap = ({ zipCode }: Props) => {
  const { isLoaded } = useJsApiLoader({ googleMapsApiKey: googleMapKey, mapIds: [googleMapId] });
  const geocodedLocation = useGeocoding(zipCode);

  if (!isLoaded || !geocodedLocation) return null;

  const { latitude, longitude } = geocodedLocation.coordinates;
  const coords = { lat: latitude, lng: longitude };

  return (
    <GoogleMap
      data-testid={'location-map'}
      mapContainerStyle={mapContainerStyle}
      center={coords}
      zoom={10}
      options={{ mapId: googleMapId }}>
      <LocationMarker position={coords} />
    </GoogleMap>
  );
};

export default LocationMap;
