import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { CreateButton } from 'components/buttons/CreateButton';
import { LoadMoreButton } from 'components/buttons/LoadMoreButton';
import { H4 } from 'components/typography/Headers';
import { useItemPopup } from 'features/add-item/form/useItemPopup';
import { useGridLayout } from 'hooks/useGridLayout';
import { useResponsive } from 'hooks/useResponsive';
import { useModal } from 'providers/ModalProvider';
import useMyItems, { ItemFilter } from 'services/useMyItems';

import { FilterButtons } from './components/FilterButtons';
import { MyActivityItemTile } from './components/MyActivityItemTile';
import { NoContent } from './components/NoContent';
import { ReturnButton } from './components/ReturnButton';
import { getNoContentConfig } from './utils/noContentConfig';
import { useMyActivityNavigate } from './utils/useMyActivityNavigate';
import { useMyItemNavigate } from './utils/useMyItemNavigate';

const Container = styled.div`
  display: flex;
  gap: 24px;

  ${theme.mq.phone} {
    width: 100%;
    justify-content: space-between;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: 48px;
  align-items: center;
  margin-bottom: 24px;

  ${theme.mq.phone} {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    margin-bottom: 12px;
  }
`;

const TileContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 24px;

  ${theme.mq.tablet} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 12px;
  }

  ${theme.mq.phone} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

interface Props {
  selectedFilter: ItemFilter;
}

export const MyActivityHotDealsPage = ({ selectedFilter }: Props) => {
  const { t } = useTranslation('myProfile');
  const { showGetApp } = useModal();
  const { showAddItemPopup } = useItemPopup();
  const onAddClick = () => showAddItemPopup();
  const onBuyClick = () => showGetApp({ appAreaType: 'MARKETPLACE' });
  const { fetchNextPage, state } = useMyItems({ filter: selectedFilter });
  const { goToHotDeals } = useMyActivityNavigate();
  const { goToMyItem } = useMyItemNavigate();
  const { isMobile } = useResponsive();
  const gridRef = useRef<HTMLDivElement>(null);
  const visibleItems = useGridLayout(state.items, gridRef, state.hasNextPage);

  const renderNoContent = () => {
    const noContentConfig = getNoContentConfig(onAddClick, onBuyClick);
    const { text, buttonText, onClick } = noContentConfig[selectedFilter];
    return <NoContent text={text} buttonText={buttonText} onClick={onClick} />;
  };

  const resolveButtonText = () => (isMobile ? t('my-activity.add') : t('my-activity.add-item'));

  const renderItems = () => (
    <TileContainer ref={gridRef}>
      {visibleItems.map(item => (
        <MyActivityItemTile
          key={`${item.id}_${item.orderId}`}
          item={item}
          onClick={(itemId, orderId) => goToMyItem(selectedFilter, itemId, orderId)}
        />
      ))}
    </TileContainer>
  );

  const renderContent = () => {
    if (state.loading) return null;
    if (state.items && state.items.length > 0) return renderItems();
    return renderNoContent();
  };

  return (
    <div data-testid={'my-profile-my-activity-hot-deals'}>
      <ReturnButton />
      <HeaderContainer>
        <Container>
          <H4>{t('my-activity.hot-deals')}</H4>
          <CreateButton onClick={onAddClick}>{resolveButtonText()}</CreateButton>
        </Container>
        <FilterButtons selectedFilter={selectedFilter} handleFilterChange={goToHotDeals} />
      </HeaderContainer>
      {renderContent()}
      <LoadMoreButton hasNextPage={state.hasNextPage} loading={state.loading} onClick={fetchNextPage} />
    </div>
  );
};
