import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { H5 } from 'components/typography/Headers';
import { useHotDealsFilters } from 'providers/HotDealsFiltersProviders';
import { ItemCategoryDto } from 'services/item/itemService.dto';

const TileContainer = styled.div<{ imageUrl: string }>`
  position: relative;
  border: 1px solid ${theme.color.lightGray};
  border-radius: 5px;
  background: ${theme.color.white};
  cursor: pointer;

  aspect-ratio: 1 / 1.4;
  background-image: url(${props => props.imageUrl});
  background-size: cover;
  background-position: center;

  ${theme.mq.phone} {
    aspect-ratio: 1 / 1.6;
  }
`;

const CategoryText = styled(H5)`
  padding: 12px;
  color: ${theme.color.white};
`;

interface Props {
  category: ItemCategoryDto;
}

export const CategoryTile = ({ category }: Props) => {
  const { updateFilters } = useHotDealsFilters();

  return (
    <TileContainer
      imageUrl={category.imageUrl}
      data-testid={'categories-tile_' + category.code}
      onClick={() => updateFilters({ categoryCode: category.code })}>
      <CategoryText>{category.displayName}</CategoryText>
    </TileContainer>
  );
};
