import { useEffect, useState } from 'react';

import { SharePanel } from 'components/share/SharePanel';
import { campaignService } from 'services/campaign/campaignService';
import { CampaignDto } from 'services/campaign/campaignService.dto';
import { itemService } from 'services/item/itemService';
import { ItemDto } from 'services/item/itemService.dto';
import {
  CAMPAIGN_SHARE_TYPES,
  DEFAULT_SHARE_TYPE,
  generateDeepLink,
  ITEM_SHARE_TYPES,
  ShareType,
  ShareTypeAppArea,
} from 'services/utils/shareUtils';

export interface ShareProps {
  shareType?: ShareType;
  objectId?: number;
  item?: ItemDto;
  campaign?: CampaignDto;
}

const Share = ({ shareType = DEFAULT_SHARE_TYPE, objectId, item, campaign }: ShareProps) => {
  const [url, setUrl] = useState<string>();
  const appArea = ShareTypeAppArea[shareType];
  const [campaignId, setCampaignId] = useState<number | undefined>();
  const [itemId, setItemId] = useState<number | undefined>();
  const [campaignTitle, setCampaignTitle] = useState<string | undefined>();
  const [itemTitle, setItemTitle] = useState<string | undefined>();

  useEffect(() => {
    if (!shareType) return;
    if (ITEM_SHARE_TYPES.includes(shareType)) {
      setItemId(objectId);
    } else if (CAMPAIGN_SHARE_TYPES.includes(shareType)) {
      setCampaignId(objectId);
    }
  }, [shareType, objectId]);

  useEffect(() => {
    if (!!campaign) {
      setCampaignTitle(campaign.name);
    } else if (!!campaignId) {
      campaignService.fetchCampaignDetails(campaignId).then(response => setCampaignTitle(response.data.name));
    }
  }, [campaignId, campaign]);

  useEffect(() => {
    if (!!item) {
      setCampaignId(item.supportedCampaignId);
      setItemTitle(item.title);
    } else if (!!itemId) {
      itemService.fetchItemDetails(itemId).then(response => {
        setCampaignId(response.data.supportedCampaignId);
        setItemTitle(response.data.title);
      });
    }
  }, [itemId, item]);

  useEffect(() => {
    if (!!appArea) {
      generateDeepLink({ context: appArea, objectId }).then(deepLinkUrl => setUrl(deepLinkUrl));
    }
  }, [appArea, objectId]);

  return <SharePanel shareType={shareType} url={url} campaignTitle={campaignTitle} itemTitle={itemTitle} />;
};

export default Share;
