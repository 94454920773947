import { useTranslation } from 'react-i18next';

import SortingDropdown from 'components/filters/SortingDropdown';
import { CampaignSortType } from 'services/sortType';

interface Option {
  value: CampaignSortType;
  label: string;
}

interface Props {
  value: CampaignSortType;
  testId?: string;
  onChange: (sortType: CampaignSortType) => void;
}

export const CampaignSortingDropdown = ({ value, testId, onChange }: Props) => {
  const { t } = useTranslation('campaign');

  const options: Option[] = [
    { value: 'RELEVANT', label: t('option.relevant') },
    { value: 'RECENT', label: t('option.recent') },
    { value: 'NEAREST', label: t('option.nearest') },
    { value: 'BEST_MATCH', label: t('option.best-match') },
  ];

  return <SortingDropdown testId={testId} value={value} options={options} onChange={onChange} />;
};
