import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import galleryIcon from 'assets/svg/gallery-icon.svg';
import galleryIconGray from 'assets/svg/gallery-icon-gray.svg';
import sendIcon from 'assets/svg/send-icon.svg';
import sendIconGray from 'assets/svg/send-icon-gray.svg';
import { useMessengerAlert } from 'features/messenger/hooks/useMessengerAlert';
import { useConversations } from 'providers/ConversationProvider';
import { generateTmpMessageId } from 'providers/utils/conversationProviderUtils';
import { ConversationDto } from 'services/message/messageService.dto';
import { fileToUrl } from 'utils/fileUtils';

const MSG_CHARACTERS_LIMIT = 3000;

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  align-self: flex-end;
  width: 100%;
  gap: 24px;
  padding: 0 60px 24px 60px;

  ${theme.mq.desktop} {
    padding: 24px;
  }

  ${theme.mq.phone} {
    padding: 12px;
  }
`;

const TextArea = styled(TextareaAutosize)`
  background-color: ${theme.color.lighterGray};
  width: 100%;
  outline: 0;
  white-space: pre-wrap;
  border-radius: 22px;
  padding: 12px 10px;
  border-color: ${theme.color.lighterGray};
`;

const FileLabel = styled.label`
  cursor: pointer;
  margin: auto 0;
`;

const FileInput = styled.input`
  display: none;
`;

const SendButton = styled.img`
  margin: auto 0;
  cursor: pointer;
`;

interface Props {
  conversation: ConversationDto;
}

export const MessageInput = ({ conversation }: Props) => {
  const { t } = useTranslation('message');
  const { addTextMessage, addPhotoMessage } = useConversations();
  const { handleMessengerAlert } = useMessengerAlert();
  const [message, setMessage] = useState('');
  const [messageToSend, setMessageToSend] = useState('');

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const isMessage = message !== '';

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSendMsg();
    }
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value.slice(0, MSG_CHARACTERS_LIMIT).trimStart();
    setMessage(newValue);
  };

  const handleSendImg = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file: File = e.target.files[0];
      if (
        !!process.env.REACT_APP_MAX_PHOTO_SIZE_IN_BYTES &&
        file.size > Number(process.env.REACT_APP_MAX_PHOTO_SIZE_IN_BYTES)
      ) {
        handleMessengerAlert(t('photos-size.title'), { label: t('ok') }, undefined, t('photos-size.description'));
      } else {
        const localUrl = await fileToUrl(file);
        const tmpMessageId = generateTmpMessageId();
        addPhotoMessage({ tmpMessageId, content: localUrl, conversationId: conversation.conversationId });
      }
    }
  };

  useEffect(() => {
    if (!!messageToSend) {
      const tmpMessageId = generateTmpMessageId();
      addTextMessage({ tmpMessageId, content: message.trim(), conversationId: conversation.conversationId });
      setMessage('');
    }
  }, [messageToSend]);

  useEffect(() => {
    if (!isMessage) {
      setMessageToSend('');
    }
  }, [isMessage]);

  const handleSendMsg = () => {
    if (!isMessage) {
      return;
    }
    const emailRegexp = /(([a-zA-Z0-9._-]+)(@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+))/gim;
    const phoneRegexp = /(\(\d{3}\))?[\s-]?\d{3}[\s-]?\d{4}/gim;

    if (message.match(emailRegexp) || message.match(phoneRegexp)) {
      const firstButton = {
        label: t(`notifications:alerts.SAFETY_FIRST.buttons.firstButton`),
      };
      const secondButton = {
        label: t(`notifications:alerts.SAFETY_FIRST.buttons.secondButton`),
        onClick: () => setMessageToSend(message),
      };

      handleMessengerAlert(
        t(`notifications:alerts.SAFETY_FIRST.title`),
        firstButton,
        secondButton,
        t(`notifications:alerts.SAFETY_FIRST.body`)
      );
    } else {
      setMessageToSend(message);
    }
  };

  return (
    <Container>
      <FileLabel htmlFor={'fileInput'}>
        <img src={conversation.archived ? galleryIconGray : galleryIcon} alt={'gallery icon'} />
      </FileLabel>
      <FileInput
        type='file'
        id='fileInput'
        accept='image/png, image/jpeg'
        onChange={handleSendImg}
        onClick={event => ((event.target as HTMLInputElement).value = '')}
        disabled={conversation.archived}
      />
      <TextArea
        ref={inputRef}
        minRows={1}
        maxRows={6}
        onKeyPress={handleKeyPress}
        placeholder={t('messenger.write-here')}
        value={message}
        onChange={handleTextChange}
        disabled={conversation.archived}
      />
      <SendButton src={isMessage ? sendIcon : sendIconGray} alt={'send button'} onClick={handleSendMsg} />
    </Container>
  );
};
