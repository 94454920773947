import { useTranslation } from 'react-i18next';

import { usePopup } from 'hooks/usePopup';
import { useNotification } from 'providers/NotificationProvider';
import { PurchaseDto } from 'services/order/orderService.dto';

import { ReturnAndRefund } from './ReturnAndRefund';

export const useReturnAndRefundPopup = () => {
  const { t } = useTranslation();
  const { showPopup, hidePopup } = usePopup();
  const { showNotification } = useNotification();

  const refundRequested = () => {
    showNotification({
      title: t('notifications:success-action'),
      type: 'success',
    });
  };
  const invalidRequest = () => {
    showNotification({
      title: t('notifications:invalid-action'),
      type: 'error',
    });
  };

  const showReturnAndRefundPopup = (order: PurchaseDto) => {
    showPopup(
      <ReturnAndRefund
        order={order}
        hidePopup={hidePopup}
        onInvalidRequest={invalidRequest}
        onRefundRequested={refundRequested}
      />
    );
  };

  return { showReturnAndRefundPopup };
};
