import { useEffect, useState } from 'react';

import { MyActivityBoughtSoldStatsDto } from 'services/item/itemService.dto';

import { orderService } from './orderService';

const useBoughtSoldStats = () => {
  const [myActivityBoughtSoldStats, setMyActivityBoughtSoldStats] = useState<MyActivityBoughtSoldStatsDto>();

  const refreshBoughtSoldStats = async () => {
    const statsResponse = await orderService.fetchMyItemsStats();
    setMyActivityBoughtSoldStats(statsResponse.data);
  };

  useEffect(() => {
    refreshBoughtSoldStats();
  }, []);

  return { myActivityBoughtSoldStats, refreshBoughtSoldStats };
};

export default useBoughtSoldStats;
