import Select, { StylesConfig } from 'react-select';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

import BaseInputField, {
  BaseInputProps,
  containerStyle,
  fontStyle,
  inputStyle,
  placeholderStyle,
} from './BaseInputField';

const StyledSelect = styled(Select)<{ $error: boolean; $success: boolean }>`
  ${containerStyle};
  ${fontStyle};
  padding: 0;
  text-align: start;

  & .dropdown-field__control {
    ${inputStyle};
  }

  &::placeholder {
    ${placeholderStyle}
  }
`;

interface Option {
  value: string;
  label: string;
}

interface Props extends BaseInputProps {
  options: Option[];
}

const styles: StylesConfig = {
  menuPortal: base => ({ ...base, zIndex: theme.zIndex.foremost }),
};

const DropdownField = (props: Props) => {
  const { value, options, placeholder, errorMessage, successMessage, onChange } = props;
  const { className, testId, disabled } = props;

  const handleChange = (newValue?: any) => onChange(newValue ? newValue.value : '');

  return (
    <BaseInputField testId={testId} className={className} successMessage={successMessage} errorMessage={errorMessage}>
      <StyledSelect
        className='dropdown-field'
        classNamePrefix='dropdown-field'
        menuPosition={'fixed'}
        menuPortalTarget={document.body}
        value={options.find(option => option.value === value)}
        placeholder={placeholder}
        options={options}
        isSearchable={true}
        isMulti={false}
        onChange={handleChange}
        $error={!!errorMessage}
        $success={!!successMessage}
        isDisabled={disabled}
        styles={styles}
      />
    </BaseInputField>
  );
};

export default DropdownField;
