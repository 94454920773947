import { useTranslation } from 'react-i18next';
import { useGoogleLogin } from '@react-oauth/google';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { makeTransparentColor } from 'assets/styles/transparentColor';
import ButtonBase from 'components/buttons/ButtonBase';
import { useGlobalError } from 'hooks/useGlobalError';

import { GoogleIcon } from './socialMediaIcons';

const StyledButton = styled(ButtonBase)`
  border: 1px solid ${theme.color.socialMediaGoogle};
  background-color: ${theme.color.white};
  color: ${theme.color.lightBlack};

  &:hover {
    box-shadow: 0 0 10px ${makeTransparentColor(theme.color.socialMediaGoogle, 0.7)};
  }
`;

interface Props {
  onAccessToken: (accessToken: string) => void;
}

const GoogleLoginButton = ({ onAccessToken }: Props) => {
  const { t } = useTranslation('account');
  const { unknownError } = useGlobalError();

  const handleLogin = useGoogleLogin({
    onSuccess: tokenResponse => onAccessToken(tokenResponse['access_token']),
    onError: unknownError,
    scope: 'email profile',
  });

  return (
    <StyledButton data-testid={'login-google-button'} onClick={() => handleLogin()}>
      <GoogleIcon />
      {t('login.google-button')}
    </StyledButton>
  );
};

export default GoogleLoginButton;
