import { Reducer, useReducer } from 'react';
import { uniqBy } from 'lodash';

import {
  InternalMessageDto,
  MessageDto,
  MessageInternalStatus,
  MessageType,
} from 'services/message/messageService.dto';
import PageableType from 'services/pageableType';
import { isReceived } from 'utils/messagingUtils';

enum MessageActionTypes {
  MESSAGES_REFRESH_IMAGES = 'MESSAGES_REFRESH_IMAGES',
  MESSAGES_ADD_TMP_MESSAGE = 'MESSAGES_ADD_TMP_MESSAGE',
  MESSAGES_ADD_MESSAGE_SUCCESS = 'MESSAGES_ADD_MESSAGE_SUCCESS',
  MESSAGES_ADD_MESSAGE_ERROR = 'MESSAGES_ADD_MESSAGE_ERROR',
  MESSAGES_FETCH_MESSAGES_REQUEST = 'MESSAGES_FETCH_MESSAGES_REQUEST',
  MESSAGES_FETCH_MESSAGES_SUCCESS = 'MESSAGES_FETCH_MESSAGES_SUCCESS',
  MESSAGES_FETCH_MESSAGES_ERROR = 'MESSAGES_FETCH_MESSAGES_ERROR',
  MESSAGES_CONFIRM_MESSAGES = 'MESSAGES_CONFIRM_MESSAGES',
}

type MessageAction =
  | { type: MessageActionTypes.MESSAGES_REFRESH_IMAGES; payload: { conversationId: number; messages: MessageDto[] } }
  | {
      type: MessageActionTypes.MESSAGES_ADD_TMP_MESSAGE;
      payload: { conversationId: number; message: InternalMessageDto };
    }
  | {
      type: MessageActionTypes.MESSAGES_ADD_MESSAGE_SUCCESS;
      payload: { conversationId: number; message: MessageDto; tmpMessageId?: number };
    }
  | { type: MessageActionTypes.MESSAGES_ADD_MESSAGE_ERROR; payload: { conversationId: number; tmpMessageId: number } }
  | { type: MessageActionTypes.MESSAGES_FETCH_MESSAGES_REQUEST; payload: { conversationId: number } }
  | {
      type: MessageActionTypes.MESSAGES_FETCH_MESSAGES_SUCCESS;
      payload: { conversationId: number; messages: PageableType<MessageDto> };
    }
  | { type: MessageActionTypes.MESSAGES_FETCH_MESSAGES_ERROR; payload: { conversationId: number } }
  | { type: MessageActionTypes.MESSAGES_CONFIRM_MESSAGES; payload: { messageIds: number[] } };

export interface ConversationMessages {
  conversationId: number;
  messageContent: InternalMessageDto[];
  isLoading: boolean;
  pageNumber: number;
  isLastPage: boolean;
}

interface MessagesState {
  messages: ConversationMessages[];
}

const messagesInitialState: MessagesState = {
  messages: [],
};

const messagesReducer = (state: MessagesState, action: MessageAction) => {
  switch (action.type) {
    case MessageActionTypes.MESSAGES_REFRESH_IMAGES: {
      const conversationId = action.payload.conversationId;
      const internalMessages = action.payload.messages.map(it => mapMessageToInternal(it));
      const messages: ConversationMessages[] = mapConversationMessages(state, conversationId, conversationMessages =>
        updateMessages(conversationMessages, internalMessages)
      );
      return { ...state, messages: messages };
    }
    case MessageActionTypes.MESSAGES_ADD_TMP_MESSAGE: {
      const conversationId = action.payload.message.conversationId;
      const internalMessage = action.payload.message;
      const messages: ConversationMessages[] = mapConversationMessages(state, conversationId, conversationMessages =>
        addMessage(conversationMessages, internalMessage)
      );
      return { ...state, messages: messages };
    }
    case MessageActionTypes.MESSAGES_ADD_MESSAGE_SUCCESS: {
      const conversationId = action.payload.message.conversationId;
      const internalMessage = mapMessageToInternal(action.payload.message);
      const messages: ConversationMessages[] = mapConversationMessages(state, conversationId, conversationMessages =>
        addMessage(conversationMessages, internalMessage, action.payload.tmpMessageId)
      );
      return { ...state, messages: messages };
    }
    case MessageActionTypes.MESSAGES_ADD_MESSAGE_ERROR: {
      const conversationId = action.payload.conversationId;
      const messages: ConversationMessages[] = mapConversationMessages(state, conversationId, conversationMessages =>
        updateMessageStatusError(conversationMessages, action.payload.tmpMessageId)
      );
      return { ...state, messages: messages };
    }
    case MessageActionTypes.MESSAGES_FETCH_MESSAGES_REQUEST: {
      const conversationId = action.payload.conversationId;
      const messages: ConversationMessages[] = mapConversationMessages(state, conversationId, conversationMessages =>
        updateMessagesLoading(conversationMessages, true)
      );
      return { ...state, messages: messages };
    }
    case MessageActionTypes.MESSAGES_FETCH_MESSAGES_SUCCESS: {
      const conversationId = action.payload.conversationId;
      const existingConversation = state.messages.find(it => it.conversationId === conversationId);
      const currentPageNumber = existingConversation?.pageNumber || 0;
      const messages: ConversationMessages[] = mapConversationMessages(state, conversationId, conversationMessages =>
        addFetchedMessages(conversationMessages, action.payload.messages, currentPageNumber)
      );
      return { ...state, messages: messages };
    }
    case MessageActionTypes.MESSAGES_FETCH_MESSAGES_ERROR: {
      const conversationId = action.payload.conversationId;
      const messages: ConversationMessages[] = mapConversationMessages(state, conversationId, conversationMessages =>
        updateMessagesLoading(conversationMessages, false)
      );
      return { ...state, messages: messages };
    }
    case MessageActionTypes.MESSAGES_CONFIRM_MESSAGES: {
      const messageIds = action.payload.messageIds;
      const messages = state.messages.map(conversationMessages => confirmMessages(conversationMessages, messageIds));
      return { ...state, messages: messages };
    }
    default:
      return state;
  }
};

const sortMessages = (messageContent: InternalMessageDto[]) => {
  return messageContent.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
};

const updateMessageStatus = (
  conversationMessages: ConversationMessages,
  messageId: number,
  internalStatus: MessageInternalStatus
) => ({
  ...conversationMessages,
  messageContent: conversationMessages.messageContent.map(message =>
    message.internalId === messageId
      ? {
          ...message,
          internalStatus: internalStatus,
        }
      : message
  ),
});
const updateMessageStatusError = (conversationMessages: ConversationMessages, tmpMessageId: number) => ({
  ...conversationMessages,
  messageContent: conversationMessages.messageContent.map(message =>
    message.internalId === tmpMessageId
      ? {
          ...message,
          internalStatus:
            message.type === MessageType.PHOTO ? MessageInternalStatus.UPLOAD_ERROR : MessageInternalStatus.ERROR,
        }
      : message
  ),
});

const updateMessages = (conversationMessages: ConversationMessages, newMessages: InternalMessageDto[]) => ({
  ...conversationMessages,
  messageContent: uniqBy([...newMessages, ...conversationMessages.messageContent], 'internalId'),
});

const confirmMessages = (conversationMessages: ConversationMessages, messageIds: number[]) => ({
  ...conversationMessages,
  messageContent: conversationMessages.messageContent.map(message => {
    if (messageIds.includes(message.internalId)) {
      return {
        ...message,
        confirmationReceived: true,
      };
    }
    return message;
  }),
});

const updateMessagesLoading = (conversationMessages: ConversationMessages, isLoading: boolean) => ({
  ...conversationMessages,
  isLoading: isLoading,
});

const addMessage = (
  conversationMessages: ConversationMessages,
  newMessage: InternalMessageDto,
  tmpMessageId?: number
) => {
  const message: InternalMessageDto | undefined = conversationMessages.messageContent.find(
    it => it.internalId === newMessage.internalId
  );
  if (!!message) {
    return updateMessageStatus(conversationMessages, newMessage.internalId, newMessage.internalStatus);
  }
  return {
    ...conversationMessages,
    messageContent: [...conversationMessages.messageContent.filter(it => it.internalId !== tmpMessageId), newMessage],
  };
};

const addFetchedMessages = (
  conversationMessages: ConversationMessages,
  fetchedMessages: PageableType<MessageDto>,
  currentPageNumber: number
) => ({
  ...conversationMessages,
  messageContent: sortMessages(
    uniqBy(
      [...conversationMessages.messageContent, ...(fetchedMessages.content?.map(it => mapMessageToInternal(it)) || [])],
      'internalId'
    )
  ),
  isLoading: false,
  pageNumber: fetchedMessages.last ? currentPageNumber : currentPageNumber + 1,
  isLastPage: fetchedMessages.last || false,
});

const mapConversationMessages = (
  state: MessagesState,
  conversationId: number,
  transform: (conversationMessages: ConversationMessages) => ConversationMessages
): ConversationMessages[] => {
  const existingConversationMessages = state.messages.find(msg => msg.conversationId === conversationId);

  if (existingConversationMessages) {
    return state.messages.map(conversationMessages =>
      conversationMessages.conversationId === conversationId ? transform(conversationMessages) : conversationMessages
    );
  } else {
    const newConversationMessages: ConversationMessages = {
      conversationId,
      messageContent: [],
      isLoading: false,
      isLastPage: false,
      pageNumber: 0,
    };
    return [...state.messages, transform(newConversationMessages)];
  }
};

const mapMessageToInternal = (message: MessageDto): InternalMessageDto => {
  return {
    ...message,
    confirmationReceived: isReceived(message),
    internalId: message.id!,
    internalStatus: MessageInternalStatus.SYNC,
  };
};

export const useMessagesReducer = () => {
  const [state, dispatch] = useReducer<Reducer<MessagesState, MessageAction>>(messagesReducer, messagesInitialState);
  return { state, dispatch };
};

export { MessageActionTypes, MessageAction, messagesInitialState, MessagesState };
