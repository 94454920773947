import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import RedButton from 'components/buttons/RedButton';
import { RedTextButton } from 'components/buttons/RedTextButton';
import FormStepWrapper from 'components/form/FormStepWrapper';
import { useAddItem } from 'features/add-item/context/AddItemProvider';
import { useCampaignPicker } from 'features/campaign/picker/useCampaignPicker';
import CampaignInfo from 'features/item/components/CampaignInfo';
import { CampaignDto } from 'services/campaign/campaignService.dto';

const SelectedCampaignWrapper = styled.div`
  margin-top: 20px;
`;

const SearchCampaignsButton = styled(RedButton)`
  height: 46px;
`;

const ChangeCampaignsButton = styled(RedTextButton)`
  width: 100%;
`;

const SelectCampaign = () => {
  const { t } = useTranslation('addItem');
  const { formData, errors, setFieldValue } = useAddItem();
  const { showCampaignPicker } = useCampaignPicker();

  const isCampaignSelected = !!formData.supportedCampaign;
  const onBtnClicked = () => showCampaignPicker(onCampaignSelected, true);
  const onCampaignSelected = (campaign: CampaignDto) => setFieldValue('supportedCampaign', campaign);

  return (
    <FormStepWrapper
      header={t('select-campaign.header')}
      description={!isCampaignSelected ? t('select-campaign.description') : ''}
      isValid={!errors.supportedCampaign}>
      {isCampaignSelected && (
        <SelectedCampaignWrapper>
          <CampaignInfo campaign={formData.supportedCampaign} />
          <ChangeCampaignsButton onClick={onBtnClicked}>{t('select-campaign.change-btn')}</ChangeCampaignsButton>
        </SelectedCampaignWrapper>
      )}

      {!isCampaignSelected && (
        <SearchCampaignsButton onClick={onBtnClicked}>{t('select-campaign.search-btn')}</SearchCampaignsButton>
      )}
    </FormStepWrapper>
  );
};

export default SelectCampaign;
