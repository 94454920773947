import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { LoadingContainer } from 'components/containers/Containers';
import { H4 } from 'components/typography/Headers';
import { LoadingIndicator } from 'components/ui-elements/LoadingIndicator';
import { useUsers } from 'providers/UserProvider';
import { ItemDto } from 'services/item/itemService.dto';
import { orderService } from 'services/order/orderService';
import { ORDER_INVALID_STATUSES, PurchaseDto } from 'services/order/orderService.dto';

import { ListOfSoldBuyersList } from './ListOfSoldBuyersList';

const Container = styled.div`
  width: 600px;

  ${theme.mq.phone} {
    width: 100%;
  }
`;

interface Props {
  item: ItemDto;
  onClick: () => void;
}

export const ListOfSold = ({ item, onClick }: Props) => {
  const { t } = useTranslation('myProfile');
  const [orders, setOrders] = useState<PurchaseDto[]>();
  const { users, fetchUsers } = useUsers();

  const isNationwideShippingAvailable = item.canNationwideShipping;
  const isLocalPickupAvailable = item.canLocalPickUpDelivery;

  const renderLoading = () => {
    return (
      <LoadingContainer>
        <LoadingIndicator />
      </LoadingContainer>
    );
  };
  const findValidOrders = (orders: PurchaseDto[]): PurchaseDto[] => {
    return orders.filter(order => !ORDER_INVALID_STATUSES.includes(order.status));
  };

  const allBuyersIds = (orders: PurchaseDto[]): number[] => {
    return [...new Set(orders.map(order => order.buyerId))];
  };

  useEffect(() => {
    orderService.fetchMySellings(item.id).then(response => setOrders(findValidOrders(response.data)));
  }, []);

  useEffect(() => {
    if (orders) {
      fetchUsers(allBuyersIds(orders));
    }
  }, [orders]);

  if (!users || !orders) return renderLoading();
  return (
    <Container>
      <H4>{t('tracked-item.multiple-list-header')}</H4>
      <ListOfSoldBuyersList
        onClick={onClick}
        users={users!!}
        orders={orders!!}
        isLocalPickupAvailable={isLocalPickupAvailable}
        isNationwideShippingAvailable={isNationwideShippingAvailable}
        unitsSold={item.unitsSold}
        unitsQuantity={item.unitsQuantity}
      />
    </Container>
  );
};
