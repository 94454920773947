import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { TextButton1 } from 'components/typography/TextButtons';
import { useUpdatePaymentForm } from 'features/my-profile/payment/hooks/usePaymentForm';
import { useAlertPopup } from 'hooks/useAlertPopup';
import { usePayment } from 'providers/PaymentProvider';
import { PaymentMethodDto } from 'services/payment/paymentService.dto';

import { PaymentForm } from './PaymentForm';
import { PaymentMethodDetails } from './PaymentMethodDetails';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.color.lighterGray};
  border-radius: 5px;
  padding: 20px;
  gap: 20px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const FooterContainer = styled(HeaderContainer)`
  justify-content: flex-start;
`;

const StyledTextButton = styled(TextButton1)`
  background-color: ${theme.color.lighterGray};
`;

interface Props {
  paymentMethod: PaymentMethodDto;
  isLast: boolean;
}

export const PaymentMethod = ({ paymentMethod, isLast }: Props) => {
  const { t } = useTranslation('myProfile');
  const { showAlertPopup } = useAlertPopup();
  const { isSaving, deletePaymentMethod, editedPaymentMethodId, setEditedPaymentMethodId } = usePayment();
  const isEditMode = editedPaymentMethodId === paymentMethod.id;
  const { values, errors, submitForm, setFieldValue, resetForm, isValid, dirty } = useUpdatePaymentForm(paymentMethod);

  const handleDelete = () => {
    showAlertPopup({
      header: t('payment.deletion-popup.header'),
      description: t('payment.deletion-popup.description'),
      firstButton: {
        label: t('payment.deletion-popup.confirm-btn'),
        onClick: () => deletePaymentMethod(paymentMethod.id),
      },
      secondButton: { label: t('payment.deletion-popup.cancel-btn') },
      testId: 'payment-methods-limit-alert',
    });
  };

  const handleCancel = () => {
    setEditedPaymentMethodId();
    resetForm();
  };

  return (
    <Container data-testid={'payment-method'}>
      <HeaderContainer>
        <PaymentMethodDetails paymentMethod={paymentMethod} />
        {!isEditMode && (
          <StyledTextButton data-testid={'edit-btn'} onClick={() => setEditedPaymentMethodId(paymentMethod.id)}>
            {t('common:edit')}
          </StyledTextButton>
        )}
        {isEditMode && !isSaving && (
          <>
            <StyledTextButton data-testid={'cancel-btn'} onClick={handleCancel}>
              {t('common:cancel')}
            </StyledTextButton>
            <StyledTextButton data-testid={'save-btn'} onClick={submitForm} disabled={!dirty || !isValid}>
              {t('common:save')}
            </StyledTextButton>
          </>
        )}
      </HeaderContainer>
      {isEditMode && <PaymentForm values={values} setFieldValue={setFieldValue} errors={errors} isEditMode={true} />}
      {isEditMode && !isSaving && (
        <FooterContainer>
          <StyledTextButton data-testid={'delete-btn'} onClick={handleDelete} disabled={isLast}>
            {t('payment.delete-card')}
          </StyledTextButton>
        </FooterContainer>
      )}
    </Container>
  );
};
