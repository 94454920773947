import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import WhiteButton from 'components/buttons/WhiteButton';
import { H2 } from 'components/typography/Headers';
import { useSearchParams } from 'hooks/useSearchParams';
import { notificationService } from 'services/notification/notificationService';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin: 24px auto;
  text-align: center;
  max-width: 800px;
  padding: 24px;
  gap: 24px;
`;

enum ApiStatus {
  INIT = 'INIT',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export const UnsubscribeEmail = () => {
  const [unsubscribeStatus, setUnsubscribeStatus] = useState<ApiStatus>(ApiStatus.INIT);
  const { userId, token } = useSearchParams();

  const handleUnsubscribeButton = () => {
    if (userId && token) {
      setUnsubscribeStatus(ApiStatus.IN_PROGRESS);
    } else {
      setUnsubscribeStatus(ApiStatus.ERROR);
    }
  };

  useEffect(() => {
    if (unsubscribeStatus === ApiStatus.IN_PROGRESS) {
      notificationService
        .unsubscribeEmail(Number(userId), token)
        .then(() => setUnsubscribeStatus(ApiStatus.SUCCESS))
        .catch(() => setUnsubscribeStatus(ApiStatus.ERROR));
    }
  }, [token, unsubscribeStatus, userId]);

  const { t } = useTranslation('user');

  const resolveTitle = () => {
    if (unsubscribeStatus === ApiStatus.INIT) {
      return t('unsubscribe.info');
    } else if (unsubscribeStatus === ApiStatus.SUCCESS) {
      return t('unsubscribe.success');
    } else if (unsubscribeStatus === ApiStatus.ERROR) {
      return t('unsubscribe.error');
    } else {
      return '';
    }
  };

  return (
    <Container>
      <H2>{resolveTitle()}</H2>
      {unsubscribeStatus === ApiStatus.INIT && (
        <WhiteButton onClick={handleUnsubscribeButton}>{t('unsubscribe.unsubscribe')}</WhiteButton>
      )}
    </Container>
  );
};
