import { formatMoneyInteger } from 'utils/numberFormatter';

import NumericInputField, { NumericInputProps } from './NumericInputField';

const PriceInputField = (props: NumericInputProps) => {
  const formatPrice = (value?: number) => (value ? formatMoneyInteger(value, true) : '');
  return <NumericInputField valueFormatter={formatPrice} {...props} />;
};

export default PriceInputField;
