import * as React from 'react';

import { ReactComponent as IconWeight1 } from 'assets/svg/weights/weight_1.svg';
import { ReactComponent as IconWeight3 } from 'assets/svg/weights/weight_3.svg';
import { ReactComponent as IconWeight05 } from 'assets/svg/weights/weight_05.svg';
import { ReactComponent as IconWeight10 } from 'assets/svg/weights/weight_10.svg';
import { ReactComponent as IconWeight20 } from 'assets/svg/weights/weight_20.svg';
import { ReactComponent as IconWeight30 } from 'assets/svg/weights/weight_30.svg';
import { ReactComponent as IconWeight50 } from 'assets/svg/weights/weight_50.svg';
import { ReactComponent as IconWeight70 } from 'assets/svg/weights/weight_70.svg';
import { ReactComponent as IconWeight100 } from 'assets/svg/weights/weight_100.svg';

export type WeightIcon = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

export interface ItemWeight {
  weightUpTo: number;
  label: string;
  Icon: WeightIcon;
}

export const itemWeights: ItemWeight[] = [
  {
    weightUpTo: 0.5,
    label: '0 - 0.5 lbs',
    Icon: IconWeight05,
  },
  {
    weightUpTo: 1,
    label: '0.5 - 1 lbs',
    Icon: IconWeight1,
  },
  {
    weightUpTo: 3,
    label: '1 - 3 lbs',
    Icon: IconWeight3,
  },
  {
    weightUpTo: 10,
    label: '3 - 10 lbs',
    Icon: IconWeight10,
  },
  {
    weightUpTo: 20,
    label: '10 - 20 lbs',
    Icon: IconWeight20,
  },
  {
    weightUpTo: 30,
    label: '20 - 30 lbs',
    Icon: IconWeight30,
  },
  {
    weightUpTo: 50,
    label: '30 - 50 lbs',
    Icon: IconWeight50,
  },
  {
    weightUpTo: 70,
    label: '50 - 70 lbs',
    Icon: IconWeight70,
  },
  {
    weightUpTo: 100,
    label: '70 - 100 lbs',
    Icon: IconWeight100,
  },
];
