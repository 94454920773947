import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import RedButton from 'components/buttons/RedButton';
import { H4 } from 'components/typography/Headers';
import { useGlobalData } from 'providers/GlobalDataProvider';
import { useModal } from 'providers/ModalProvider';
import { AppAreaType } from 'services/notification/notificationService.dto';
import { ReasonDto } from 'services/report/reportService.dto';

import { addReason, removeReasons } from './components/reasonsUtils';
import ReportReasonsList from './components/ReportReasonsList';

const Container = styled.div`
  width: 80%;
  padding: 20px;

  ${theme.mq.tablet} {
    width: 100%;
    padding: 0;
  }
`;

const TopSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Header = styled(H4)`
  font-weight: 700;
  margin-bottom: 22px;
`;

const Description = styled.p``;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
`;

const SendButton = styled(RedButton)`
  margin-top: 24px;
  width: 100%;
`;

const ReportReasonsListWrapper = styled.div`
  margin: 16px 0;
`;

interface Props {
  appArea: AppAreaType;
  objectId: number;
}

const ReportPopup = ({ appArea, objectId }: Props) => {
  const [selectedReasons, setSelectedReasons] = useState<ReasonDto[]>([]);

  const { reportReasons } = useGlobalData();
  const { t } = useTranslation();
  const { showGetApp } = useModal();

  const displayGetApp = () => {
    showGetApp({ appAreaType: appArea, objectId: objectId, description: t('report.header') });
  };

  return (
    <Container>
      <TopSectionWrapper>
        <Header>{t('report.header')}</Header>
        <Description>{t('report.description', { objectType: appArea.toLowerCase() })}</Description>
      </TopSectionWrapper>
      <ReportReasonsListWrapper>
        {reportReasons.length > 0 && (
          <ReportReasonsList
            reasons={reportReasons}
            selectedReasons={selectedReasons}
            onSelectReason={(reasonDto: ReasonDto) => setSelectedReasons(prevState => addReason(reasonDto, prevState))}
            onUnselectReason={(reasonDto: ReasonDto) =>
              setSelectedReasons(prevState => removeReasons(reasonDto, prevState))
            }
          />
        )}
      </ReportReasonsListWrapper>
      <ButtonWrapper>
        <SendButton onClick={displayGetApp}>{t('report.send')}</SendButton>
      </ButtonWrapper>
    </Container>
  );
};

export default ReportPopup;
