import { useTranslation } from 'react-i18next';

import { PURCHASE_ACTIONS } from 'assets/constants/purchaseActions';
import RedButton from 'components/buttons/RedButton';
import { useContact } from 'features/messenger/hooks/useContact';
import { orderService } from 'services/order/orderService';

import { TrackedItemComponentsProps } from './trackedItemUtils';

export const MeetUpButtons = ({ isBuyer, order, invalidRequest, onClick }: TrackedItemComponentsProps) => {
  const { t } = useTranslation('myProfile');
  const status = order?.status;
  const orderId = order?.id;
  const ownerId = order?.ownerId;
  const buyerId = order?.buyerId;
  const refundRequested = order?.refundRequested;

  const interlocutorId = isBuyer ? ownerId : buyerId;

  const { contactOwner } = useContact('ITEM', interlocutorId, order?.itemId);

  const canMeetUp = () => {
    return status && !refundRequested && PURCHASE_ACTIONS[status].isMeetUpPossible;
  };

  const meetUp = () => {
    onClick?.();
    return contactOwner(); // TODO: handle when the views are added
  };

  const confirmPickUp = () => {
    orderService
      .markAsPickedUp(orderId!!)
      .then(() => {
        window.location.reload();
      })
      .catch(invalidRequest);
  };

  if (!canMeetUp()) return null;
  return (
    <>
      <RedButton data-testid={'meet-up-btn'} onClick={() => meetUp()}>
        {t('tracked-item.meet-up')}
      </RedButton>
      <RedButton data-testid={'confirm-pick-up-btn'} onClick={() => confirmPickUp()}>
        {t('tracked-item.confirm-pickup')}
      </RedButton>
    </>
  );
};
