import { ChangeEvent, KeyboardEvent, useState } from 'react';
import styled from 'styled-components';

import SearchIconSource from 'assets/svg/search.svg';
import { containerStyle, focusStyle, fontStyle, inputStyle, placeholderStyle } from 'components/inputs/BaseInputField';

const Container = styled.div`
  position: relative;
`;

const Input = styled.input<{ $error: boolean; $success: boolean }>`
  ${containerStyle}
  ${fontStyle};
  ${inputStyle};

  padding-left: 36px;

  &:focus {
    ${focusStyle}
  }

  &::placeholder {
    ${placeholderStyle}
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  position: absolute;
  left: 0;
  width: 36px;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const SearchIcon = styled.img`
  width: 13px;
  height: 13px;
`;

interface Props {
  placeholder?: string;
  onSubmit: (value: string) => void;
  testId?: string;
}

const SearchField = ({ placeholder, onSubmit, testId }: Props) => {
  const [value, setValue] = useState('');

  const handleSubmit = () => onSubmit(value);

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <Container data-testid={testId}>
      <IconWrapper data-testid={'search-button'} onClick={handleSubmit}>
        <SearchIcon src={SearchIconSource} />
      </IconWrapper>
      <Input
        data-testid={'input'}
        placeholder={placeholder}
        onChange={handleTextChange}
        onKeyDown={handleKeyDown}
        $error={false}
        $success={false}
      />
    </Container>
  );
};

export default SearchField;
