import { useEffect, useRef, useState } from 'react';
import { SwiperRef } from 'swiper/react';

export const useAutoSlide = (interval = 3000) => {
  const swiperRef = useRef<SwiperRef>(null);
  const [isAutoSlideEnabled, setIsAutoSlideEnabled] = useState(true);

  const handleAutomaticSlideClick = () => {
    if (swiperRef.current?.swiper && isAutoSlideEnabled) {
      swiperRef.current.swiper.slideNext();
    }
  };

  useEffect(() => {
    if (!isAutoSlideEnabled) return;

    const intervalId = setInterval(handleAutomaticSlideClick, interval);
    return () => clearInterval(intervalId);
  }, [isAutoSlideEnabled, interval]);

  const stopAutoSlide = () => setIsAutoSlideEnabled(false);

  return { swiperRef, stopAutoSlide };
};
