import { useLoginPopup } from 'features/account';
import { extractItemPrice } from 'features/item/components/auction/auctionUtils';
import PlaceBidPopup from 'features/item/components/auction/PlaceBidPopup';
import { usePopup } from 'hooks/usePopup';
import { useAuth } from 'providers/AuthProvider';
import { useGlobalData } from 'providers/GlobalDataProvider';
import { itemService } from 'services/item/itemService';
import { AuctionDto } from 'services/item/itemService.dto';

import { usePlaceBidNotifications } from './usePlaceBidNotifications';

export const usePlaceBidPopup = () => {
  const { showPopup, hidePopup } = usePopup();
  const { logged } = useAuth();
  const { showLoginPopup } = useLoginPopup();
  const { paymentConfig } = useGlobalData();
  const { bidAdded, amountTooLow, auctionExpired, amountOverLimit, unknownError } = usePlaceBidNotifications();

  const maxPrice = paymentConfig?.maxItemPrice;

  const placeBid = (auctionId: number, price: number, onBidPlaced: () => void) => {
    itemService
      .addBid(auctionId, price)
      .then(() => onBidPlaced())
      .then(() => bidAdded())
      .catch(error => {
        if (error.response?.status === 400) {
          amountTooLow();
        } else if (error.response?.status === 409) {
          auctionExpired();
        } else if (error.response?.status === 422) {
          amountOverLimit();
        } else {
          unknownError();
        }
      });
  };

  const showPlaceBidPopup = (auction: AuctionDto, onBidPlaced: () => void) => {
    const { currentItemPrice, minBidPrice } = extractItemPrice(auction);

    if (logged) {
      showPopup(
        <PlaceBidPopup
          currentItemPrice={currentItemPrice}
          minBidPrice={minBidPrice ? minBidPrice : 0}
          maxBidPrice={maxPrice}
          onPlaceBid={price => {
            hidePopup();
            placeBid(auction.id, price, onBidPlaced);
          }}
        />
      );
    } else {
      showLoginPopup();
    }
  };

  return { showPlaceBidPopup };
};
