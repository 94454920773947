import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import CircleActive from 'assets/svg/circle-active.svg';
import CircleInactive from 'assets/svg/circle-inactive.svg';
import { Text1Bold } from 'components/typography/Texts';

const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 0;
  gap: 10px;
  flex-wrap: wrap;
`;

const Option = styled.div<{ selected: boolean }>`
  border: 1px solid ${theme.color.red};
  background-color: ${props => (props.selected ? theme.color.red : theme.color.white)};
  color: ${props => (props.selected ? theme.color.white : theme.color.red)};
  border-radius: 5px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: stretch;
  padding: 12px;
  flex: 1;
  cursor: pointer;
  gap: 10px;
  flex-basis: initial;
`;

export interface ChoiceOption {
  key: string;
  name: string;
  isActive: boolean;
}

interface Props {
  options: ChoiceOption[];
  onSelect: (key: string) => void;
}

const ChoicePicker = ({ options, onSelect }: Props) => {
  return (
    <Container data-testid={'choice-picker'}>
      {options.map(option => (
        <Option
          data-testid={'choice-picker-option'}
          key={option.key}
          onClick={() => onSelect(option.key)}
          selected={option.isActive}>
          <img src={option.isActive ? CircleActive : CircleInactive} alt={'circle-icon'} />
          <Text1Bold>{option.name}</Text1Bold>
        </Option>
      ))}
    </Container>
  );
};

export default ChoicePicker;
