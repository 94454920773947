import { useGlobalError } from 'hooks/useGlobalError';
import { paymentService } from 'services/payment/paymentService';

export const useSessionValidationHandler = () => {
  const { unknownError } = useGlobalError();

  const handleMerchantValidation = async (validationURL: string, paymentId: number) => {
    try {
      const response = await paymentService.validateMerchant(validationURL, paymentId);
      return response.data.merchantSession;
    } catch (error) {
      unknownError();
    }
  };

  return { handleMerchantValidation };
};
