import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { RedButtonWithArrow } from 'components/buttons/RedButtonWithArrow';
import { HomeItemTiles } from 'components/item/HomeItemsTiles';
import { H4 } from 'components/typography/Headers';
import { useHomeItems } from 'features/item/hooks/useItems';

const ITEMS_COUNT = 10;

const Container = styled.div`
  width: 100%;
  padding: 24px;

  ${theme.mq.tablet} {
    padding: 12px;
  }
`;

const HeaderContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;

  ${theme.mq.phone} {
    margin-bottom: 12px;
  }
`;

export const ItemsHomeList = () => {
  const { t } = useTranslation('home');
  const items = useHomeItems(ITEMS_COUNT);
  const navigate = useNavigate();

  return (
    <Container data-testid={'home-items-list'}>
      <HeaderContainer>
        <H4 data-testid={'home-items-list-header'}>{t('items.header')}</H4>
        <RedButtonWithArrow onClick={() => navigate('/items')} label={t('items.more-items')} />
      </HeaderContainer>
      <HomeItemTiles itemsState={items.state} />
    </Container>
  );
};
