import { useTranslation } from 'react-i18next';

import { useGlobalError } from 'hooks/useGlobalError';

type UploadImageErrorCode = 'IMAGE_UPLOAD_ERROR' | 'IMAGE_TOO_LARGE' | 'IMAGES_LIMIT_EXCEEDED' | 'FILE_ERROR';

export class UploadImageError extends Error {
  public errorCode: UploadImageErrorCode;

  constructor(message: string, errorCode: UploadImageErrorCode) {
    super(message);
    this.errorCode = errorCode;
    Object.setPrototypeOf(this, UploadImageError.prototype);
  }
}

export const useUploadImagesError = () => {
  const { t } = useTranslation();
  const { unknownError, messageError } = useGlobalError();

  const handleError = (e: unknown) => {
    if (e instanceof UploadImageError) {
      if (e.errorCode === 'IMAGE_UPLOAD_ERROR' || e.errorCode === 'FILE_ERROR') imageUploadError();
      else if (e.errorCode === 'IMAGE_TOO_LARGE') imageTooLarge();
      else if (e.errorCode === 'IMAGES_LIMIT_EXCEEDED') imagesLimitExceeded();
      else unknownError();
    } else {
      unknownError();
    }
  };

  const imageUploadError = () => messageError(t('photos.image-upload-error'), t('photos.error-title'));
  const imageTooLarge = () => messageError(t('photos.image-too-large-error'), t('photos.error-title'));
  const imagesLimitExceeded = () => messageError(t('photos.image-limit-error'), t('photos.error-title'));

  return { handleError };
};
