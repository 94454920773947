import styled from 'styled-components';

import arrowIcon from 'assets/svg/red-right-arrow.svg';

import { RedTextButton } from './RedTextButton';

const Button = styled(RedTextButton)`
  margin-left: 0;
  padding: 0;
  width: auto;
`;

const Icon = styled.img`
  padding-left: 12px;
`;

interface Props {
  onClick: () => void;
  label: string;
}

export const RedButtonWithArrow = ({ onClick, label }: Props) => {
  return (
    <Button onClick={onClick} data-testid={'red-button-with-arrow'}>
      {label} <Icon src={arrowIcon} />
    </Button>
  );
};
