import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import logoIcon from 'assets/svg/logo-full.svg';
import { IconWrapper } from 'components/containers/Containers';
import { ROUTE_FUNDRAISERS } from 'navigation/config/routesConfig';
import { useFundraiserPage } from 'providers/FundraiserPageProvider';
import { convertToThumbnail300 } from 'utils/thumbnails';

const Container = styled.div`
  position: relative;
  width: 8vw;
  display: flex;
  align-items: center;
  justify-content: center;

  ${theme.mq.desktop} {
    width: 77px;
  }
`;

const LogoIcon = styled(IconWrapper)`
  width: 100%;
  aspect-ratio: 2.5;
  object-fit: contain;
`;

export const GiveoLogo = () => {
  const { fundraiserPage, isLoading } = useFundraiserPage();
  const isFundraiserUrl = window.location.pathname.startsWith(ROUTE_FUNDRAISERS);
  const navigate = useNavigate();

  const logo = useMemo(() => {
    if (!isFundraiserUrl) return logoIcon;
    if (isLoading) return;
    if (!!fundraiserPage && fundraiserPage.logoUrl) return convertToThumbnail300(fundraiserPage.logoUrl);
    return logoIcon;
  }, [isFundraiserUrl, fundraiserPage, isLoading]);

  return (
    <Container>
      <LogoIcon src={logo} onClick={() => navigate('/')} />
    </Container>
  );
};
