import { useRef } from 'react';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { useGridLayout } from 'hooks/useGridLayout';
import { ItemsState } from 'services/search/searchService.dto';

import ItemTile from './item-tile/ItemTile';

const TilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  gap: 24px;
  margin-bottom: 24px;

  ${theme.mq.huge} {
    gap: 12px;
  }

  ${theme.mq.desktop} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${theme.mq.phone} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

interface Props {
  itemsState: ItemsState;
}
export const HomeItemTiles = ({ itemsState }: Props) => {
  const gridRef = useRef<HTMLDivElement>(null);
  const visibleItems = useGridLayout(itemsState.items, gridRef, itemsState.hasNextPage);

  return (
    <TilesContainer ref={gridRef}>
      {visibleItems.map(item => (
        <ItemTile key={item.id.toString()} item={item} />
      ))}
    </TilesContainer>
  );
};
