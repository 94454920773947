import { EasyGiving } from 'components/ui-elements/EasyGiving';
import NoSearchResult from 'components/ui-elements/NoSearchResult';

import { AvailableHotDeals } from './AvailableHotDeals';

interface Props {
  resetFilters: () => void;
}

export const HotDealsNotFound = ({ resetFilters }: Props) => {
  return (
    <>
      <NoSearchResult />
      <AvailableHotDeals resetFilters={resetFilters} />
      <EasyGiving />
    </>
  );
};
