import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import RedButton from 'components/buttons/RedButton';
import PriceInputPopupField from 'components/inputs/PriceInputPopupField';
import { H4 } from 'components/typography/Headers';
import { Text1 } from 'components/typography/Texts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled(Text1)`
  margin: 26px 0;
`;

interface Props {
  maxPrice?: number;
  onEnterOffer: (price: number) => void;
}

const EnterOfferPopup = ({ maxPrice, onEnterOffer }: Props) => {
  const { t } = useTranslation('item');
  const [offer, setOffer] = useState<number>();

  const handleOfferChange = (newPrice: number) => setOffer(newPrice);

  const onEnterOfferClick = () => {
    offer && onEnterOffer(offer);
  };

  return (
    <Container data-testid={'enter-offer-popup'}>
      <H4 data-testid={'enter-offer-popup-header'}>{t('enter-offer-popup.header')}</H4>
      <Description data-testid={'enter-offer-popup-description'}>{t('popup-desc')}</Description>
      <PriceInputPopupField
        testId={'enter-offer-popup-input'}
        value={offer}
        onChange={handleOfferChange}
        placeholder={t('enter-offer-popup.input-placeholder')}
        maxValue={maxPrice}
      />
      <RedButton data-testid={'enter-offer-popup-button'} onClick={onEnterOfferClick} disabled={!offer}>
        {t('no-price')}
      </RedButton>
    </Container>
  );
};

export default EnterOfferPopup;
