import axios from 'axios';

import { TransactionDto, TransactionErrorDto } from 'services/payment/paymentService.dto';

import { CheckoutResult } from './checkoutResult.types';

export const orderConfirmationError = (): CheckoutResult => {
  return { paymentStatus: 'FAILURE', errorCode: 'ORDER_CONFIRMATION_ERROR' };
};

export const paymentSuccess = (transaction: TransactionDto): CheckoutResult => {
  return { paymentStatus: 'SUCCESSFUL', transactionId: transaction.id };
};

export const paymentError = (error: any): CheckoutResult => {
  return { paymentStatus: 'FAILURE', errorCode: 'PAYMENT_ERROR', validationErrorCodes: extractValidationErrors(error) };
};

const extractValidationErrors = (error: unknown) => {
  if (
    axios.isAxiosError<TransactionErrorDto>(error) &&
    error.response?.status === 400 &&
    error.response?.data.errorType === 'BILLING_ADDRESS_VALIDATION'
  ) {
    return error.response.data.validationErrorCodes;
  } else {
    return [];
  }
};
