import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useLoginError } from 'features/account/login/useLoginError';
import { useGlobalError } from 'hooks/useGlobalError';
import { useAuth } from 'providers/AuthProvider';
import { authService } from 'services/auth/authService';
import { LoggedWithEmailDto } from 'services/auth/authService.dto';
import { clearLastSignUpEmail } from 'utils/localStorageData';

interface FormData {
  email: string;
  password: string;
}

export const useLoginWithEmailForm = (onLogged: () => void) => {
  const { t } = useTranslation('account');

  const { appLogin } = useAuth();
  const { wrongPassword, lockedAccount, blockedAccount, blockedDevice, inactiveAccount } = useLoginError();
  const { unknownError } = useGlobalError();

  const initialValues: FormData = { email: '', password: '' };

  const validationSchema = Yup.object().shape({
    email: Yup.string().trim().required(t('login-email.email-required')).email(t('login-email.email-invalid')),
    password: Yup.string().trim().required(t('login-email.password-required')),
  });

  const onSubmit = async (data: FormData) => {
    const email = data.email.trim().toLowerCase();
    const password = data.password.trim();

    try {
      const { data: loggedWithEmail } = await authService.login(email, password);
      handleLogged(loggedWithEmail);
    } catch (error) {
      handleError(email, error);
    }
  };

  const handleLogged = (loggedData: LoggedWithEmailDto) => {
    appLogin(loggedData.authToken, 'Email');
    clearLastSignUpEmail();
    onLogged();
  };

  const handleError = (email: string, error: any) => {
    if (error.response?.status === 303) {
      inactiveAccount(email);
    } else if (error.response?.status === 400) {
      wrongPassword();
    } else if (error.response?.status === 403) {
      blockedAccount();
    } else if (error.response?.status === 429) {
      lockedAccount();
    } else if (error.response?.status === 423) {
      blockedDevice();
    } else {
      unknownError();
    }
  };

  return useFormik({ initialValues, validationSchema, onSubmit });
};
